import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Dialog,
    Select,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    styled,
    AppBar,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    TableCell,
    Paper,
    Slide,
    tableCellClasses,
    Table,
    InputLabel,
    TableHead,
    TableRow,
    PropTypes,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    SaveOutlinedIcon,
    useScrollTrigger,
    AddCircleOutlineOutlinedIcon,
    BackspaceOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const rows = [
    { Screen: 'Tiket Master' }
];

export default function UserPreference(props) {
    const [state, setState] = React.useState({
        Module: '',
        ResourceType: '',
        Screen: '',
        List: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    User Preference
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        Add
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                                <Button variant="contained" sx={{ textTransform: 'none', m: 1 }} startIcon={<SaveOutlinedIcon />}>Save</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1} sx={styles.pAlart}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Module <Box component='span' sx={styles.color}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Module"
                                        value={state.Module}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>---All---</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Utilities</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>Doctor</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>Appointment</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={5}>Inventory Management</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={6}>Pharmacy</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={7}>Medical Review</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={8}>Employee</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={9}>Asset Management</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Safety</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={11}>Communication</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={12}>Examination</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={13}>MIS</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={14}>Support</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={15}>Diagnostic Report</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={16}>Diagnostic</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={17}>Stock</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={18}>First Aid</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={19}>My Communication</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Resource Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="ResourceType"
                                        value={state.ResourceType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>---All---</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Report Criteria</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Transaction Screen</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Screen <Box component='span' sx={styles.color}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Screen"
                                        value={state.Screen}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>---All---</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Import Doctor</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box component='h3' sx={{ m: 0, pb: 2 }}>List</Box>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{ fontWeight: 'bold' }}>Show</Box>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label" size="small">10</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="10"
                                    name="List"
                                    value={state.List}
                                    onChange={handleChange}
                                    size="small"
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{ fontWeight: 'bold' }}>entries</Box>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant="outlined"
                                startIcon={<FileCopyOutlinedIcon />}>
                                Export
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6} lg={6} sx={{ sm: { display: 'block' } }}></Grid>
                        <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{ fontWeight: 'bold' }}>Search: </Box>
                            <TextField size="small" sx={{ width: '100%' }} />
                        </Grid>

                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Screen <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Delete <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <StyledTableRow key={row.Screen} sx={styles.TableRow}>
                                                <StyledTableCell> {row.Screen} </StyledTableCell>
                                                <StyledTableCell><Button variant='contained' sx={{ bgcolor: 'red' }} startIcon={<BackspaceOutlinedIcon />} /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}