import { useState, useEffect } from 'react';
import sharedImports from '../../../../Comman/sharedImports';
import {InsertAssetMaintenance, UpdateAssetMaintenance} from '../../../../../api/assetMaintenance';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Dialog,
    Toolbar,
    TextField,
    Typography,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    LocalizationProvider,
    DemoItem,
    DialogTitle,
    DatePicker,
    DialogActions,
    CancelOutlinedIcon,
    AdapterDayjs,
    DialogContent,
    DialogContentText,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetMaintenanceForm(props) {

    const [formData, setFormData] = useState({
        asset_tag: '',
        department: '',
        asset_name: '',
        maintenance_date: null,
        location: '',
        next_maintenance_date: null,
        status: '',
        maintenance_cost: '',
        model_no: '',
        maintenance_by: '',
        category: '',
        document_no: '',
        document_date: null,
        maintenance_details: ''
    });
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }  

    const handleDateChange = (date, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: date
        }))
    }  
    
    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                // Handle update logic response
                response = await UpdateAssetMaintenance(formData);
            } else {
                // Handle create response
                const newData = { ...formData };
                response = await InsertAssetMaintenance(newData);
            }
            
            switch (response.status) {
                case "success":
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/dashboard/support/asset-management/asset-maintenance');
                    break;
                case "failed":
                    enqueueSnackbar(`${response.data}`, { variant: "warning" });
                    break;
                case "error":
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                    break;
            }
        } catch (error) {
            console.log('An error occurred', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }

    useEffect(() => {
        if (props.assetMaintenanceId && props.assetMaintenanceData) {
            setIsEditing(true);
            setFormData({
                ...props.assetMaintenanceData,
                maintenance_date: props.assetMaintenanceData.maintenance_date ? dayjs(props.assetMaintenanceData.maintenance_date) : null,
                next_maintenance_date: props.assetMaintenanceData.next_maintenance_date ? dayjs(props.assetMaintenanceData.next_maintenance_date) : null,
                document_date: props.assetMaintenanceData.document_date ? dayjs(props.assetMaintenanceData.document_date) : null,
            });
        }
    }, [props.assetMaintenanceId, props.assetMaintenanceData]);

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Maintenance create
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }} onClick={handleSubmit}> {isEditing ? 'Update' : 'Save'} </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                startIcon={<FormatListBulletedOutlinedIcon />}
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                                List
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Asset Tag <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Asset Tag'
                                            name='asset_tag'
                                            value={formData.asset_tag}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Department'
                                            name='department'
                                            value={formData.department}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Asset Name</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Asset Name'
                                            name='asset_name'
                                            value={formData.asset_name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker
                                                        value={formData.maintenance_date}
                                                        onChange={((date) => handleDateChange(date, 'maintenance_date'))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Location</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Location'
                                            name='location'
                                            value={formData.location}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Next Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker 
                                                        value={formData.next_maintenance_date}
                                                        onChange={((date) => handleDateChange(date, 'next_maintenance_date'))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Status'
                                            name='status'
                                            value={formData.status}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Maintainance Cost <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Maintainance Cost'
                                            name='maintenance_cost'
                                            value={formData.maintenance_cost}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Model No</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Model No'
                                            name='model_no'
                                            value={formData.model_no}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Maintainance By</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Maintainance By'
                                            name='maintenance_by'
                                            value={formData.maintenance_by}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Category</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Category'
                                            name='category'
                                            value={formData.category}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Document No</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Document No'
                                            name='document_no'
                                            value={formData.document_no}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Document Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker 
                                                        value={formData.document_date}
                                                        onChange={((date) => handleDateChange(date, 'document_date'))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Maintainance Details</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Maintainance Details'
                                            name='maintenance_details'
                                            value={formData.maintenance_details}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}