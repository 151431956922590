import EmployeeTransferOutForm from './form';

const CreateEmployeeTransferOut = () => {
    return (
    <>
      <EmployeeTransferOutForm />
    </>  
  )
}

export default CreateEmployeeTransferOut
