import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Paper,
    Table,
    Button,
    AppBar,
    Toolbar,
    TableRow,
    TextField,
    TableBody,
    FormControl,
    TableHead,
    PropTypes,
    Typography,
    TableContainer,
    useScrollTrigger,
    SaveOutlinedIcon,
    tableCellClasses,
    TableCell,
    styled,
    Select,
    MenuItem,
    useMediaQuery
} = sharedImports;



const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function LocationDepartmentGroup(props) {

    const isSmallScreen = useMediaQuery('(max-width:981px)');

    const [state, setState] = React.useState({
        TargetGroup: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },

        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const rows = [
        { departmentCode: '-"', department: '-', employeeName: null },
        { departmentCode: 'Asset Integrity', department: 'Asset Integrity', employeeName: null },
        { departmentCode: 'Iron Ore Mining"', department: 'Iron Ore Mining', employeeName: null },
        { departmentCode: 'ABEX"', department: 'ABEX', employeeName: null },
        { departmentCode: 'ABEX "', department: 'ABEX', employeeName: null },
        { departmentCode: 'Adani North America"', department: 'Adani North America', employeeName: null },
        { departmentCode: 'ADHOC"', department: 'ADHOC', employeeName: null },
    ];

    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
            fontWeight: 'bold'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Location Department Group
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{ textTransform: 'none', m: 1 }} startIcon={<SaveOutlinedIcon />}>Save</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1} sx={styles.pAlart}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="TargetGroup"
                                        value={state.TargetGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]}>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell>Department Group Code</StyledTableCell>
                                        <StyledTableCell>Department Group</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.departmentCode} sx={styles.TableRow}>
                                            <StyledTableCell> {row.departmentCode} </StyledTableCell>
                                            <StyledTableCell>{row.department}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
            </Box>
        </Box>
    )
}