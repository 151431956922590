import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeDesignatedPosition = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeDesignatedPosition = async (props) => {
  const url = `${ApiConfig.employeeDesignatedPositionMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_designated_position_id) => {
  const url = `${ApiConfig.employeeDesignatedPositionMain}?id=${employee_designated_position_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeDesignatedPosition = async (props) => {
  const url = `${ApiConfig.employeeDesignatedPositionMain}?id=${props.employee_designated_position_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeDesignatedPosition = async (employee_designated_position_id) => {
    const url = `${ApiConfig.employeeDesignatedPositionMain}?id=${employee_designated_position_id}`;
    const response = await api.delete(url);
    return response.data;
};