import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeTypeGroup = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeTypeGroup = async (props) => {
  const url = ApiConfig.employeeTypeGroupMain;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_type_group_id) => {
  const url = `${ApiConfig.employeeTypeGroupMain}?id=${employee_type_group_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeTypeGroup = async (props) => {
  const url = `${ApiConfig.employeeTypeGroupMain}?id=${props.employee_type_group_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeTypeGroup = async (employee_type_group_id) => {
    const url = `${ApiConfig.employeeTypeGroupMain}?id=${employee_type_group_id}`;
    const response = await api.delete(url);
    return response.data;
};
