import { api } from "api/config/axiosInterceptor";
import { ApiConfig } from "api/config/ApiConfig";
export const GetHRListing = async (props) => {
  try {
    const { page, per_page, Code, ToDate, FromDate, globalFilter, name, location, company } = props;

    // Function to build the query string
    function buildQueryString(page, per_page, Code, ToDate, FromDate, globalFilter, name) {
      const params = [`page=${page}`, `limit=${per_page}`];

      if (Code) {
        params.push(`employeeCode=${Code}`);
      }
      if (ToDate) {
        params.push(`endDate=${ToDate}`);
      }
      if (FromDate) {
        params.push(`formData=${FromDate}`);
      }
      if (globalFilter) {
        params.push(`search=${globalFilter}`);
      }
      if (name) {
        params.push(`fullname=${name}`);
      }
      if (location) {
        params.push(`location=${location}`);
      }
      if (company) {
        params.push(`company=${company}`);
      }
      return params.join("&");
    }

    const queryString = buildQueryString(
      page,
      per_page,
      Code,
      ToDate,
      FromDate,
      globalFilter,
      name,
      location,
      company
    );
    const url = `${ApiConfig.listUserByRole}?roleID=3&${queryString}`;

    const response = await api.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};



