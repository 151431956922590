import sharedImports from "component/Comman/sharedImports";
import { ApiConfig } from "api/config/ApiConfig";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import dayjs from 'dayjs';
import { GetEmployeeWorkLocation } from "api/employee-master-setup/employeeWorkLocation";
import { Insert, Update } from "api/employee-management/employeeTransferOut";
import { useNavigate } from 'react-router-dom';
const {
  React,
  PropTypes,
  Box,
  Grid,
  Slide,
  AppBar,
  Button,
  Stack,
  Toolbar,
  Link,
  DemoItem,
  SaveOutlinedIcon,
  DatePicker,
  AdapterDayjs,
  TextField,
  MenuItem,
  Select,
  Typography,
  FormControl,
  useScrollTrigger,
  CancelOutlinedIcon,
  FormatListBulletedOutlinedIcon,
  LocalizationProvider,
} = sharedImports;

const drawerWidth = 300;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
const styles = {
  TableRow: {
    th: {
      svg: {
        float: "right",
        color: "#1976d2",
      },
    },
    td: {
      border: "1px solid lightgray",
    },
  },
  h: {
    minHeight: "40px !important",
  },
  bgWhite: {
    bgcolor: "white",
    color: "black",
  },
};

export default function EmployeeTransferOutForm(props) {

  const [formData, setFormData] = useState({
    FromLocation: "",
    ToLocation: "",
    EmployeeCode: "",
    EmployeeName: "",
    Remark: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { baseUrl, listAllEmployeeWorkLocation } = ApiConfig;
  const fetchInfo = async () => {
    try {
      const url = new URL(listAllEmployeeWorkLocation, baseUrl);
      url.searchParams.set(
        "sorting",
        JSON.stringify([{ employee_work_location_id: "name", asc: true }])
      );
      const response = await GetEmployeeWorkLocation(url);
      if (response.status) {
        const { data } = response?.data;
        setData(data);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Somthing went wrong", { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? (checked ? true : false) : value,
    }));
  };

  const handleDateChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const insertData = async (formData) => {
    try {
      let response;
      if (isEditing) {
        response = await Update(formData);
      } else {
        const newData = { ...formData };
        response = await Insert(newData);
      }

      if (response.status) {
        enqueueSnackbar(response.message, { variant: "success" });
        navigate("/dashboard/hr/employee-management/employee-transfer-out");
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }

    } catch (error) {
      //console.log("An error occurred", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    insertData(formData);
  };
  const handleReset = () => {
    setFormData({
      FromLocation: "",
      ToLocation: "",
      EmployeeCode: "",
      EmployeeName: "",
      Remark: '',
    });
  };

  useEffect(() => {
    if (props.Id && props.Data) {
        setIsEditing(true);
        setFormData({
            ...props.Data
        });      
    }
    fetchInfo();
  }, [props.Id, props.Data]);

  const styles = {
    firstForm: {
      padding: "16px 16px 34px 16px",
      backgroundColor: "#f1f1f1",
    },
    subHeader: {
      padding: "10px",
      h3: {
        margin: "0px",
        padding: "0px",
      },
    },
    bgWhite: {
      backgroundColor: "white",
      color: "black",
    },
    wFull: {
      width: "100%",
    },
    marginBottom2: {
      marginBottom: 2,
      mt: 2,
    },
    TableRow: {
      th: {
        width: "12.5%",
        svg: {
          float: "right",
          color: "#1976d2",
        },
      },
      td: {
        border: "1px solid lightgray",
      },
    },
    h: {
      minHeight: "40px !important",
    },
    p20: {
      padding: "20px",
    },
    bRadius: {
      borderRadius: "5px",
      marginTop: "45px",
    },
    dFlex: {
      display: "flex",
    },
    justifySB: {
      justifyContent: "space-between",
    },
    alignC: {
      alignItems: "center",
    },
  };

  return (
    <>
      <React.Fragment>
        <Box sx={styles.root}>
          <HideOnScroll {...props}>
            <AppBar
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                mt: { xs: "53px", sm: "64px" },
                zIndex: 1099,
              }}
              elevation={1}
            >
              <Toolbar sx={[styles.h, styles.bgWhite]}>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={12} md={11}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{ m: 1, fontWeight: "bold" }}
                    >

                        {isEditing ? (
                            'Update '
                        ) : (
                            'Create '
                        )}                        
                       Employee Transfer Out
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <React.Fragment>
                      <Link to="/dashboard/hr/employee-management/employee-transfer-out">
                        <Button
                          variant="contained"
                          startIcon={<FormatListBulletedOutlinedIcon />}
                          item
                          xs={12}
                          md={1}
                          sx={{ textTransform: "none", m: 1 }}
                        >
                          List
                        </Button>
                      </Link>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          <Box sx={styles.firstForm}>
            <Box
              sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]}
              className="MainPadding"
            >
              <Grid container spacing={1}>

              <Grid item xs={12} md={3}>
                  <Box sx={{ fontWeight: "bold" }}>Employee Code</Box>
                  <Grid item xs={12} md={11}>
                    <TextField
                      required
                      name="EmployeeCode"
                      id="filled-search"
                      value={formData.EmployeeCode}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      placeholder="Code Like"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{ fontWeight: "bold" }}>Employee Name</Box>
                  <Grid item xs={12} md={11}>
                    <TextField
                      required
                      name="EmployeeName"
                      id="filled-search"
                      value={formData.EmployeeName}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      placeholder="Name Like"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{ fontWeight: "bold" }}>From Location</Box>
                  <Grid item xs={12} md={11}>
                    <FormControl fullWidth>
                      <Select
                        name="FromLocation"
                        value={formData.FromLocation}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>-All-</>
                        </MenuItem>
                        {data.map((row, index) => (
                          <MenuItem
                            sx={{ ml: 3 }}
                            value={row.employee_work_location_id}
                          >
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{ fontWeight: "bold" }}>To Location</Box>
                  <Grid item xs={12} md={11}>
                    <FormControl fullWidth>
                      <Select
                        name="ToLocation"
                        value={formData.ToLocation}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>-All-</>
                        </MenuItem>
                        {data.map((row, index) => (
                          <MenuItem
                            sx={{ ml: 3 }}
                            value={row.employee_work_location_id}
                          >
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}></Grid>

               

                <Grid container spacing={1} sx={{ mt: .5, ml: 1 }}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ fontWeight: 'bold' }}>Remark</Box>
                        <textarea
                            name="Remark"
                            style={{ height: '65px', width: '96%' }}
                            placeholder='Remark'
                            value={formData.Remark}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>

                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    sx={styles.marginBottom2}
                  >
                    <Button
                      variant="contained"
                      startIcon={<SaveOutlinedIcon />}
                      onClick={handleSubmit}
                      sx={{ m: 1, textTransform: "none" }}
                    >
                      {" "}
                       
                      {isEditing ? (
                            'Update '
                        ) : (
                            'Save '
                        )}       
                      {" "}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleReset}
                      startIcon={<CancelOutlinedIcon />}
                      sx={{ m: 1, textTransform: "none" }}
                    >
                      Clear
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
}
