import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../../../../api/assetMaintenance';
import AssetMaintenanceForm from '../assetMaintenance/from';

const UpdateAssetMaintenance = () => {
  const { id } = useParams();
  
  const [assetMaintenanceData, setAssetMaintenanceData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setAssetMaintenanceData(response.data);
    } catch (error) {
      console.error('Error fetching asset maintenance data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (assetMaintenanceData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <AssetMaintenanceForm assetMaintenanceId={id} assetMaintenanceData={assetMaintenanceData} />
    </>
  );
};

export default UpdateAssetMaintenance;
