import { useEffect, useState, useCallback } from 'react';
import sharedImports from '../../Comman/sharedImports';
import { useSnackbar } from 'notistack';
import PaginationControls from 'component/Comman/paginationControls';
import { getAccidentWithoutInjury } from 'api/safety/formAccidentWithoutInjury';
import dayjs from 'dayjs';

const {
    React,
    Box,
    Grid,
    Paper,
    Slide,
    Table,
    AppBar,
    Button,
    styled,
    Select,
    Dialog,
    Toolbar,
    DemoItem,
    MenuItem,
    TableRow,
    Checkbox,
    TableCell,
    TextField,
    TableHead,
    TableBody,
    PropTypes,
    DatePicker,
    Typography,
    CssBaseline,
    FormControl,
    AdapterDayjs,
    DialogTitle,
    DialogActions,
    DialogContent,
    TableContainer,
    FormControlLabel,
    useScrollTrigger,
    tableCellClasses,
    DialogContentText,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    LocalizationProvider,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    AddCircleOutlineOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function FormAccidentWithoutInjury(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [injuryData, setInjuryData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [hitApi, setHitApi] = useState(false);
    const [filters, setFilters] = useState({
        shift: '',
        placeOfIncident: '',
        accidentRemark: '',
        activityWhileAccident: '',
        isExplosion: false,
        isFire: false,
        isChemical: false,
        startDate: null,
        endDate: null
    });

    const disbleSearch = React.useMemo(
        () =>
            filters.shift ||
            filters.placeOfIncident ||
            filters.accidentRemark ||
            filters.activityWhileAccident ||
            filters.isExplosion ||
            filters.isFire ||
            filters.isChemical ||
            (filters.startDate && filters.endDate),
        [filters]
    );

    const handleFilterChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: type === 'checkbox' ? (checked ? 1 : false) : value
        }));
    };

    const getAccWithoutInjury = useCallback(async () => {
        try {
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const params = {
                page: page,
                per_page: per_page,
                globalFilter: globalFilter,
                sorting: sorting,
                filters: filters
            };
            const response = await getAccidentWithoutInjury(params);

            if (response.status) {
                const { data, totalCount } = response?.data;
                setInjuryData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            console.error('Error fetching employee grades:', err);
        }
    }, [hitApi, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);



    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);

            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });

        getAccWithoutInjury();
    };

    const returnValOfBoolean = (value) => {
        const valueReutn = value === false ? 'No' : 'Yes';
        return valueReutn
    }

    const handleClearFilters = () => {
        setFilters((pre) => ({
            ...pre,
            shift: '',
            placeOfIncident: '',
            accidentRemark: '',
            activityWhileAccident: '',
            isExplosion: false,
            isFire: false,
            isChemical: false,
            startDate: null,
            endDate: null
        }));
        setHitApi(!hitApi);
        setIsSearch(false);
    };

    const handleDateChange = (date, name) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: date ? date : null,
        }));
    };

    const handleSearch = () => {
        const { startDate, endDate } = filters;

        if (startDate) {
            if (!endDate) {
                enqueueSnackbar("Please select both from and end dates.", { variant: "warning" });
                return;
            }

            if (new Date(startDate) > new Date(endDate)) {
                enqueueSnackbar("End date should be greater than or equal to from date.", { variant: "warning" });
                return;
            }
        }

        setIsSearch(true);
        setHitApi(prev => !prev);
    };


    useEffect(() => {
        getAccWithoutInjury();
    }, [getAccWithoutInjury]);

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Form Accident Without Injury
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                                Add
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker
                                                        value={filters.startDate}
                                                        onChange={(date) => handleDateChange(date, 'startDate')}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker
                                                        value={filters.endDate}
                                                        onChange={(date) => handleDateChange(date, 'endDate')}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Shift</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="shift"
                                                value={filters.shift}
                                                onChange={handleFilterChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="A07AMTO02PM">A (07 AM To 02 PM)</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="B02PMTO10PM">B (02 PM To 10 PM)</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="C10PMTO07PM">C (10 PM To 07 PM)</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="off_duty">OFF DUTY</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="official_tour">OFFICIAL TOUR</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Place of Incident</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="placeOfIncident"
                                                value={filters.placeOfIncident}
                                                onChange={handleFilterChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="consumable">Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="laboratory">Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="medicine">Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value="setrid_cold">SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Accident Remark</Box>
                                    <textarea
                                        name="accidentRemark"
                                        style={{ height: '65px', width: '97%' }}
                                        placeholder='Accident Remark'
                                        value={filters.accidentRemark}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>

                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Activity While Accident</Box>
                                    <textarea
                                        name="activityWhileAccident"
                                        style={{ height: '65px', width: '97%' }}
                                        placeholder='Activity While Accident'
                                        value={filters.activityWhileAccident}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filters.isExplosion}
                                                onChange={handleFilterChange}
                                                name="isExplosion"
                                            />
                                        }
                                        label="Explosion"
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filters.isFire}
                                                onChange={handleFilterChange}
                                                name="isFire"
                                            />
                                        }
                                        label="Fire"
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filters.isChemical}
                                                onChange={handleFilterChange}
                                                name="isChemical"
                                            />
                                        }
                                        label="Chemical"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}
                                        onClick={handleSearch} disabled={!disbleSearch} >Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}
                                        onClick={handleClearFilters} disabled={!isSearch}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <select
                                            id="outlined-number"
                                            sx={{ width: '9ch' }}
                                            value={pagination.pageSize}
                                            onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={(e) => handleSort('accidentTime')}>Accident Time<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={(e) => handleSort('accidentDate')}>Accident Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={(e) => handleSort('shift')}>Shift<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={(e) => handleSort('placeOfIncident')}>Place of Incident<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>IsExplosion</StyledTableCell>
                                            <StyledTableCell>IsFire</StyledTableCell>
                                            <StyledTableCell>IsChemical</StyledTableCell>
                                            <StyledTableCell onClick={(e) => handleSort('accidentRemark')}>Accident Remark<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {injuryData.length === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={8} align="center">
                                                    No records found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            injuryData.map((row, index) => (
                                                <StyledTableRow key={index} sx={styles.TableRow}>
                                                    <StyledTableCell> {row?.accidentTime} </StyledTableCell>
                                                    <StyledTableCell>{new Date(row?.accidentDate).toISOString().split('T')[0]}</StyledTableCell>
                                                    <StyledTableCell>{row?.shift}</StyledTableCell>
                                                    <StyledTableCell>{row?.placeOfIncident}</StyledTableCell>
                                                    <StyledTableCell>{returnValOfBoolean(row?.isExplosion)}</StyledTableCell>
                                                    <StyledTableCell>{returnValOfBoolean(row?.isFire)}</StyledTableCell>
                                                    <StyledTableCell>{returnValOfBoolean(row?.isChemical)}</StyledTableCell>
                                                    <StyledTableCell>{row?.accidentRemark}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}