import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Button,
    AppBar,
    Toolbar,
    TextField,
    PropTypes,
    Typography,
    useMediaQuery,
    HighlightOffIcon,
    useScrollTrigger,
    FileUploadOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ImportOtherMaster(props) {

    const isSmallScreen = useMediaQuery('(max-width:981px)');
    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Import Other Master
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{ textTransform: 'none', m: 1 }} startIcon={<FileUploadOutlinedIcon />}>Import</Button>
                                <Button variant='outlined' startIcon={<HighlightOffIcon />} sx={{ textTransform: 'none' }}> Clear </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1} sx={styles.pAlart}>
                        <Grid item sx={[isSmallScreen ? { width: '100%' } : { width: '100%' }, { color: '#179fff' }, styles.marginBottom2]}>
                            Notes: Text data to be Separated
                        </Grid>
                        <Grid item sx={isSmallScreen ? { width: '100%' } : { width: '50%' }}>
                            <Box sx={{ color: '#179fff' }}>Download Template <Box component='span' sx={styles.color}>*</Box></Box>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                placeholder='Copy and Paste data here'
                                sx={[isSmallScreen ? { width: '100%' } : { width: '100%' }, { border: 1, borderColor: '#179fff', borderRadius: 1 }]}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}