import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    DemoItem,
    TableRow,
    MenuItem,
    TableHead,
    DatePicker,
    TableCell,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    FileCopyOutlinedIcon,
    useScrollTrigger,
    LocalizationProvider,
    Slide,
    AdapterDayjs,
    PropTypes,
    FormControlLabel,
    Checkbox,
    EditNoteOutlinedIcon,
    TableBody,

} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SubLocation: 'Ahmedabad', ItemCode: 180, ItemName: 'Foley Catheter 16 FR', Uom: 'NOS', Batch: 'G21012425', ExpiryDate: '31/12/2025', Quantity: 4, },
    { SubLocation: 'Ahmedabad', ItemCode: 181, ItemName: 'Suction Catheter 12 No.', Uom: 'NOS', Batch: 'G19062509', ExpiryDate: '31/05/2024', Quantity: 2, },
    { SubLocation: 'Ahmedabad', ItemCode: 182, ItemName: 'Nebulizer mask', Uom: 'NOS', Batch: 'NM57', ExpiryDate: '30/04/2024', Quantity: 2, },
    { SubLocation: 'Ahmedabad', ItemCode: 184, ItemName: 'ET Tube 6 No.', Uom: 'NOS', Batch: '313641BE', ExpiryDate: '30/04/2023', Quantity: 1, },
    { SubLocation: 'Ahmedabad', ItemCode: 185, ItemName: 'ET Tube 6.5 No.', Uom: 'NOS', Batch: '3245518H', ExpiryDate: '31/07/2023', Quantity: 1, },
    { SubLocation: 'Ahmedabad', ItemCode: 186, ItemName: 'ET Tube 7 No.', Uom: 'NOS', Batch: '3074221C', ExpiryDate: '28/02/2026', Quantity: 3, },
    { SubLocation: 'Ahmedabad', ItemCode: 187, ItemName: 'ET Tube 7.5 No.', Uom: 'NOS', Batch: '3245518H', ExpiryDate: '20/09/2023', Quantity: 1, },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        MainGroup: '',
        SubGroup: '',
        Filter: '',
        ItemCategory: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Batch
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Name Like</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Item Name Like'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Main Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="MainGroup"
                                            value={state.MainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="SubGroup"
                                            value={state.SubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Filter</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Filter"
                                            value={state.Filter}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Item Category</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ItemCategory"
                                            value={state.ItemCategory}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Batch</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Batch'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Expiry Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>





                            <Grid item xs={12} md={4.5}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex.10,155 etc.)</Box>
                                </Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Code Like '
                                />

                            </Grid>


                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Critical"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="First Aid"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Inactive"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center' }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export To Excel
                        </Button>

                    </Stack>


                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        mb: 5,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <TableContainer item xs={12} md={3} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Sub Location</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Item Code</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Item Name</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>UOM</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Batch</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Expiry Date</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Quantity</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.SubLocation} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.SubLocation} </StyledTableCell>
                                                    <StyledTableCell>{row.ItemCode}</StyledTableCell>
                                                    <StyledTableCell>{row.ItemName}</StyledTableCell>
                                                    <StyledTableCell>{row.Uom}</StyledTableCell>
                                                    <StyledTableCell>{row.Batch}</StyledTableCell>
                                                    <StyledTableCell>{row.ExpiryDate}</StyledTableCell>
                                                    <StyledTableCell>{row.Quantity}</StyledTableCell>
                                                    <StyledTableCell sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="outlined" sx={{ textTransform: 'none' }}><EditNoteOutlinedIcon />Edit</Button></StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
}