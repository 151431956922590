import { useState } from 'react';
import sharedImports from '../../Comman/sharedImports';
import { insertAccidentWithInjury } from 'api/safety/formAccidentWithInjury';
import { useSnackbar } from 'notistack';
const {
    React,
    Box,
    Tabs,
    Tab,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Dialog,
    Toolbar,
    Checkbox,
    MenuItem,
    TextField,
    PropTypes,
    Typography,
    CssBaseline,
    FormControl,
    DatePicker,
    SaveOutlinedIcon,
    useScrollTrigger,
    DemoItem,
    DialogTitle,
    AdapterDayjs,
    DialogContent,
    DialogActions,
    FormControlLabel,
    DialogContentText,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    LocalizationProvider,
    FormatListBulletedIcon
} = sharedImports;

const drawerWidth = 300;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function FromAccidentWithInjury(props) {
    const { enqueueSnackbar } = useSnackbar();

    const initialState = {
        accidentTime: '',
        accidentDate: null,
        shift: '',
        placeOfIncident: '',
        causeOfAccident: '',
        natureAndExtentInjury: '',
        accidentType: '',
        totalPersonsAffected: '',
        insideKilled: '',
        insideInjured: '',
        outsideKilled: '',
        outsideInjured: '',
        witness1Name: '',
        witness1Address: '',
        witness2Name: '',
        witness2Address: '',
        accidentRemark: '',
        activityWhileAccident: '',
        isExplosion: false,
        isFire: false,
        isChemical: false
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? (checked ? true : false) : value
        }));
    };

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };


    function handleChanges(event, newValue) {
        setValue(newValue);
    }

    const insertData = async (formData) => {
        try {
            const newData = { ...formData };
            const response = await insertAccidentWithInjury(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                handleReset();
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            console.log('An error occurred', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        insertData(formData);
    }

    const handleReset = () => {
        setFormData(initialState);
    };


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5.5}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            From Accident With Injury
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5.5}>
                                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }} onClick={handleSubmit}> Save </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ textTransform: 'none' }} onClick={handleReset}> Clear </Button>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                startIcon={<FormatListBulletedIcon />}
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                                List
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChanges} aria-label="basic tabs example">
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Details" {...a11yProps(0)} />
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Annexires" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Accident Time <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                name="accidentTime"
                                                value={formData.accidentTime}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Accident Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker
                                                            value={formData.accidentDate}
                                                            onChange={(value) => handleDateChange('accidentDate', value)}
                                                        />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Outside Injured</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.outsideInjured}
                                                onChange={handleChange}
                                                name='outsideInjured'
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Shift</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="shift"
                                                    value={formData.shift}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-Select-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="A07AMTO02PM">A (07 AM To 02 PM)</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="B02PMTO10PM">B (02 PM To 10 PM)</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="C10PMTO07PM">C (10 PM To 07 PM)</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="off_duty">OFF DUTY</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="official_tour">OFFICIAL TOUR</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Place of Incident</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="placeOfIncident"
                                                    value={formData.placeOfIncident}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-Select-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="consumable">Consumable</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="laboratory">Laboratory</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="medicine">Medicine</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value="setrid_cold">SETRID-COLD</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Cause Of Accident</Box>
                                        <textarea
                                            name="causeOfAccident"
                                            style={{ height: '70px', width: '92%' }}
                                            value={formData.causeOfAccident}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Accident Type</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.accidentType}
                                                onChange={handleChange}
                                                name='accidentType'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Total No Of Person Affected</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.totalPersonsAffected}
                                                onChange={handleChange}
                                                name='totalPersonsAffected'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Witness2 Address</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.witness2Address}
                                                onChange={handleChange}
                                                name='witness2Address'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Inside Killed</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.insideKilled}
                                                onChange={handleChange}
                                                name='insideKilled'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Inside Injured</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.insideInjured}
                                                onChange={handleChange}
                                                name='insideInjured'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Outside Killed</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.outsideKilled}
                                                onChange={handleChange}
                                                name='outsideKilled'
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Witness1 Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.witness1Name}
                                                onChange={handleChange}
                                                name='witness1Name'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Witness1 Address</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.witness1Address}
                                                onChange={handleChange}
                                                name='witness1Address'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Witness2 Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                fullWidth
                                                size='small'
                                                value={formData.witness2Name}
                                                onChange={handleChange}
                                                name='witness2Name'
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Nature And Extent Injury</Box>
                                        <textarea
                                            name="natureAndExtentInjury"
                                            style={{ height: '70px', width: '92%' }}
                                            value={formData.natureAndExtentInjury}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Accident Remark</Box>
                                        <textarea
                                            style={{ height: '70px', width: '92%' }}
                                            value={formData.accidentRemark}
                                            onChange={handleChange}
                                            name='accidentRemark'
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ fontWeight: 'bold' }}>Activity While Accident</Box>
                                        <textarea
                                            style={{ height: '70px', width: '92%' }}
                                            value={formData.activityWhileAccident}
                                            onChange={handleChange}
                                            name='activityWhileAccident'
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            name="isExplosion"
                                            checked={formData.isExplosion}
                                            onChange={handleChange}
                                            control={<Checkbox />}
                                            label="IsExplosion"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="IsFire"
                                            labelPlacement="end"
                                            name="isFire"
                                            checked={formData.isFire}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="IsChemical"
                                            labelPlacement="end"
                                            name="isChemical"
                                            checked={formData.isChemical}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                </Grid>

                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Age <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Gender</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Gender"
                                                    value={""}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-Select-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Address</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Occupation</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Nature Of Job</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Nature And Extant Of Injury</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Injury Time</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Incident Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Hazard Process</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Dangerous Operation</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Job</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Time Of Starting Work</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Travelling Type</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Emergency Remark</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Related to Business</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Treated Doctor Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Dispensary Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsExplosion"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsFire"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsChemical"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsDisabled"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsAttendEmergency"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="IsTravelling"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                    <Grid item>
                                        <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}