import { Button } from '@mui/material';
import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Grid,
    Card,
    Stack,
    Radio,
    Slide,
    Select,
    Toolbar,
    MenuItem,
    PropTypes,
    CheckIcon,
    FormControl,
    Typography,
    CardContent,
    useScrollTrigger,
    FileCopyOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    color: {
        color: 'red',
        border: '1px solid lightgray',
        padding: '10px',
    },
}



export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        MainGroup: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const [selectedValue, setSelectedValue] = React.useState('a');
    const handleChanges = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Import Stock Item Restrictive
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1, ml: 2, mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid item xs={12} md={3}>
                        <Box variant="soft" sx={[styles.color, { paddingLeft: '30px', borderRadius: 2, ml: 2 }]}>
                            Import Stock Item In Location :Ahmedabad<br />
                            Notes : Sheet Name Must be StockItems<br />
                            Notes : Text data to be Separated
                        </Box>
                    </Grid>
                    <CardContent>
                        <div>
                            <Radio
                                checked={selectedValue === 'a'}
                                onChange={handleChanges}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            Copy
                            <Radio
                                checked={selectedValue === 'b'}
                                onChange={handleChanges}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}
                            />
                            Upload
                        </div>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Main Group <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="MainGroup"
                                            value={state.MainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>
                        </Grid>
                        <div>
                            <Radio
                                checked={selectedValue === 'a'}
                                onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            Excel
                        </div>
                        <Button sx={{ textTransform: 'none' }}>Download Template</Button>

                    </CardContent>

                    <Grid item xs={12} md={6} sx={{ ml: 2 }}>
                        <textarea
                            name="postContent"
                            rows={12}
                            cols={180}
                            placeholder='Copy and Paste data here'
                        />
                    </Grid>
                    <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={< CheckIcon />}>
                            Validate
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export Stock Item Category
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export Stock Item SubGroup
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export Issue UOM
                        </Button>
                    </Stack>
                </Card>
            </Box>

        </>


    )
} 