import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const Insert = async (props) => {  
  const url = ApiConfig.insertEmployeeTransferIn;
  const params = {
    ...props,
    FromDate: props.FromDate ? props.FromDate.format("YYYY-MM-DD") : null,
    ToDate: props.ToDate ? props.ToDate.format("YYYY-MM-DD") : null,
  };

  const response = await api.post(url, params);
  return response.data;
};

export const Listing = async (url) => {  
  const response = await api.get(url);
  return response.data;
};

export const SingleData = async (id) => {
  const url = `${ApiConfig.getSingleEmployeeTransferIn}?id=${id}`;
  const response = await api.get(url);
  return response.data;
};

export const Delete = async (id) => {
  const url = `${ApiConfig.deleteAllEmployeeTransferIn}?id=${id}`;
  const response = await api.delete(url);
  return response.data;
};

export const Update = async (props) => {
  const url = `${ApiConfig.updateEmployeeTransferIn}?id=${props.id}`;
  const { id, CreatedAt, UpdatedAt, ...restProps} = props;
  const params = {
    ...restProps,
    FromDate: props.FromDate ? props.FromDate.format("YYYY-MM-DD") : null,
    ToDate: props.ToDate ? props.ToDate.format("YYYY-MM-DD") : null,
  }
  const response = await api.put(url, params);
  return response.data;
};