import { useEffect } from 'react';
import { getFirtAidDashboardData } from 'api/HRsection/firstAidDashboard';
import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Select,
    Paper,
    Table,
    Slide,
    styled,
    Button,
    TableRow,
    MenuItem,
    PropTypes,
    TableBody,
    Typography,
    FormControl,
    DatePicker,
    TableHead,
    DemoItem,
    SearchIcon,
    AdapterDayjs,
    TableContainer,
    TableCell,
    Toolbar,
    AppBar,
    useScrollTrigger,
    tableCellClasses,
    HighlightOffIcon,
    LocalizationProvider,
} = sharedImports;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const rows = [
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
    { firstAidCertified: 44, firstAidDue: 44, firstAidRandom: 0, firstAidCertifiedAndRandom: 0 },
];

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function FirstAidDashboard(props) {

    const [state, setState] = React.useState({
        TargetGroup: ''
    });

    const [fisrtAidDashboardData, setFisrtAidDashboardData] = React.useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        }
    }

    const getFirtAidDashboard = async () => {
        try {
            const response = await getFirtAidDashboardData();
            if (response.status) {
                setFisrtAidDashboardData(response.data.firstAidTrainingData);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getFirtAidDashboard();
    }, [])

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    First Aid Dashboard
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="TargetGroup"
                                        value={state.TargetGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <Button variant="contained" startIcon={<SearchIcon />} sx={{ textTransform: 'none', mr: 2, ml: 4 }}>Search</Button>
                            <Button variant="outlined" startIcon={<HighlightOffIcon />} sx={{ textTransform: 'none' }}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>First Aid Certified</StyledTableCell>
                                            <StyledTableCell>First Aid Due</StyledTableCell>
                                            <StyledTableCell>First Aid Random</StyledTableCell>
                                            <StyledTableCell>First Aid Certified and Random</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            <StyledTableRow key={1} sx={styles.TableRow}>
                                                <StyledTableCell> {fisrtAidDashboardData} </StyledTableCell>
                                                <StyledTableCell>--</StyledTableCell>
                                                <StyledTableCell>--</StyledTableCell>
                                                <StyledTableCell>--</StyledTableCell>
                                            </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}