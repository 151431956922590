import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    PropTypes,
    TextField,
    Typography,
    DialogTitle,
    DialogContent,
    useMediaQuery,
    DialogActions,
    useScrollTrigger,
    DialogContentText,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function MyDashBoard(props) {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const isSmallScreen = useMediaQuery('(max-width:981px)');
    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom5: {
            marginBottom: 5,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
    }


    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    My DashBoard
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.wFull]} className='MainPadding'>
                    <Box sx={[styles.bgWhite, styles.wFull, { display: 'flex', justifyContent: 'end', marginTop: '45px' }]} className='MainPadding'>
                        <React.Fragment>
                            <Button
                                variant="contained"
                                onClick={handleClickOpen}
                                sx={isSmallScreen ? { width: '50%', mr: 2 } : { maxWidth: '100%', mr: 2, textTransform: 'none' }}>
                                Alert Recipient
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    component: 'form',
                                    onSubmit: (event) => {
                                        event.preventDefault();
                                        const formData = new FormData(event.currentTarget);
                                        const formJson = Object.fromEntries(formData.entries());
                                        const email = formJson.email;
                                        console.log(email);
                                        handleClose();
                                    },
                                }}
                            >
                                <DialogTitle>Subscribe</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        To subscribe to this website, please enter your email address here. We
                                        will send updates occasionally.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button type="submit">Subscribe</Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                        <React.Fragment>
                            <Button
                                variant="contained"
                                onClick={handleClickOpen}
                                sx={isSmallScreen ? { width: '50%' } : { maxWidth: '100%', textTransform: 'none' }}>
                                Manage Dashlet
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    component: 'form',
                                    onSubmit: (event) => {
                                        event.preventDefault();
                                        const formData = new FormData(event.currentTarget);
                                        const formJson = Object.fromEntries(formData.entries());
                                        const email = formJson.email;
                                        console.log(email);
                                        handleClose();
                                    },
                                }}
                            >
                                <DialogTitle>Subscribe</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        To subscribe to this website, please enter your email address here. We
                                        will send updates occasionally.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button type="submit">Subscribe</Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}