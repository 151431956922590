import React, { useState, useEffect } from 'react';
import EmployeeTransferInForm from './form';
import { useParams } from 'react-router-dom';
import { SingleData } from "api/employee-management/employeeTransferIn";

const UpdateEmployeeTransferIn = () => {
  const { id } = useParams();
  const [Data, setData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SingleData(id);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (Data === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <EmployeeTransferInForm Id={id} Data={Data} />
    </>
  );
};

export default UpdateEmployeeTransferIn;
