import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const Insert = async (props) => {  
  const url = ApiConfig.insertEmployeeTransferOut;
  const params = {
    ...props
  };
  const response = await api.post(url, params);
  return response.data;
};

export const Listing = async (url) => {  
  const response = await api.get(url);
  return response.data;
};

export const SingleData = async (id) => {
  const url = `${ApiConfig.getSingleEmployeeTransferOut}?id=${id}`;
  const response = await api.get(url);
  return response.data;
};

export const Delete = async (id) => {
  const url = `${ApiConfig.deleteEmployeeTransferOut}?id=${id}`;
  const response = await api.delete(url);
  return response.data;
};

export const Update = async (props) => {
  const url = `${ApiConfig.updateEmployeeTransferOut}?id=${props.id}`;
  const { id, CreatedAt, UpdatedAt, ...restProps} = props;
  const params = {
    ...restProps
  }
  const response = await api.put(url, params);
  return response.data;
};