import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ApiConfig } from '../../../../api/config/ApiConfig';
import { GetAssetMaintenance, DeleteAssetMaintenance } from '../../../../api/assetMaintenance';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import sharedImports from '../../../Comman/sharedImports';
import PaginationControls from '../../../Comman/paginationControls';
import PageSizeSelector from '../../../Comman/PageSizeSelector';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Link,
    Table,
    Typography,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    AddCircleOutlineOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetMaintenance(props) {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        asset_maintainance_no: '',
        asset_tag: '',
        department: '',
        asset_name: '',
        maintenance_date: null,
        location: '',
        next_maintenance_date: null,
        status: '',
        maintenance_cost: '',
        model_no: '',
        maintenance_by: '',
        category: '',
        document_no: '',
        document_date: null,
        maintenance_details: ''
    });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    
    const { baseUrl, assetMaintenanceList } = ApiConfig;
    const fetchInfo = async () => {
        const page=pagination.pageIndex+1;
        const per_page=pagination.pageSize;
        
        const url = new URL(
          assetMaintenanceList,
          baseUrl,
        );
        url.searchParams.set(
          'page',
          `${page}`,
        );
        url.searchParams.set('per_page', `${per_page}`);
        url.searchParams.set('globalFilter', globalFilter ?? '');
        url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
        url.searchParams.set('filters', JSON.stringify(filters ?? []));
        const response = await GetAssetMaintenance(url);
        switch (response.status) {
          case "success":
            const { data, totalCount } = await response;
            setData(data);
            setRowCount(totalCount);
            break;
          case "failed":
            enqueueSnackbar(response.message, { variant: "error" });
            break;
          default:
            break;
        }
    }    

    const handleExport = () => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'expenses.csv');
    };

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });
        
        fetchInfo();
    };

    const handleDelete = async (id) => {
        try {
            const response = await DeleteAssetMaintenance(id);
            switch (response.status) {
                case "success":
                  enqueueSnackbar(response.message, { variant: "success" });
                  setData((prevData) => prevData.filter((item) => item.id !== id));
                  break;
                case "failed":
                  enqueueSnackbar(response.message, { variant: "warning" });
                  break;
                case "error":
                  enqueueSnackbar(response.message, { variant: "error" });
                  break;
                default:                    
                  break;
              }
        } catch (error) {
            console.error('An error occurred while deleting the expense:', error);
        }
    };

    const handleSearch = () => {
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };

    const handleClear = () => {
        setFilters({ asset_maintainance_no: '', asset_tag: '', department: '', asset_name: '', maintenance_date: null, location: '', next_maintenance_date: null, status: '', maintenance_cost: '', model_no: '', maintenance_by: '', category: '', document_no: '', document_date: null, maintenance_details: '' });
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };

    useEffect(() => {
        fetchInfo();
    }, [globalFilter, sorting, pagination]);

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Maintenance
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Link to='/dashboard/support/asset-management/asset-maintenance/create'>
                                            <Button variant='contained' sx={{ textTransform: 'none', m: 1 }} startIcon={<AddCircleOutlineOutlinedIcon />}> Add </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Maintainance No.</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Maintainance No."
                                            value={filters.asset_maintainance_no}
                                            onChange={(e) => setFilters(prev => ({ ...prev, asset_maintainance_no: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={{ mr: 0.5, mb: 2, textTransform: 'none' }} onClick={handleSearch}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ ml: 0.5, mb: 2, textTransform: 'none' }} onClick={handleClear}>Clear</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Tag <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Tag"
                                            value={filters.asset_tag}
                                            onChange={(e) => setFilters(prev => ({ ...prev, asset_tag: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Department"
                                            value={filters.department}
                                            onChange={(e) => setFilters(prev => ({ ...prev, department: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Name"
                                            value={filters.asset_name}
                                            onChange={(e) => setFilters(prev => ({ ...prev, asset_name: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker 
                                                        value={filters.maintenance_date}
                                                        onChange={(newValue) => setFilters(prev => ({ ...prev, maintenance_date: newValue }))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Location"
                                            value={filters.location}
                                            onChange={(e) => setFilters(prev => ({ ...prev, location: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Next Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker 
                                                        value={filters.next_maintenance_date}
                                                        onChange={(newValue) => setFilters(prev => ({ ...prev, next_maintenance_date: newValue }))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Status"
                                            value={filters.status}
                                            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintainance Cost <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Maintainance Cost"
                                            value={filters.maintenance_cost}
                                            onChange={(e) => setFilters(prev => ({ ...prev, maintenance_cost: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Model No</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Model No"
                                            value={filters.model_no}
                                            onChange={(e) => setFilters(prev => ({ ...prev, model_no: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance By</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Maintenance By"
                                            value={filters.maintenance_by}
                                            onChange={(e) => setFilters(prev => ({ ...prev, maintenance_by: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Category </Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Category "
                                            value={filters.category}
                                            onChange={(e) => setFilters(prev => ({ ...prev, category: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Document No </Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Document No"
                                            value={filters.document_no}
                                            onChange={(e) => setFilters(prev => ({ ...prev, document_no: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Document Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker 
                                                        value={filters.document_date}
                                                        onChange={(newValue) => setFilters(prev => ({ ...prev, document_date: newValue }))}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance Details </Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Maintenance Details"
                                            value={filters.maintenance_details}
                                            onChange={(e) => setFilters(prev => ({ ...prev, maintenance_details: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={ (e) => setGlobalFilter(e.target.value) }
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={() => handleSort('asset_name')}>Asset Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('maintenance_date')}>Maintenance Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('department')}>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('maintenance_cost')}>Maintenance Cost<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <StyledTableRow key={row.asset_maintenance_no} sx={styles.TableRow}>
                                                <StyledTableCell>{row.asset_name}</StyledTableCell>
                                                <StyledTableCell>{row.maintenance_date}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                                <StyledTableCell>{row.maintenance_cost}</StyledTableCell>
                                                <StyledTableCell>
                                                    <EditOutlinedIcon onClick={() => navigate(`update/${row.asset_maintenance_no}`)} />
                                                    <DeleteOutlinedIcon onClick={() => handleDelete(row.asset_maintenance_no)} sx={{ ml: 1, color: 'red', cursor: 'pointer' }} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}