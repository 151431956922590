import sharedImports from '../../../Comman/sharedImports';
import PaginationControls from '../../../Comman/paginationControls';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import { ApiConfig } from '../../../../api/config/ApiConfig';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import * as Papa from 'papaparse';
import { Listing } from "api/employee-management/firstAidTrainingLog";

const {
    React,
    Box,
    Grid,
    Stack,
    Slide,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    PropTypes,
    DemoItem,
    DatePicker,
    FileCopyOutlinedIcon,
    DialogTitle,
    DialogActions,
    DialogContent,
    AdapterDayjs,
    TextField,
    FormControl,
    Typography,
    useScrollTrigger,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    DialogContentText,
    LocalizationProvider,
    ControlPointOutlinedIcon,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    styled,
    Table,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function FirstAidTrainingLog(props) {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [data, setData] = useState([]);

    const [filters, setFilters] = useState({ Code: '', FromDate: null, ToDate: null });
    const [tempFilters, setTempFilters] = useState({ Code: '', FromDate: null, ToDate: null });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    
    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({pageIndex: 0,pageSize: 10});

    const { baseUrl, getFirstAidTrainingLog } = ApiConfig;

    const formatDateToStringJS = (date) => {
        if (!date) return '';
        
        // Convert date to Date object if it’s not already one
        const validDate = date instanceof Date ? date : new Date(date);        
        if (isNaN(validDate.getTime())) {
            console.error("Invalid date format:", date);
            return '';
        }    
        const localDate = new Date(validDate.getTime() - validDate.getTimezoneOffset() * 60000);        
        return localDate.toISOString().split('T')[0];
    };    
    
    const fetchInfo = async () => {
        try {
            const page=pagination.pageIndex+1;
            const per_page=pagination.pageSize;            
            const url = new URL(getFirstAidTrainingLog,baseUrl);
            url.searchParams.set('page',`${page}`);
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            url.searchParams.set('Code', filters.Code ?? '');
            url.searchParams.set('FromDate', formatDateToStringJS(filters.FromDate));
            url.searchParams.set('ToDate', formatDateToStringJS(filters.ToDate));
            const response = await Listing(url);
            if (response.status) {
                const { data, totalCount } = await response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }   

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });        
        fetchInfo();
    };
    
    const handleExport = () => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'first-aid-training-log.csv');
    };

    const handleSearch = () => {
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        setFilters(tempFilters); // 
    };

    const handleClear = () => {
        setFilters({ Code: '', FromDate: null, ToDate: null });
        setTempFilters({ Code: '', FromDate: null, ToDate: null });
        setGlobalFilter('');
        setSorting([]);
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };
    
    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting, filters]);


    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
            mt: 2
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        }
    }

    return (
        <>
            <React.Fragment>
                <Box sx={styles.root}>
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            First Aid Training Log
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={1}>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}
                                                startIcon={<ControlPointOutlinedIcon />}>
                                                Add
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={styles.firstForm}>
                        <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker value={tempFilters.FromDate}
                                                    onChange={(newValue) => setTempFilters(prev => ({ ...prev, FromDate: newValue }))} />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker value={tempFilters.ToDate}
                                                    onChange={(newValue) => setTempFilters(prev => ({ ...prev, ToDate: newValue }))} />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex. 10, 155 etc.)</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Code Like (Ex. 10, 155 etc.)"
                                            name='Code'
                                            value={tempFilters.Code}
                                            onChange={(e) => setTempFilters(prev => ({ ...prev, Code: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom2}>
                                        <Button variant='contained' startIcon={<SearchOutlinedIcon />} onClick={handleSearch} sx={{ mr: 0.5, mb: 2 }}>Search</Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} onClick={handleClear} sx={{ ml: 0.5, mb: 2 }}>Clear</Button>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>            
                </Box>
                <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                    <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                
                                <Grid item>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                    <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField
                                    id="filled-search"
                                    type="search"
                                    size='small'
                                    value={globalFilter}
                                    onChange={ (e) => setGlobalFilter(e.target.value) }
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell onClick={() => handleSort('code')}>
                                            Code
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleSort('first_name')}>
                                            Name
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleSort('first_aid_training_date')}>
                                            First Aid Training Date
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleSort('created_at')}>
                                            Create Date
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    rowCount === 0 ? (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={7} align="center">
                                                No Log found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        data.map((row) => (
                                            <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                <StyledTableCell>{row.code}</StyledTableCell>
                                                <StyledTableCell>{row.first_name} {row.middle_name} {row.last_name}</StyledTableCell>
                                                <StyledTableCell>{ (row.first_aid_training_date)?moment(row.first_aid_training_date).format('DD/MM/YYYY'):''}</StyledTableCell>
                                                <StyledTableCell>{moment(row.created_at).format('DD/MM/YYYY')}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationControls
                            pagination={pagination}
                            rowCount={rowCount}
                            setPagination={setPagination}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}