import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeLegalEntity = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeLegalEntity = async (props) => {
  const url = `${ApiConfig.employeeLegalEntityMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_legal_entity_id) => {
  const url = `${ApiConfig.employeeLegalEntityMain}?id=${employee_legal_entity_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeLegalEntity = async (props) => {
  const url = `${ApiConfig.employeeLegalEntityMain}?id=${props.employee_legal_entity_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeLegalEntity = async (employee_legal_entity_id) => {
    const url = `${ApiConfig.employeeLegalEntityMain}?id=${employee_legal_entity_id}`;
    const response = await api.delete(url);
    return response.data;
};
