import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import dayjs from 'dayjs';
import {InsertCreateExpense, UpdateExpense} from '../../../../api/expense';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import styles from 'css/styles';
const {
    React,
    Box,
    Grid,
    Link,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    SaveOutlinedIcon,
    Typography,
    CssBaseline,
    FormControl,
    CancelOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    FormatListBulletedOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;

export default function ExpenseForm(props) {
    const [formData, setFormData] = useState({
        expense_date: null,
        expense_group: "",
        expense_sub_group: "",
        remark: "",
        amount: "",
    });
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            expense_date: date,
        }));
    }; 

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
               response = await UpdateExpense(formData); 
            } else {
                const newData = { ...formData };
                response = await InsertCreateExpense(newData);
            }           

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                navigate('/dashboard/support/expense');
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }

    useEffect(() => {
        if (props.expenseId && props.expenseData) {
            setIsEditing(true);
            setFormData({
                ...props.expenseData,
                expense_date: props.expenseData.expense_date ? dayjs(props.expenseData.expense_date) : null,
            });
        }
    }, [props.expenseId, props.expenseData]);


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        Expense Create
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }} onClick={handleSubmit}> {isEditing ? 'Update' : 'Save'} </Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Link to='/dashboard/support/expense/expense-report'>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> List </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Expense Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker
                                                        value={formData.expense_date}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Expense Group <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="expense_group"
                                                value={formData.expense_group}
                                                onChange={handleInputChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Male nurse salary</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Expense Sub Group <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="expense_sub_group"
                                                value={formData.expense_sub_group}
                                                onChange={handleInputChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Male nurse salary</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Remark</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Remark'
                                            name="remark"
                                            value={formData.remark}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Amount  <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Amount'
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}