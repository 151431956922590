import React from 'react';
import { Grid, Button } from '@mui/material';

const PaginationControls = ({ pagination, rowCount, setPagination }) => {
    const { pageIndex, pageSize } = pagination;

    const handlePrevPage = () => {
        setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    };

    const handleNextPage = () => {
        setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    };

    return (
        <Grid container spacing={2} direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
            <Grid item sx={{ fontWeight: 'bold' }}>
                {`Showing ${rowCount === 0 ? 0 : pageIndex * pageSize + 1} to ${ Math.min((pageIndex + 1) * pageSize, rowCount) } of ${rowCount} entries`}
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    sx={{ mr: 2, textTransform: 'none' }}
                    disabled={pageIndex === 0}
                    onClick={handlePrevPage}
                >
                    Prev
                </Button>
                <Button
                    variant='contained'
                    sx={{ textTransform: 'none' }}
                    disabled={(pageIndex + 1) * pageSize >= rowCount}
                    onClick={handleNextPage}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export default PaginationControls;