import { ApiConfig } from "./config/ApiConfig";
import { api } from "api/config/axiosInterceptor";
import { buildQueryString } from "helper/index";

export const GetUserRole = async (parmas) => {  
  const query = buildQueryString(parmas);  
  const url = `${ApiConfig.userRoleUrl}?${query}`;
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateUserRole = async (props) => { 
  const url = `${ApiConfig.userRoleUrl}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (user_role_id) => {  
  const url = `${ApiConfig.singleUserRoleUrl}?id=${user_role_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateUserRole = async (props) => {
  const url = `${ApiConfig.userRoleUrl}?id=${props.user_role_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteUserRole = async (user_role_id) => {
  const url = `${ApiConfig.userRoleUrl}?id=${user_role_id}`;
    const response = await api.delete(url);
    return response.data;
};