import { api, fileApi } from "api/config/axiosInterceptor";
import { ApiConfig } from "api/config/ApiConfig";
import { buildQueryString } from "helper/index";
export const UploadEmployeeDocument = async (formData) => {
  try {
    const url = `${ApiConfig.employeeDocumentUploadUrl}`;
    const response = await fileApi.post(url, formData);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const DeleteEmployeeDocument = async (parmas) => {
  try {
    const query = buildQueryString(parmas);
    const url = `${ApiConfig.employeeDocumentUploadUrl}?${query}`;
    const response = await api.delete(url);
    return response.data;
  } catch (err) {
    return err
  }
};

export const CustomSearchEmployee = async (parmas) => {
  try {
    const query = buildQueryString(parmas);
    const url = `${ApiConfig.listEmployeeCustomSearch}?${query}`;
    const response = await api.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const EmployeeCustomFields = async () => {
  try {
    const url = `${ApiConfig.employeeCustomFields}`;
    const response = await api.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const EmployeeFieldUpdate = async (data) => {
  try {
    const url = `${ApiConfig.employeeCustomFields}`;
    const response = await api.post(url, data);
    return response.data;
  } catch (err) {
    return err;
  }
};
