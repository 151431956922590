import sharedImports from '../../../Comman/sharedImports';
const {
    Box,
    Grid,
    React,
    Table,
    Paper,
    Slide,
    Select,
    AppBar,
    styled,
    Button,
    Toolbar,
    DemoItem,
    TableRow,
    MenuItem,
    TableHead,
    PropTypes,
    TableBody,
    TableCell,
    TextField,
    DatePicker,
    Typography,
    FormControl,
    CssBaseline,
    AdapterDayjs,
    TableContainer,
    useScrollTrigger,
    tableCellClasses,
    PrintOutlinedIcon,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { test: null, target: null, done: null, notDone: null, select: null }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function TestAnalysis(props) {
    const [state, setState] = React.useState({
        examinationType: '',
        round: '',
        TargetGroup: '',
        DepartmentGroup: '',
        Department: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Test Analysis
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="examinationType"
                                                value={state.examinationType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="round"
                                                value={state.round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="TargetGroup"
                                                value={state.TargetGroup}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-ALL-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>General</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Noise</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Dust</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Noise and Dust</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Canteen</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 1 }}>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department Group</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="DepartmentGroup"
                                                value={state.DepartmentGroup}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-ALL-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Asset Integrity</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Information Technology</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Investor Relation</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Iron Ore Mining</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Projects_Civil</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>ABEX</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>ACCOUNT</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Adani North America</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={9}>ADHOC</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>ADMIN</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={11}>Adimin - ACH</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={12}>Admin - Uttar Pradesh</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={13}>Admin - Aravalli</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={14}>Admin_AGEL_Ahme</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={15}>Administraion</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={16}>Administraion_BIOMPL_Bailadila</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={17}>Administration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={18}>Administration - ACH</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department </Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Department"
                                                value={state.Department}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-ALL-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 4 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<PrintOutlinedIcon />}>Print</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }}>TargetGroupCount</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Total Records:0
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Test<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Target<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Done<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Not Done<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Select<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.test} sx={styles.TableRow}>
                                                <StyledTableCell> {row.test} </StyledTableCell>
                                                <StyledTableCell>{row.target}</StyledTableCell>
                                                <StyledTableCell>{row.done}</StyledTableCell>
                                                <StyledTableCell>{row.notDone}</StyledTableCell>
                                                <StyledTableCell>{row.select}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}