import { fileApi } from "api/config/axiosInterceptor";
import { ApiConfig } from "api/config/ApiConfig";
export const UploadDocument = async (formData) => {
  try {
    console.log("===formData", formData);
    
    const url = `${ApiConfig.documentUploadUrl}`;
    const response = await fileApi.post(url, formData);
    return response.data;
  } catch (err) {
    return err;
  }
};