import sharedImports from '../../../Comman/sharedImports';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import GestureRoundedIcon from '@mui/icons-material/GestureRounded';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Slide,
    Select,
    Button,
    Toolbar,
    PropTypes,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    useScrollTrigger,
    HighlightOffIcon,
    LocalPhoneIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        Country: '',
        State: '',
        Service: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Affiliated Service
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        mt: 7,
                        width: "100%",
                    },
                }}
            >

                <Card variant="outlined" sx={{ padding: 3 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Country</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Country"
                                            value={state.Country}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>INDIA</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>State</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="State"
                                            value={state.State}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Gujarat</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>City</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='Ahmedabad'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Service</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Service"
                                            value={state.Service}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>BLOOD BANK</>
                                            </MenuItem>
                                            <MenuItem value={1}></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Provider Search</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center' }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>
                </Card>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={3} >
                    <Card sx={{ height: 300, ml: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: '#1976d2', mb: 2 }} >
                                BLOOD BANK
                            </Typography>
                            <Box sx={{ fontWeight: 'bold', mb: 3 }}>
                                Red Cross Society
                            </Box>
                            <Box>
                                <Typography>
                                    Speciality
                                </Typography>
                                <Typography>
                                    Blood Bank
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 20, mt: -6 }}>
                                <Typography>
                                    CashLess
                                </Typography>
                                <Typography>
                                    false
                                </Typography>
                            </Box>
                        </CardContent>
                        <hr />
                        <Typography sx={{ ml: 2, mb: .5 }}><LocalPhoneIcon sx={{ color: '#1976d2' }} />9978925297</Typography>
                        <Typography sx={{ ml: 2 }}><AccessAlarmsIcon sx={{ color: '#1976d2' }} />10: 00 am to 05:00 pm</Typography>
                        <GestureRoundedIcon sx={{ ml: 2, color: '#1976d2' }} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card sx={{ height: 300, ml: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: '#1976d2', mb: 2 }} >
                                BLOOD BANK
                            </Typography>
                            <Box sx={{ fontWeight: 'bold', mb: 3 }}>
                                GCRI
                            </Box>
                            <Box>
                                <Typography>
                                    Speciality
                                </Typography>
                                <Typography>
                                    Blood Bank
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 20, mt: -6 }}>
                                <Typography>
                                    CashLess
                                </Typography>
                                <Typography>
                                    false
                                </Typography>
                            </Box>
                        </CardContent>
                        <hr />
                        <Typography sx={{ ml: 2, mb: .5 }}><LocalPhoneIcon sx={{ color: '#1976d2' }} />9978925297</Typography>
                        <Typography sx={{ ml: 2 }}><AccessAlarmsIcon sx={{ color: '#1976d2' }} />10: 00 am to 05:00pm</Typography>
                        <GestureRoundedIcon sx={{ ml: 2, color: '#1976d2' }} />
                    </Card>
                </Grid>

            </Grid>
        </>
    );
}