import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { importEmployeeExcelFile, getAllEmployeeTemporaryData, getSubEmployee, saveTemDataToEmployee, DeleteEmployeeTemData, exportEmployeeSubType, exportDepartment, exportDesignation, exportTargetGroup, exportGrade } from '../../../../api/user';
import { useSnackbar } from 'notistack';
import { getAllInputJobDetailData, getEmployee } from '../../../../api/user';
import styles from 'css/styles';
import dayjs from 'dayjs';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import { validateFileType } from './importCommonFunctions.js/importFile';
import * as XLSX from 'xlsx';
import { getBloodGroups } from 'helper/employee/employeeHelpers';

const {
    React,
    PropTypes,
    Box,
    Grid,
    Radio,
    Alert,
    Slide,
    AppBar,
    Button,
    Toolbar,
    RadioGroup,
    Typography,
    CssBaseline,
    FormControlLabel,
    useScrollTrigger,
    TextField,
    DatePicker,
    NoteAddOutlinedIcon,
    FileUploadOutlinedIcon,
    AdapterDayjs,
    LocalizationProvider,
    FormControl,
    Select,
    MenuItem,
} = sharedImports;

const drawerWidth = 300;

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function ImportEmployee(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [file, setFile] = useState(null);
    const [temporaryData, setTemporaryData] = useState([]);
    const [temData, setTemData] = React.useState({
        gender: '',
        code: '',
        work_location: '',
        employee_status: '',
        employee_type_group: '',
        employee_type: '',
        employee_sub_type: '',
        first_name: '',
        date_of_birth: null,
        date_of_joining: null,
        department: '',
        grade: '',
        employee_group: '',
        designation: '',
        target_group: '',
        process_type: '',
        company: '',
        email: '',
        phone_number: '',
        blood_group: '',
        company_phone_no: '',
        extension_no: '',

    });
    const [employeeTemCount, setEmployeeTemCount] = useState(1);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && validateFileType(selectedFile)) {
            setFile(selectedFile);
        } else {
            enqueueSnackbar("Please select an Excel file (.xlsx or .xls, csv)", { variant: "warning" });
            setFile(null);
        }
    };

    const handleDateChange = (date, name) => {
        setTemData((prevData) => ({
            ...prevData,
            [name]: date
        }));
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        setTemData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'employee_type_group' && value) {
            try {
                const response = await getEmployee(value);
                setEmplyGroupId(value);
                setEmployeeType(response.data)
                setTemData(prevState => ({
                    ...prevState,
                    employee_type: '',
                    employee_sub_type: ''
                }))
                setEmployeeSubType([]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (name === 'employee_type_group' && !value) {
            setTemData(prevState => ({
                ...prevState,
                employee_type: '',
                employee_sub_type: ''
            }))
            setEmployeeSubType([]);
            setEmployeeType([]);
        }

        if (name === 'employee_type' && value) {
            try {
                const gID = emplyGroupId;
                const response = await getSubEmployee(value, gID);
                setEmployeeSubType(response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (name === 'employee_type' && !value) {
            setEmployeeSubType([]);
            setTemData(prevState => ({
                ...prevState,
                employee_sub_type: ''
            }))
        }
    };

    useEffect(() => {
        fetchData();
        getAllInputJobDetail();
    }, []);

    const handleFileUpload = async () => {
        try {
            if (!file) {
                enqueueSnackbar("Please select a file", { variant: "warning" });
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            formData.append('employee_import_action', selectedValue);

            const response = await importEmployeeExcelFile(formData);

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchData();
                setCurrentEmpIndex(0);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            console.error('Error uploading file:', err);
        }
    };

    const fetchData = () => {
        getAllEmployeeTemporaryData().then((res) => {

            if (res?.data?.tempData.length > 0) {
                setTemporaryData(res?.data?.tempData);
                setEmployeeTemCount(res.data.temporaryDataCount);
                setSelectedValue(res?.data?.tempData[0]?.import_action_type);
            } else {
                setTemporaryData([]);
            }
        });
    }

    /* Get all input values of Job Detail */
    const [inputData, setInputData] = useState({
        employeeDepartment: [],
        employeeGrade: [],
        employeeGroup: [],
        employeeProcessType: [],
        employeeTargetGroup: [],
        employeeTypeGroup: [],
        employeeWorkLocation: [],
        employeeCompany: [],
        employeeDesignation: []
    });
    const [employeeType, setEmployeeType] = useState([])
    const [employeeSubType, setEmployeeSubType] = useState([])
    const [currentEmpIndex, setCurrentEmpIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState('0');

    const [emplyGroupId, setEmplyGroupId] = useState(null)

    const getAllInputJobDetail = async () => {
        try {
            const response = await getAllInputJobDetailData();

            if (response.status) {
                const { data } = response;
                const stateFields = [
                    'employeeDepartment',
                    'employeeGrade',
                    'employeeGroup',
                    'employeeProcessType',
                    'employeeTargetGroup',
                    'employeeTypeGroup',
                    'employeeWorkLocation',
                    'employeeCompany',
                    'employeeDesignation'
                ];

                // Dynamically setting state for each field
                const stateData = {};
                stateFields.forEach(field => {
                    stateData[field] = data[field] || [];
                });

                setInputData(stateData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            console.error('Error fetching employment grades:', err);
        }

    }

    const renderMenuItems = (data, value) => {
        return data.map(item => (
            <MenuItem sx={{ ml: 3 }} value={item[value]}>
                {item['name']}
            </MenuItem>
        ));
    };

    const renderEmployeType = (data, value) => {
        return data.map(item => (
            <MenuItem sx={{ ml: 3 }} value={item[value]} >
                {item['name']}
            </MenuItem>
        ));
    };

    const handleSave = async (emp_tem_id) => {
        const newData = { ...temData, emp_tem_id: emp_tem_id, import_action_type: selectedValue };
        try {
            const response = await saveTemDataToEmployee(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                let newTempData = [...temporaryData];
                newTempData[currentEmpIndex].missing_fields = "";
                setTemporaryData(newTempData);
                setEmployeeTemCount(response.data);
                setTemData({
                    gender: '',
                    code: '',
                    work_location: '',
                    employee_status: '',
                    employee_type_group: '',
                    employee_type: '',
                    employee_sub_type: '',
                    first_name: '',
                    date_of_birth: null,
                    date_of_joining: null,
                    department: '',
                    grade: '',
                    employee_group: '',
                    designation: '',
                    target_group: '',
                    process_type: '',
                    company: '',
                    email: '',
                    phone_number: '',
                    blood_group: '',
                    company_phone_no: '',
                    extension_no: '',
                });
                if (currentEmpIndex < temporaryData.length - 1) {
                    setCurrentEmpIndex(prevIndex => prevIndex + 1);
                }
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleDelete = async (temID) => {
        try {
            const response = await DeleteEmployeeTemData(temID);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                let newTempData = [...temporaryData];
                newTempData[currentEmpIndex].missing_fields = "";
                setTemporaryData(newTempData);
                setEmployeeTemCount(response.data);
                setTemData({
                    gender: '',
                    code: '',
                    work_location: '',
                    employee_status: '',
                    employee_type_group: '',
                    employee_type: '',
                    employee_sub_type: '',
                    first_name: '',
                    date_of_birth: null,
                    date_of_joining: null,
                    department: '',
                    grade: '',
                    employee_group: '',
                    designation: '',
                    target_group: '',
                    process_type: '',
                    company: '',
                    email: '',
                    phone_number: '',
                    blood_group: '',
                    company_phone_no: '',
                    extension_no: '',
                })
                if (currentEmpIndex < temporaryData.length - 1) {
                    setCurrentEmpIndex(prevIndex => prevIndex + 1);
                }
            }
            else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }

    const boodGroups = getBloodGroups();

    const handleExport = async (endpoint, fileName) => {
        try {
            const response = await endpoint;
            if (response.status) {
                const data = response.data.data;
                const worksheet = XLSX.utils.json_to_sheet(data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
                XLSX.writeFile(workbook, `${fileName}.xlsx`);
                enqueueSnackbar(response.message, { variant: "success" });
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    /* Get all input values of Job Detail */

    const renderInputFields = (missingFields, emp_tem_id) => {
        if (!missingFields) return;
        const fieldsArray = missingFields.split(', ').map(field => field.trim());

        return (
            <Grid container spacing={2} sx={{ mt: 2, mb: 8 }}>
                {fieldsArray.map(field => (
                    <Grid item xs={12} sm={6} md={4} key={field}>
                        {field === 'Employee Code' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Code
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="code"
                                    value={temData.code || ""}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Code" />
                            </>
                        )}

                        {field === 'FirstName' && (
                            <>
                                <Box sx={[styles.box]}>
                                    First Name
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="first_name"
                                    value={temData.first_name || ""}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="First Name"
                                />
                            </>
                        )}

                        {field === 'Gender' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Gender
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="gender"
                                        value={temData.gender || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="male">Male</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="female">Female</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="other">Others</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'DateOfBirth' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Date of Birth
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={temData.date_of_birth}
                                            onChange={(date) => handleDateChange(date, 'date_of_birth')}
                                            renderInput={(params) => <TextField {...params} label="Date of Birth" variant="outlined" />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </>
                        )}

                        {field === 'DateOfJoining' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Date of Joining
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={temData.date_of_joining}
                                            onChange={(date) => handleDateChange(date, 'date_of_joining')}
                                            renderInput={(params) => <TextField {...params} label="Date of Joining" variant="outlined" />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </>
                        )}

                        {field === 'Location' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Work Location
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="work_location"
                                        value={temData.work_location || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select Location-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeWorkLocation, 'employee_work_location_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Status' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Employee Status
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="employee_status"
                                        value={temData.employee_status || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="active">Active</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="inActive">In Active</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'EmpTypeGroup' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Employee Type Group
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="employee_type_group"
                                        value={temData.employee_type_group || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Selects-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeTypeGroup, 'employee_type_group_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'EmpType' && (
                            <>
                                <Box sx={[styles.box]}>
                                    Employee Type
                                    <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="employee_type"
                                        value={temData.employee_type || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderEmployeType(employeeType || [], 'employee_type_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'EmpSubType' && (
                            <>
                                <Box sx={[styles.box]}> Employee Sub Type <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="employee_sub_type"
                                        value={temData.employee_sub_type || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderEmployeType(employeeSubType || [], 'employee_sub_type_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Department' && (
                            <>
                                <Box sx={[styles.box]}> Department <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="department"
                                        value={temData.department || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeDepartment, 'employee_department_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Grade' && (
                            <>
                                <Box sx={[styles.box]}> Grade <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="grade"
                                        value={temData.grade || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeGrade, 'employee_grade_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Designation' && (
                            <>
                                <Box sx={[styles.box]}> Designation <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="designation"
                                        value={temData.designation || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeDesignation, 'employee_designation_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'TargetGroup' && (
                            <>
                                <Box sx={[styles.box]}> Target Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="target_group"
                                        value={temData.target_group || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeTargetGroup, 'employee_target_group_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'ProcessType' && (
                            <>
                                <Box sx={[styles.box]}> Process Type <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="process_type"
                                        value={temData.process_type || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeProcessType, 'employee_process_type_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'EmpGroup' && (
                            <>

                                <Box sx={[styles.box]}> Employee Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="employee_group"
                                        value={temData.employee_group || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeGroup, 'employee_group_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Company' && (
                            <>
                                <Box sx={[styles.box]}> Company <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="company"
                                        value={temData.company || ""}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeCompany, 'employee_company_id')}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'Email' && (
                            <>
                                <Box sx={[styles.box]}> Email <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    id="email"
                                    fullWidth
                                    size='small'
                                    name="email"
                                    value={temData.email || ""}
                                    onChange={handleChange}
                                    type='email'
                                    placeholder='Email'
                                />
                            </>
                        )}

                        {field === 'MobileNo' && (
                            <>
                                <Box sx={[styles.box]}> Mobile Number <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    required
                                    id="phone_number"
                                    fullWidth
                                    size='small'
                                    name="phone_number"
                                    value={temData.phone_number || ""}
                                    onChange={handleChange}
                                    type='number'
                                    placeholder='Mobile Number'
                                />
                            </>
                        )}

                        {field === 'BloodGroup' && (
                            <>
                                <Box sx={[styles.box]}> Blood Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        name="blood_group"
                                        value={temData.blood_group}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        {boodGroups.map((boolGroup, index) => (
                                            <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup.value}>
                                                {boolGroup.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {field === 'CompanyPhone' && (
                            <>
                                <Box sx={[styles.box]}> Company Phone No <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    required
                                    id="company_phone_no"
                                    fullWidth
                                    size='small'
                                    name="company_phone_no"
                                    value={temData.company_phone_no}
                                    onChange={handleChange}
                                    type='number'
                                    placeholder='Company Phone No'
                                />
                            </>
                        )}

                        {field === 'Extention' && (
                            <>
                                <Box sx={[styles.box]}> Extension No <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                </Box>
                                <TextField
                                    required
                                    id="extension_no"
                                    fullWidth
                                    size='small'
                                    name="extension_no"
                                    value={temData.extension_no}
                                    onChange={handleChange}
                                    type='number'
                                    placeholder='Extension No'
                                />
                            </>
                        )}

                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                    Import Employee
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                            Notes: Text data to be Separated
                            <br />
                            Auto-entry Fields: Employee Group, Employee Type Group, Employee Type, Employee Sub Type, Company, Legal Entity, Designation, Location, Target Group, Grade
                            <br />
                            Mandatory Fields: Code, First Name, Date of Birth, Gender, Status, Date of Joining, Department, Employee Type Group, Employee Type, Employee Sub Type,
                            <br />
                            Location, Grade, Designation, Target Group, Process Type & Employee Group
                        </Alert>
                        <Box component="div" xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group"
                                name="row-radio-buttons-group"
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                            >
                                <FormControlLabel
                                    value='0'
                                    control={<Radio />}
                                    label='New Employee'
                                    disabled={temporaryData && temporaryData.length > 0 && employeeTemCount > 0}
                                />
                                <FormControlLabel
                                    value='1'
                                    control={<Radio />}
                                    label='Update Employee'
                                    disabled={temporaryData && temporaryData.length > 0 && employeeTemCount > 0}
                                />
                            </RadioGroup>
                            <Box component='div'>
                                <Button sx={{ textTransform: 'none' }} href="/templates/Import_EmployeesDynamic.xls" download>Download Template</Button>
                            </Box>
                            <Grid spacing={2} xm={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2, mr: 3 }}>
                                <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput"
                                    accept=".csv, .xlsx, .xls"
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => document.getElementById('fileInput').click()}
                                    startIcon={<FileUploadOutlinedIcon />}
                                    sx={{ mr: 3 }}
                                    disabled={temporaryData && temporaryData.length > 0 && employeeTemCount > 0}
                                >
                                    Select File
                                </Button>
                                <Button variant='contained' onClick={handleFileUpload} sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />} disabled={temporaryData && temporaryData.length > 0 && employeeTemCount > 0}>Import</Button>
                            </Grid>
                            <Grid container spacing={2} direction='row' justifyContent='center'>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: 'none' }}
                                        startIcon={<NoteAddOutlinedIcon />}
                                        onClick={() => handleExport(exportEmployeeSubType(), 'employeeSubTypes')}
                                    >
                                        Export Emp Sub Type
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: 'none' }}
                                        startIcon={<NoteAddOutlinedIcon />}
                                        onClick={() => handleExport(exportDepartment(), 'employeeDepartmentTypes')}
                                    >
                                        Export Department
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: 'none' }}
                                        startIcon={<NoteAddOutlinedIcon />}
                                        onClick={() => handleExport(exportDesignation(), 'employeeDesignation')}
                                    >
                                        Export Designation
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: 'none' }}
                                        startIcon={<NoteAddOutlinedIcon />}
                                        onClick={() => handleExport(exportTargetGroup(), 'employeeTargetGroup')}
                                    >
                                        Export Target Group
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: 'none' }}
                                        startIcon={<NoteAddOutlinedIcon />}
                                        onClick={() => handleExport(exportGrade(), 'employeeGrade')}
                                    >
                                        Export Grade
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* Render missing input fields based on temporary data */}
                        {temporaryData && temporaryData.length > 0 && employeeTemCount > 0 && (
                            <Box sx={{ mt: 4 }}>
                                <div key={temporaryData[currentEmpIndex]?.emp_tem_id}>
                                    <Box style={{ ...styles.dFlex }} sx={{ bgcolor: 'white', pl: 3, borderRadius: 1 }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={6} md={10}>
                                                <h4 style={{ paddingLeft: '2px' }}>Employee Temp ID: {temporaryData[currentEmpIndex]?.emp_tem_id}</h4>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2} style={{
                                                display: 'flex',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                justifyContent: 'space-evenly',
                                            }}>
                                                <Button variant="contained" color="primary" onClick={() => handleSave(temporaryData[currentEmpIndex]?.emp_tem_id)}>
                                                    Save {employeeTemCount !== 1 ? "& Next" : ""}
                                                </Button>
                                                <DeleteButton
                                                    id={temporaryData[currentEmpIndex]?.emp_tem_id}
                                                    fieldName={`Employee Temp ID: ${temporaryData[currentEmpIndex]?.emp_tem_id}`}
                                                    index={currentEmpIndex}
                                                    handleDeleteRow={() => handleDelete(temporaryData[currentEmpIndex]?.emp_tem_id)}
                                                    titleName="Employee"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Render missing fields for the current employee */}
                                    {renderInputFields(temporaryData[currentEmpIndex]?.missing_fields, temporaryData[currentEmpIndex]?.emp_tem_id)}
                                </div>
                            </Box>
                        )}
                    </Box>
                </Box>
            </React.Fragment>
        </>
    );
}
