import React, { useState } from 'react';
import { Tabs, Tab, Select, MenuItem, FormControl, Button } from '@mui/material';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import sharedImports from '../../../Comman/sharedImports';
const {
  AppBar,
  Box,
  Paper,
  Grid,
  Card,
  Slide,
  Table,
  styled,
  Toolbar,
  TableRow,
  TableCell,
  PropTypes,
  Typography,
  CardContent,
  TableContainer,
  tableCellClasses,
  useScrollTrigger,
  TableBody,

} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const pieParams = { height: 200, margin: { right: 5 } };
const palette = ['#006c18', '#ff8531', '#ff2123', '#e45e53', '#b73933', '#00d630',];



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td': {
    borderBottom: 0,
  },
}));
const reportListRows = [
  { Page: 'Adani Group', Button: '84.05' },
  { Page: 'Adani Infrastructure Management', Button: '86.94' },
  { Page: 'Adani Internatopnal School', Button: '79.17' },
  { Page: 'AEL Support Service', Button: '85.98' },
  { Page: 'Adani Infrastructure Management', Button: '90.59' },
  { Page: 'Agri', Button: '89.84' },
  { Page: 'Airports', Button: '87.29' },
  { Page: 'Corporate Services', Button: '86.48' },
];

const reportListRows1 = [
  { Page: '18 to 35 ', Button: '89.32' },
  { Page: '36 to 45', Button: '86.39' },
  { Page: '46 to 55', Button: '85.03' },
  { Page: '56 and above', Button: '84.31' },
];

const reportListRows2 = [
  { Page: 'Adani Group', Button: '75.83' },
  { Page: 'Adani Infrastructure Management', Button: '84.58' },
  { Page: 'Agri', Button: '96.67' },
  { Page: 'Airports', Button: '84.73' },
];

const reportListRows3 = [
  { Page: 'High Risk' },
  { Page: 'Moderate Risk (1)' },
  { Page: 'Low Risk' },
  { Page: 'Healthy' },
];

const reportListRows4 = [
  { Page: 'Overweight', Button: 5232 },
  { Page: 'Prehypertension', Button: 502 },
  { Page: 'Hypertension', Button: 1017 },
  { Page: 'Prediabetic', Button: 2133 },
];

const reportListRows5 = [
  { Page: 'Adani Internatopnal School', Button: 0 },
  { Page: 'Aluminum', Button: 0 },
  { Page: 'Beach Sand Mining', Button: 1 },
  { Page: 'Hydro Projects', Button: 0 },
];

const reportListRows6 = [
  { Page: 'Thermal Projects', Button: 61 },
  { Page: 'Thermal Generations', Button: 475 },
];

const reportListRows7 = [
  { Page: 'Ahmedabad', Button: 21 },
  { Page: 'Godda', Button: 40 },
];

const reportListRows8 = [
  { Page: 'Ashish Patodia' },
  { Page: 'K A V Prasada Reddy' },
  { Page: 'Samik Gupta' },
  { Page: 'Sanjaykumar Patel' },
];

const reportListRows9 = [
  { Page: 30059502, Text: 'N Raj Vamshee' },
  { Page: 30054450, Text: 'A Balasubramanian' },
  { Page: 30013901, Text: 'Aakash Sharma' },
  { Page: 30060740, Text: 'Aashish Dubey K' },
];

const styles = {
  TableRow: {
    th: {
      p: 1,
      svg: {
        float: 'right',
        color: '#1976d2'
      },
    },
    td: {
      border: '1px solid lightgray',
      p: 1,
    }
  },
  h: {
    minHeight: '40px !important'
  },
  bgWhite: {
    bgcolor: 'white',
    color: 'black'
  }
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  backgroundColor: 'white',
  color: selected ? '#1976d2' : 'black',
  borderTop: selected ? '3px solid #1976d2' : '1px solid #dfdcdc',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '&.Mui-selected': {
    backgroundColor: '#1976d2',
    color: 'white',
  },
}));

function App(props) {
  const [state, setState] = React.useState({
    RoundGroup: '',
    RoundGroup1: '',
    RoundGroup2: '',
    RoundGroup3: '',
    RoundGroup4: '',
    RoundGroup5: '',
    RoundGroup6: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: { xs: '53px', sm: '64px' },
            zIndex: 1099
          }}
          elevation={1}
        >
          <Toolbar sx={[styles.h, styles.bgWhite]}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item>
                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                  MIS Examination
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Box sx={{ display: 'flex', mt: 7, }}>
        <Box sx={{ width: '20%', m: 2, boxShadow: '12px 12px 10px 1px rgba(0, 0, 0, .2)', height: 'fit-content' }}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Sidebar Tabs"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {['Business Health', 'Funtional Health', 'Age Health', 'MIS', 'Graphical Health Indicator', 'Disease Count', 'Annual Health Check-up Summary', 'Compare Health'].map((label, index) => (
              <StyledTab
                key={label}
                label={label}
                selected={selectedTab === index}
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ width: '80%', m: 2, ml: 0, p: 2 }}>
          {selectedTab === 0 &&
            <Box>
              <Box sx={{ mb: .5, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="RoundGroup"
                        value={state.RoundGroup}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <></>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2022-23</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={9}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1 }}>
                <Box component="h4">Business Wise Health Index</Box>
                <TableContainer component={Paper} sx={{ height: '250px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

          }
          {selectedTab === 1 && <Typography>Content for Tab 2</Typography>}

          {selectedTab === 2 &&
            <Box>
              <Box sx={{ mb: .5, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="RoundGroup1"
                        value={state.RoundGroup1}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>Annual Health Checkup 2022-23</>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Age Wise Health Index
                </Box>
                <TableContainer component={Paper} sx={{ height: '250px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows1.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', p: 2, color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography>56 and above</Typography>
                        <Typography>84.31</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Business Wise Health Index
                </Box>
                <TableContainer component={Paper} sx={{ height: '250px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows2.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', p: 2, color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography>56 and above</Typography>
                        <Typography>84.31</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', p: 2, color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography>Adani Group</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', p: 2, color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography>Cement</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', p: 2, color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography>Mumbai</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Employee Wise Health Index
                </Box>
                <TableContainer component={Paper} sx={{ height: '200px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows3.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>

                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          }
          {selectedTab === 3 &&

            <Box>
              <Box sx={{ mb: 1, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="RoundGroup2"
                        value={state.RoundGroup2}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>Annual Health Checkup 2022-23</>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Business Wise Health Index
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Adani Group</Typography>
                        <Typography>84.05</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Adani Infrastructure Management</Typography>
                        <Typography>86.94</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Adani International School</Typography>
                        <Typography>79.17</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>AEL Support Service</Typography>
                        <Typography>85.98</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Agri</Typography>
                        <Typography>85.69</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Airports</Typography>
                        <Typography>88.29</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Aluminum</Typography>
                        <Typography>86.04</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>AMG Media</Typography>
                        <Typography>86.67</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>ANIL Central Service</Typography>
                        <Typography>84.31</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Adani Group</Typography>
                        <Typography>82.71</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Battery</Typography>
                        <Typography>72.5</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Blade Manufacturing</Typography>
                        <Typography>84.44</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Corporate Services</Typography>
                        <Typography>84.31</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>CSR</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Date Center</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Digital</Typography>
                        <Typography>75.83</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

            </Box>

          }
          {selectedTab === 4 &&

            <Box>
              <Box sx={{ mb: .5, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                       name="RoundGroup3"
                       value={state.RoundGroup3}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>Annual Health Checkup 2022-23</>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Business Wise Health Indicator
                </Box>
                <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>Corporate Services</Typography>
                    <PieChart
                      colors={palette}
                      series={[{ data: [{ value: 2 }, { value: 15 }, { value: 7 }, { value: 13 }, { value: 8 }, { value: 13 }] }]}
                      {...pieParams}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>Transmission</Typography>
                    <PieChart
                      colors={palette}
                      series={[{ data: [{ value: 2 }, { value: 15 }, { value: 5 }, { value: 13 }, { value: 8 }, { value: 15 }] }]}
                      {...pieParams}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>Ports & Logistics</Typography>
                    <PieChart
                      colors={palette}
                      series={[{ data: [{ value: 2 }, { value: 15 }, { value: 5 }, { value: 13 }, { value: 5 }, { value: 15 }] }]}
                      {...pieParams}
                    />
                  </Box>
                </Stack>
              </Box>

              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '135px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent>
                        <Typography sx={{ mt: 2 }}>Transmission</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Sub Business Wise Health Indicator
                </Box>
                <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>Transmission</Typography>
                    <Grid container spacing={1}>

                      <Grid item xs={12} md={2}>
                        <PieChart
                          colors={palette}
                          series={[{ data: [{ value: 2 }, { value: 15 }, { value: 5 }, { value: 13 }, { value: 8 }, { value: 15 }] }]}
                          {...pieParams}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Box>

            </Box>

          }

          {selectedTab === 5 &&

            <Box>
              <Box sx={{ mb: 1, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="RoundGroup4"
                        value={state.RoundGroup4}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>Annual Health Checkup 2022-23</>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1 }}>
                <Box component="h4" >
                  Diseases Wise Count
                </Box>
                <TableContainer component={Paper} sx={{ height: '250px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows4.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Diabetic</Typography>
                        <Typography>1588</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1 }}>
                <Box component="h4" >
                  Business Wise Diseases Count
                </Box>
                <TableContainer component={Paper} sx={{ height: '250px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows5.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Diabetic</Typography>
                        <Typography>1588</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Thermal</Typography>
                        <Typography>536</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1 }}>
                <Box component="h4" >
                  Sub Business Wise Diseases Count
                </Box>
                <TableContainer component={Paper} sx={{ height: '130px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows6.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Diabetic</Typography>
                        <Typography>1588</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Thermal</Typography>
                        <Typography>536</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '125px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Thermal Projects</Typography>
                        <Typography>61</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Box component='h4'>Location Wise Diseases Count</Box>
                  <FormControl sx={{ width: '20%' }}>
                    <Select
                      name="RoundGroup5"
                      value={state.RoundGroup5}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>Location Wise</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <TableContainer component={Paper} sx={{ height: '130px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows7.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                          <StyledTableCell>
                            <Button variant="outlined" sx={{ float: 'right' }}>{row.Button}</Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: 1, }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '130px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Diabetic</Typography>
                        <Typography>1588</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '130px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Thermal</Typography>
                        <Typography>536</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '130px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Themal Projects</Typography>
                        <Typography>61</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={1}></Grid>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ backgroundColor: 'lightblue', border: '2px solid #007fa9', height: '130px', color: '#007fa9', textAlign: 'center' }}>
                      <CardContent sx={{ mt: 2 }}>
                        <Typography>Ahmedabad</Typography>
                        <Typography>21</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box component={Paper} sx={{ p: 3, pt: 1, mb: .5, }}>
                <Box component="h4" >
                  Employee Wise Diseases Count
                </Box>
                <TableContainer component={Paper} sx={{ height: '200px' }}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {reportListRows8.map((row, index) => (
                        <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>
                          <StyledTableCell>{row.Page}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

            </Box>


          }
          {selectedTab === 6 &&
            <Box>
              <Box sx={{ mb: .5, p: 3 }} component={Paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', p: 1, mr: 1 }}>Round Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="RoundGroup6"
                        value={state.RoundGroup6}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem sx={{ ml: 3 }} value="">
                          <>Annual Health Checkup 2022-23</>
                        </MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2020-21</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={3}>Annual Health Checkup 2019-20</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={5}>Annual Health Checkup 2017-18</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={6}>Annual Health Checkup 2016-17</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={7}>Annual Health Checkup 2015-16</MenuItem>
                        <MenuItem sx={{ ml: 3 }} value={8}>Pre Employment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                      Employee's Current Business/Function wise Date
                    </Typography>
                    <Stack direction="row" sx={{ ml: 2, mb: 4 }} spacing={1} alignItems="center">
                      <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Box component={Paper}>
                <Box sx={{ backgroundColor: '#1976d2', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} >
                  <Typography>Total</Typography>
                  <Typography>Freezed</Typography>
                  <Typography>Examined</Typography>
                  <Typography>Pending</Typography>
                </Box>
                <Typography sx={{ color: 'green', textAlign: 'center' }}>Adipur</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      7
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      3
                    </Box>

                  </Grid>


                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      3
                    </Box>
                  </Grid>


                </Grid>
                <hr />
                <Typography sx={{ color: 'green', textAlign: 'center' }}>Ahmedabad</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      8741
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      3475
                    </Box>

                  </Grid>


                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      2719
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      756
                    </Box>
                  </Grid>


                </Grid>
                <hr />
                <Typography sx={{ color: 'green', textAlign: 'center' }}>Ahmedabad Brn</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>

                  </Grid>


                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>
                  </Grid>


                </Grid>
                <hr />
                <Typography sx={{ color: 'green', textAlign: 'center' }}>Ahmednagar</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      4
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      2
                    </Box>

                  </Grid>


                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      2
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Box
                      height={20}
                      width={100}
                      my={4}
                      display="flex"
                      alignItems="center"
                      gap={4}
                      p={2}
                      sx={{ border: '1px solid grey', justifyContent: 'center' }}
                    >
                      0
                    </Box>
                  </Grid>


                </Grid>

              </Box>

              <Box component={Paper} sx={{ mt: .5, mb: 1 }}>
                <Button variant='contained' sx={{ ml: 3, textTransform: 'none', mb: 2, mt: 2 }}>Back</Button>
                <Typography sx={{ ml: 3, mb: 2, fontWeight: 'bold' }}>Location:Ahmedabad</Typography>
                <Typography sx={{ ml: 3, fontWeight: 'bold' }}>Criteria:AbsolutePendingBasedonFreeze</Typography>
              </Box>
              <TableContainer component={Paper} sx={{ height: '200px' }}>
                <Table aria-label="customized table">
                  <TableBody>
                    {reportListRows9.map((row) => (
                      <StyledTableRow key={row.Page} sx={[{ borderRight: 'none !important', borderTop: '2px solid lightgray' }, styles.TableRow]}>

                        <StyledTableCell sx={{ borderRight: 'none !important' }}>{row.Page}</StyledTableCell>
                        <StyledTableCell sx={{ display: 'flex', justifyContent: 'left', borderLeft: 'none !important', borderBottom: 'none !important' }}>{row.Text}</StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          }
          {selectedTab === 7 && <Typography>Content for Tab 2</Typography>}
        </Box>
      </Box>
    </>
  );
}

export default App;
