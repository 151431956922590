import { ApiConfig } from "./../../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const getFirtAidDashboardData = async () => {
    try {
        const url = `${ApiConfig.getFirtAidDashboardData}`;
        const res = await api.get(url);
        return res?.data;
    } catch (error) {
        return error
    }
};
