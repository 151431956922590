import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Slide,
    CardContent,
    Button,
    styled,
    Toolbar,
    Typography,
    PropTypes,
    useScrollTrigger,
    FileUploadSharpIcon,
    FileCopyOutlinedIcon,
} = sharedImports;

const VisuallyHiddenInput = styled('input')({});

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    color: {
        color: 'red',
        border: '1px solid lightgray',
        padding: '10px',
        backgroundColor: 'lightgrey'
    },
}



export default function DenseAppBar(props) {

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Import First Aid
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1, ml: 2, mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 1 }}>
                    <Grid item xs={12} md={3}>
                        <Box variant="soft" sx={[styles.color, { paddingLeft: '30px', borderRadius: 2, ml: 2 }]}>
                            Notes: Text data to be Separated <br />
                            Example: Code Insert Data Like Alphabetical then Insert All Alphabetical Data <br />
                            Example: Code Insert Data Like Numeric then Insert all Numeric Data
                        </Box>
                    </Grid>
                    <CardContent>
                        <Box sx={{ fontWeight: 'bold' }}>Download Template</Box>

                    </CardContent>
                    <Grid item xs={12} md={3} sx={{ ml: 2 }}>
                        <VisuallyHiddenInput type="file" />
                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d', ml: 12 }} startIcon={<FileUploadSharpIcon />}>
                            Import
                        </Button>
                    </Grid>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export Department
                        </Button>

                    </Stack>

                </Card>
            </Box>


        </>
    )
}    