import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Tabs,
    Tab,
    Slide,
    AppBar,
    IconButton,
    Button,
    Toolbar,
    Tooltip,
    TableBody,
    TextField,
    MenuItem,
    DeleteOutlineIcon,
    Select,
    DatePicker,
    Typography,
    Link,
    AdapterDayjs,
    DemoItem,
    CssBaseline,
    FormControl,
    LocalizationProvider,
    useScrollTrigger,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SearchOutlinedIcon,
    FileUploadOutlinedIcon,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableHead,
    Table,
    TableCell,
    tableCellClasses,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { code: 30030132, name: 'Bhoomij Kansara' },
    { code: 30030132, name: 'Avani Khatri' },
    { code: 30030132, name: 'Avani Khatri' },
    { code: 30030132, name: 'Avani Khatri' },
    { code: 30030132, name: 'Avani Khatri' },
    { code: 30030132, name: 'Avani Khatri' },
];
const reportListRows2 = [
    { document: 'HealthConnectdoc_10122021040443615226PM.pdf', view: 'View', download: 'Download' },
];
const VisuallyHiddenInput = styled('input')({});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function HealthConnectUpdate(props) {

    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setAge(event.target.value);
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Health Connect Update
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {/* <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button> */}
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Connect No</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Connect No'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Connect Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Doctor</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Health Connect Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Faculty</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Faculty'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>No Of Employee <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='No Of Employee'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Duration (hrs) <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Faculty'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Employee Type</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Remark <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Employee" {...a11yProps(0)} />
                                    <Tab label="Other" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box>Employee Code</Box>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            size='small'
                                            placeholder='Employee Code'
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box>Employee Name</Box>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            size='small'
                                            placeholder='Employee Name'
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box>Browse</Box>
                                        <VisuallyHiddenInput type='file' />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box component='div'>
                                            <Button href='#text-button'>Health Connect Template</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center' sx={{ mb: 5 }}>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<SearchOutlinedIcon />}>Add</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<FileUploadOutlinedIcon />}>Import Details</Button>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Code</StyledTableCell>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Delete</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.code} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title="Delete">
                                                            <Link to='/support/health-connect/update'>
                                                                <IconButton>
                                                                    <DeleteOutlineIcon sx={{ color: 'red' }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                Item Two
                            </CustomTabPanel>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={4}>
                                    <Box>Upload Document</Box>
                                    <VisuallyHiddenInput type='file' />
                                    <Button variant='contained' sx={{ float: 'right' }}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Document</StyledTableCell>
                                            <StyledTableCell>View</StyledTableCell>
                                            <StyledTableCell>Download</StyledTableCell>
                                            <StyledTableCell>Delete</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows2.map((row) => (
                                            <StyledTableRow key={row.document} sx={styles.TableRow}>
                                                <StyledTableCell>{row.document}</StyledTableCell>
                                                <StyledTableCell sx={{color: '#00adff'}}>{row.view}</StyledTableCell>
                                                <StyledTableCell sx={{color: '#00adff'}}>{row.download}</StyledTableCell>
                                                <StyledTableCell>
                                                    {/* <Tooltip title="Delete">
                                                        <Link to='/support/health-connect/update'>
                                                            <IconButton>
                                                                <DeleteOutlineIcon sx={{ color: 'red' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip> */}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}