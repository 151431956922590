import EmployeeTransferInForm from './form';

const CreateEmployeeTransferIn = () => {
    return (
    <>
      <EmployeeTransferInForm />
    </>  
  )
}

export default CreateEmployeeTransferIn
