import sharedImports from '../../../component/Comman/sharedImports';
import { BarChart } from '@mui/x-charts/BarChart';

const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Slide,
    Select,
    Button,
    Toolbar,
    Table,
    styled,
    TableRow,
    TableHead,
    TableCell,
    DemoItem,
    PropTypes,
    DatePicker,
    MenuItem,
    Checkbox,
    TableBody,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    AdapterDayjs,
    useScrollTrigger,
    TableContainer,
    tableCellClasses,
    FormControlLabel,
    HighlightOffIcon,
    LocalizationProvider,
    FileCopyOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const palette = ['#ff7585', '#009bed', '#ffcb60f5', '#c0bfbf', '#dd675f', '#006341',];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { OPDList: 'Department', Count: 'Count' },
    { OPDList: 'INFORMATION TECHNOLOGY', Count: 2 },
    { OPDList: 'Energy Network Operation centre', Count: 2 },
    { OPDList: 'FINANCE & ACCOUNTS', Count: 1 },
];

const reportListRows1 = [
    { OPDList: 'Visit Purpose', Count: 'Count' },
    { OPDList: 'Sickness', Count: 11 },
    { OPDList: 'Total', Count: 11 },
];

const styles = {
    TableRow: {
        th: {
            p: 1.5,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '2px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}






export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        State: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    OPD Dashboard
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={.5}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>State</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="State"
                                            value={state.State}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Requested Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Other Filter"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Graph Visibility"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>


                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#3b6a99' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export Summary
                        </Button>

                    </Stack>


                </Card>

            </Box>




            <Card variant="outlined" sx={{ width: '48%', ml: 2, height: '450px' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9}>
                            <BarChart
                                colors={palette}
                                series={[
                                    { data: [60, 20, 20, 20, 20,] },
                                    { data: [60, 20, 20, null, 20] },
                                ]}
                                height={290}
                                xAxis={[{ data: ['INFORMATION TECHNOLOGY', 'FINANCE & ACCOUNTS', 'CEOs office', 'PROJECTS', 'TECHNO COMMERCIAL'], scaleType: 'band', barGapRatio: 0.3, categoryGapRatio: 0.3 }]}
                                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                            />

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>

            <Card variant="outlined" sx={{ width: '48%', float: 'right', mt: -56, height: '450px' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9}>
                            <BarChart
                                colors={palette}
                                series={[
                                    { data: [10] },

                                ]}
                                height={290}
                                xAxis={[{ data: ['Sickness'], scaleType: 'band', barGapRatio: 0.3, categoryGapRatio: 0.3 }]}
                                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                            />

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>

            <TableContainer item xs={12} md={3} sx={{ width: '48%', ml: 2, mt: 2, height: '199px' }} >
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow item xs={12} md={3} sx={styles.TableRow}>
                            <StyledTableCell sx={{ fontWeight: 'bold' }}>OPD List Based On Department</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportListRows.map((row) => (
                            <StyledTableRow item xs={12} md={3} key={row.OPDList} sx={styles.TableRow}>
                                <StyledTableCell sx={{ fontWeight: ' bold' }}> {row.OPDList} </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: ' bold' }}>{row.Count}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer item xs={12} md={3} sx={{ width: '48%', float: 'right', mt: -25, height: '200px' }} >
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow item xs={12} md={3} sx={styles.TableRow}>
                            <StyledTableCell sx={{ fontWeight: 'bold' }}>OPD List Based On Visit Purpose</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportListRows1.map((row) => (
                            <StyledTableRow item xs={12} md={3} key={row.OPDList} sx={styles.TableRow}>
                                <StyledTableCell sx={{ fontWeight: ' bold' }}> {row.OPDList} </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: ' bold' }}>{row.Count}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Card variant="outlined" sx={{ width: '48%', ml: 2, mt: 2, height: '450px' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={9}>
                            <BarChart
                                colors={palette}
                                series={[
                                    { data: [7] },

                                ]}
                                height={290}
                                xAxis={[{ data: ['Sickness'], scaleType: 'band', barGapRatio: 0.3, categoryGapRatio: 0.8 }]}
                                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                            />

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>

            <Card variant="outlined" sx={{ width: '48%', float: 'right', mt: -56, height: '450px' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9}>
                            <BarChart
                                colors={palette}
                                series={[
                                    { data: [70] },

                                ]}
                                height={290}
                                xAxis={[{ data: ['Sickness'], scaleType: 'band', barGapRatio: 0.3, categoryGapRatio: 0.6 }]}
                                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                            />

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>





        </>
    );
}
