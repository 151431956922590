const styles = {
    TableRow: {
      th: {
          width: '12.5%',
          svg: {
              float: 'right',
              color: '#1976d2'
          },
      },
      td: {
          border: '1px solid lightgray',
      }
    },
 
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    box: {
        fontWeight: 'bold', display: 'flex', alignItems: 'center', fontSize: '14px'
    },
    
    // Employee master css
    EmployeeMasterSetupTableRow: {
        th: {
            border: '1px solid #a3a3a3',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },

    // User Role CSS
    firstForm: {
        padding: '16px 16px 34px 16px',
        backgroundColor: '#f1f1f1'
    },
    wFull: {
        width: '100%'
    },
    marginBottom2: {
        marginBottom: 2,
        mt: 2
    },
    p20: {
        padding: '20px'
    },
    bRadius: {
        borderRadius: '5px',
        marginTop: '45px'
    },
    
    // Permission section css
    permissionTabsContainer: {
        margin: '16px',
        marginTop: '64px',
        position: 'sticky',
        top: '0',
        zIndex: 1000,
    },
    permissionTabs: {   
        backgroundColor: '#f5f5f5',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    permissionTab: {
        marginLeft: '20px',
        textTransform: 'none',
        color: '#007bff',
    },
    permissionTabPanel: {
        padding: '24px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    },
    permissionCheckboxLabel: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
    },
    permissionParentCheckboxLabelH4: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    permissionCheckboxLabelH4: {
        margin: 0,
    },
    permissionCheckboxLabelInput: {
        marginRight: '8px',
        padding: '5px',
    },
    permissionSubmenu: {
        paddingLeft: '20px',
    },
    dFlex: {
        display: 'flex', alignItems: 'self-end', justifyContent: 'space-between', backgroundColor: 'lightgray'
    },
    deleteBtn: {
        backgroundColor: 'red', color: 'white'
    }

}

export default styles;