import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeJobTitle = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeJobTitle = async (props) => {
  const url = `${ApiConfig.employeeJobTitleMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_job_title_id) => {
  const url = `${ApiConfig.employeeJobTitleMain}?id=${employee_job_title_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeJobTitle = async (props) => {
  const url = `${ApiConfig.employeeJobTitleMain}?id=${props.employee_job_title_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeJobTitle = async (employee_job_title_id) => {
    const url = `${ApiConfig.employeeJobTitleMain}?id=${employee_job_title_id}`;
    const response = await api.delete(url);
    return response.data;
};

