import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Tabs,
    Tab,
    Slide,
    Link,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    styled,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    LocalizationProvider,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
    SearchOutlinedIcon,
    DemoItem,
    DatePicker,
    AdapterDayjs,
} = sharedImports;


const drawerWidth = 300;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
    { select: 'Select', date: '31/03/2023', round: 'Annual Health Checkup 2020-23', employeeCode: 30005230, employeeName: 'Sudesh Jain', department: 'ABEX', status: 'Completed' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ExaminationAll(props) {
    const [state, setState] = React.useState({
        ExaminationType: '',
        Round: '',
        Designation: '',
        Department: '',
        Company: '',
        Status: '',
        EmployeeTypeGroup: '',
        ExaminationMainType: '',
        RoundGroup: '',
        DesignationType: '',
        DepartmentGroup: '',
        CompanyGroup: '',
        CompanyStatus: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);
    function handleChanges(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Examination All
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChanges} aria-label="basic tabs example">
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Examination All" {...a11yProps(0)} />
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Examination Custom Search" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Examination Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="ExaminationType"
                                                    value={state.ExaminationType}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Round"
                                                    value={state.Round}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Designation</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Designation"
                                                    value={state.Designation}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Department"
                                                    value={state.Department}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Company"
                                                    value={state.Company}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="Status"
                                                    value={state.Status}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Code"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="First Name"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Date of Joining</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Type Group</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="EmployeeTypeGroup"
                                                    value={state.EmployeeTypeGroup}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                    <Grid item>
                                        <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Examination Main Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="ExaminationMainType"
                                                    value={state.ExaminationMainType}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Round Group<Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="RoundGroup"
                                                    value={state.RoundGroup}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Designation Type</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="DesignationType"
                                                    value={state.DesignationType}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Department Group </Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="DepartmentGroup"
                                                    value={state.DepartmentGroup}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Company Group</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="CompanyGroup"
                                                    value={state.CompanyGroup}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="CompanyStatus"
                                                    value={state.CompanyStatus}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Code"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="First Name"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                    <Grid item>
                                        <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Round<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Status<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.date} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ color: '#0178ff' }}>
                                                    <Link to='/examination/examination-all/examination-vital'>
                                                        Select
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell>{row.date}</StyledTableCell>
                                                <StyledTableCell>{row.round}</StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                                <StyledTableCell>{row.status}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}