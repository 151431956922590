import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Tabs,
    Tab,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    TextsmsIcon,
    MenuItem,
    Typography,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    CancelOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    SaveOutlinedIcon,
    DatePicker,
    TableContainer,
    VisibilityOutlinedIcon,
    AdapterDayjs,
    Paper,
    Table,
    TableHead,
    TableRow,
    styled,
    DeleteOutlineIcon,
    TableCell,
    Checkbox,
    tableCellClasses,
    TableBody,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { assetName: null, one: null, two: null, three: null, four: null, five: null, six: null, seven: null, eightnine: null, ten: null, eleven: null, twelve: null, thirteen: null, fourteen: null, fiveteen: null },
];
const reportListRows2 = [
    { parameterName: 'Radiator' },
    { parameterName: 'Tyre Pressure' },
    { parameterName: 'Accelerator' },
    { parameterName: 'Gear System Back Gear' },
    { parameterName: 'Cabin Light' },
    { parameterName: 'Break Light' },
    { parameterName: 'Wheel Chair' },
    { parameterName: 'Clutch Condition' },
    { parameterName: 'Locker Light' },
    { parameterName: 'Siren' },
    { parameterName: 'Hand Break' },
    { parameterName: 'Light Condi.' },
    { parameterName: 'Battery Condi.' },
    { parameterName: 'A. C. Condition' },
    { parameterName: 'Hose Pipe' },
    { parameterName: 'Head Light' },
    { parameterName: 'Suspension' },
    { parameterName: 'Parking Light' },
    { parameterName: 'Steering' },
    { parameterName: 'wiper' },
    { parameterName: 'Hydraulic Oil' },
    { parameterName: 'Engine Oil' },
    { parameterName: 'P. A. System' },
    { parameterName: 'Strachere' },
    { parameterName: 'Side light' },
    { parameterName: 'Break Coundition' },
];
const reportListRows3 = [
    { itemName: null, one: null, two: null, three: null, four: null, five: null, six: null, seven: null, eightnine: null, ten: null, eleven: null, twelve: null, thirteen: null, fourteen: null, fiveteen: null },
];
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #3a3a3a',
            svg: {
                float: 'right',
                color: 'gray'
            }
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AmbulanceCheck(props) {
    const [state, setState] = React.useState({
        Ambulance: '',
        CheckYear: '',
        CheckMonth: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);

    function handleChanges(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Ambulance Check
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}> Save </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}>Clear</Button>
                                        <Button variant='contained' startIcon={<VisibilityOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}>View Report</Button>
                                        <Button variant='contained' startIcon={<VisibilityOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}>View Template</Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Ambulance <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Ambulance"
                                                value={state.Ambulance}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Ambulance-1</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Ambulance-2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Check Year <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="CheckYear"
                                                value={state.CheckYear}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Ambulance-1</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Ambulance-2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Check Month <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="CheckMonth"
                                                value={state.CheckMonth}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Ambulance-1</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Ambulance-2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChanges} aria-label="basic tabs example">
                                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Asset List" {...a11yProps(0)} />
                                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Ambulance Vehicle Parameter List" {...a11yProps(1)} />
                                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Medicine List" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        <Box component='div' sx={{ float: 'right', mb: 1 }}>
                                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<DeleteOutlineIcon />}>Delete</Button>
                                        </Box>
                                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>Asset Name</StyledTableCell>
                                                        <StyledTableCell>1<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>2<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>3<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>4<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>5<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>6<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>7<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>8<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>9<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>10<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>11<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>12<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>13<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>14<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>15<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows.map((row) => (
                                                        <StyledTableRow key={row.assetName} sx={styles.TableRow}>
                                                            <StyledTableCell> {row.assetName} </StyledTableCell>
                                                            <StyledTableCell>{row.one}</StyledTableCell>
                                                            <StyledTableCell>{row.two}</StyledTableCell>
                                                            <StyledTableCell>{row.three}</StyledTableCell>
                                                            <StyledTableCell>{row.four}</StyledTableCell>
                                                            <StyledTableCell>{row.five}</StyledTableCell>
                                                            <StyledTableCell>{row.six}</StyledTableCell>
                                                            <StyledTableCell>{row.seven}</StyledTableCell>
                                                            <StyledTableCell>{row.eight}</StyledTableCell>
                                                            <StyledTableCell>{row.nine}</StyledTableCell>
                                                            <StyledTableCell>{row.ten}</StyledTableCell>
                                                            <StyledTableCell>{row.eleven}</StyledTableCell>
                                                            <StyledTableCell>{row.twelve}</StyledTableCell>
                                                            <StyledTableCell>{row.thirteen}</StyledTableCell>
                                                            <StyledTableCell>{row.fourteen}</StyledTableCell>
                                                            <StyledTableCell>{row.fiveteen}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <Box component='div' sx={{ float: 'right', mb: 1 }}>
                                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<DeleteOutlineIcon />}>Delete</Button>
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>Parameter Name</StyledTableCell>
                                                        <StyledTableCell>1<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>2<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>3<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>4<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>5<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>6<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>7<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>8<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>9<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>10<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>11<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>12<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>13<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>14<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>15<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows2.map((row) => (
                                                        <StyledTableRow key={row.itemName} sx={styles.TableRow}>
                                                            <StyledTableCell> {row.parameterName}</StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                            <StyledTableCell><Checkbox sx={{ p: 0 }} /><TextsmsIcon color='primary' sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <Box component='div' sx={{ float: 'right', mb: 1 }}>
                                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<DeleteOutlineIcon />}>Delete</Button>
                                        </Box>
                                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>Item Name</StyledTableCell>
                                                        <StyledTableCell>1<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>2<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>3<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>4<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>5<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>6<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>7<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>8<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>9<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>10<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>11<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>12<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>13<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>14<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                        <StyledTableCell>15<Checkbox sx={{ p: 0, float: 'right' }} /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows3.map((row) => (
                                                        <StyledTableRow key={row.itemName} sx={styles.TableRow}>
                                                            <StyledTableCell> {row.itemName} </StyledTableCell>
                                                            <StyledTableCell>{row.one}</StyledTableCell>
                                                            <StyledTableCell>{row.two}</StyledTableCell>
                                                            <StyledTableCell>{row.three}</StyledTableCell>
                                                            <StyledTableCell>{row.four}</StyledTableCell>
                                                            <StyledTableCell>{row.five}</StyledTableCell>
                                                            <StyledTableCell>{row.six}</StyledTableCell>
                                                            <StyledTableCell>{row.seven}</StyledTableCell>
                                                            <StyledTableCell>{row.eight}</StyledTableCell>
                                                            <StyledTableCell>{row.nine}</StyledTableCell>
                                                            <StyledTableCell>{row.ten}</StyledTableCell>
                                                            <StyledTableCell>{row.eleven}</StyledTableCell>
                                                            <StyledTableCell>{row.twelve}</StyledTableCell>
                                                            <StyledTableCell>{row.thirteen}</StyledTableCell>
                                                            <StyledTableCell>{row.fourteen}</StyledTableCell>
                                                            <StyledTableCell>{row.fiveteen}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CustomTabPanel>

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}