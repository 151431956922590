import AssetMaintenanceForm from '../assetMaintenance/from';

const AssetMaintenanceCreate = () => {
    return (
    <>
      <AssetMaintenanceForm />
    </>  
  )
}

export default AssetMaintenanceCreate