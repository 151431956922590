import * as React from "react";
import sharedImports from "../../../Comman/sharedImports";

import { CommanAppBar } from "../../../Comman/commanAppBar";
import { SendGetRequest } from "../../../../api/config/ApiCall";
import {
  styles,
  getDropDownlist,
  getEmployeeField,
  getEmployeeType,
  initialData,
  generateExcel,
  generatePDF,
} from "./helper";

import { useSnackbar } from "notistack";
const {
  Box,
  Grid,
  Select,
  Stack,
  Button,
  MenuItem,
  TextField,
  FormControl,
  DatePicker,
  DemoItem,
  AdapterDayjs,
  HighlightOffIcon,
  LocalizationProvider,
  RemoveRedEyeOutlinedIcon,
  Autocomplete,
} = sharedImports;

export default function AllEmployeeList() {
  // const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [storedValues, setStoredValues] = React.useState({
    reportDropDownData: [],
    filterDropDownData: [],
    employeeType: [],
    employeeSubType: [],
    genderData: [],
    employeeTypeGroup: [],
    employeeDepartment: [],
    employeeGroup: [],
    employeeCompany: [],
    employeeGrade: [],
    employeeJobTitle: [],
    employeeWorkLocation: [],
    employeeDesignation: [],
  });
  const [isReset, setIsReset] = React.useState(false);
  const [state, setState] = React.useState(initialData);

  React.useEffect(() => {
    setState(initialData);
    getDropDownlist("main", setStoredValues, "reportDropDownData");
    getDropDownlist("gender", setStoredValues, "genderData");
    getEmployeeField(setStoredValues, [
      "employeeTypeGroup",
      "employeeDepartment",
      "employeeGroup",
      "employeeGrade",
      "employeeCompany",
      "employeeJobTitle",
      "employeeWorkLocation",
      "employeeDesignation"
    ]);
  }, [isReset]);

  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const viewReport = async () => {
    try {
      if (state.viewMode === "") {
        enqueueSnackbar("Please select View Mode", { variant: "error" });
        return;
      }
      const parmas = {
        gender: state.gender,
        employee_type_group: state.employeeTypeGroup,
        employee_group: state.employeeGroup,
        employee_type: state.employeeType,
        employee_sub_type: state.employeeSubType,
        grade: state.employeeGrade,
        designation: state.designation,
        company: state.employeeCompany,
        report_type: state.report,
        filterValue:
          state.report === "blood-group"
            ? encodeURIComponent(state.filter)
            : state.filter,
        job_title: state.employeeJobTitle,
        work_location: state.employeeWorkLocation,
      };
      const response = await SendGetRequest("getReportList", parmas);
      if (response.status) {
        if (state.viewMode === "pdf") {
          generatePDF(response.data);
        } else if (state.viewMode === "excel") {
          generateExcel(response.data);
        } else {
          enqueueSnackbar("Please select a valid View Mode", {
            variant: "warning",
          });
        }
      }
    } catch (error) {
      console.error("Error fetching report list:", error);
    }
  };

  const isClearDisabled = React.useMemo(
    () => Object.values(state).every((v) => v === ""),
    [state]
  );
  return (
    <Box sx={styles.root}>
      <CommanAppBar appBarTitle="All Employee List" />

      <Box sx={styles.firstForm}>
        <Box
          sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]}
          className="MainPadding"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Report</Box>
              <Grid item xs={12} md={11}>
                <Autocomplete
                  options={storedValues?.reportDropDownData}
                  size="small"
                  getOptionLabel={(option) => option.name ?? null}
                  value={
                    storedValues.reportDropDownData.find(
                      (option) => option.id === state?.report
                    ) ?? null
                  }
                  onChange={(event, newValue) => {
                    const value = newValue ? newValue?.id : "";
                    handleChange("report", value);
                    handleChange("filter", "");
                    getDropDownlist(
                      value,
                      setStoredValues,
                      "filterDropDownData"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Report"
                      variant="outlined"
                      placeholder="-select-"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id}>
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>View Mode</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="viewMode"
                    size="small"
                    value={state.viewMode}
                    onChange={(event) =>
                      handleChange("viewMode", event.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      -Select-
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={"pdf"}>
                      PDF
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={"excel"}>
                      Excel
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Filter</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.filterDropDownData}
                    value={
                      storedValues.filterDropDownData.find(
                        (option) => option?.id === state.filter
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange("filter", newValue?.id);
                    }}
                    getOptionLabel={(option) => option?.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Employee Type Group</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    // disableClearable
                    options={storedValues.employeeTypeGroup}
                    value={
                      storedValues.employeeTypeGroup.find(
                        (option) =>
                          option?.employee_type_group_id ===
                          state?.employeeTypeGroup
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeTypeGroup",
                        newValue?.employee_type_group_id
                      );
                      getEmployeeType(
                        setStoredValues,
                        "employeeType",
                        {
                          id: newValue?.employee_type_group_id,
                        },
                        "getEmployeeType"
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.employee_type_group_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Employee Type</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    // disableClearable
                    options={storedValues.employeeType}
                    value={
                      storedValues.employeeType.find(
                        (option) =>
                          option?.employee_type_id === state.employeeType
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange("employeeType", newValue?.employee_type_id);
                      getEmployeeType(
                        setStoredValues,
                        "employeeSubType",
                        {
                          id: newValue?.employee_type_id,
                          emg_id: state?.employeeTypeGroup,
                        },
                        "getSubEmployeeType"
                      );
                    }}
                    getOptionLabel={(option) => option?.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_type_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Employee Sub Type</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeSubType}
                    value={
                      storedValues.employeeSubType.find(
                        (option) =>
                          option?.employee_sub_type_id ===
                          state?.employeeSubType
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeSubType",
                        newValue?.employee_sub_type_id
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_sub_type_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Location</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={storedValues?.employeeWorkLocation}
                    size="small"
                    getOptionLabel={(option) => option.name ?? null}
                    value={
                      storedValues.employeeWorkLocation.find(
                        (option) =>
                          option.employee_work_location_id ===
                          state?.employeeWorkLocation
                      ) ?? null
                    }designation
                    onChange={(event, newValue) => {
                      const value = newValue
                        ? newValue?.employee_work_location_id
                        : "";
                      handleChange("employeeWorkLocation", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Report"
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_work_location_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Department Group</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="DepartmentGroup"
                    value={state.departmentGroup}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-All -</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Department</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeDepartment}
                    value={
                      storedValues.employeeDepartment.find(
                        (option) =>
                          option?.employee_department_id ===
                          state?.employeeDepartment
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeDepartment",
                        newValue?.employee_department_id
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_department_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Grade</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeGrade}
                    value={
                      storedValues.employeeGrade.find(
                        (option) =>
                          option?.employee_grade_id === state?.employeeGrade
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeGrade",
                        newValue?.employee_grade_id
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_grade_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Designation</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeDesignation}
                    value={
                      storedValues.employeeDesignation.find(
                        (option) =>
                          option?.employee_designation_id === state?.designation
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "designation",
                        newValue?.employee_designation_id
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_designation_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Job Title</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeJobTitle}
                    value={
                      storedValues.employeeJobTitle.find(
                        (option) =>
                          option?.employee_job_title_id ===
                          state.employeeJobTitle
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeJobTitle",
                        newValue?.employee_job_title_id
                      );
                    }}
                    getOptionLabel={(option) => option?.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_job_title_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Company</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeCompany}
                    value={
                      storedValues.employeeCompany.find(
                        (option) =>
                          option?.employee_company_id === state?.employeeCompany
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeCompany",
                        newValue?.employee_company_id
                      );
                    }}
                    getOptionLabel={(option) => option?.name ?? ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_company_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Employee Group</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.employeeGroup}
                    value={
                      storedValues?.employeeGroup.find(
                        (option) =>
                          option?.employee_group_id === state?.employeeGroup
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "employeeGroup",
                        newValue?.employee_group_id
                      );
                    }}
                    getOptionLabel={(option) => option?.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.employee_group_id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Contractor</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Contractor"
                    value={state.contractor}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-All-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Employee Code</Box>
              <Grid item xs={12} md={11}>
                <TextField
                  required
                  id="filled-search"
                  disabled
                  fullWidth
                  size="small"
                  placeholder="Employee Code"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Age From</Box>
              <Grid item xs={12} md={11}>
                <TextField
                  required
                  id="filled-search"
                  disabled
                  fullWidth
                  size="small"
                  placeholder="Age From"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Age To</Box>
              <Grid item xs={12} md={11}>
                <TextField
                  required
                  id="filled-search"
                  disabled
                  fullWidth
                  size="small"
                  placeholder="Age To"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Gender</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    options={storedValues?.genderData}
                    value={
                      storedValues?.genderData.find(
                        (option) => option?.id === state?.gender
                      ) ?? null
                    }
                    onChange={(event, newValue) => {
                      handleChange("gender", newValue?.id);
                    }}
                    getOptionLabel={(option) => option?.name ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="-select-"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>
                        {option.name}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Status</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Status"
                    value={state.status}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-All-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Function</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Function"
                    disabled
                    value={state.filter}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-All-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>From Date</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DatePicker disabled />
                    </DemoItem>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>To Date</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DatePicker disabled />
                    </DemoItem>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                sx={styles.marginBottom2}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={viewReport}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                >
                  View Report
                </Button>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  disabled={isClearDisabled}
                  onClick={() => setIsReset(!isReset)}
                  startIcon={<HighlightOffIcon />}
                >
                  Clear
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
