import { ApiConfig } from "./config/ApiConfig";
import { api } from "api/config/axiosInterceptor";
import { buildQueryString } from "helper/index";

export const GetLanguage = async (parmas) => {  
  const query = buildQueryString(parmas);  
  const url = `${ApiConfig.getLanguage}?${query}`;
  const response = await api.get(url);
  return response.data;
}

export const InsertLanguage = async (props) => { 

  console.log(props);
  const url = `${ApiConfig.insertLanguage}`;
  const response = await api.post(url, props);
  return response.data;
};