import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Grid,
    Card,
    Slide,
    Stack,
    Button,
    CardContent,
    Select,
    Toolbar,
    Checkbox,
    MenuItem,
    DemoItem,
    TextField,
    DatePicker,
    Typography,
    FormControl,
    SearchIcon,
    PropTypes,
    AdapterDayjs,
    HighlightOffIcon,
    FormControlLabel,
    useScrollTrigger,
    VisibilityOutlinedIcon,
    LocalizationProvider,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 2,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        Report: '',
        ViewMode: '',
        Visitpurpose: '',
        ParticipantType: '',
        OtherType: '',
        OtherSubGroup: '',
        EmployeeTypeGroup: '',
        EmployeeType: '',
        EmployeeSubType: '',
        Function: '',
        DepartmentGroup: '',
        Department: '',
        Grade: '',
        Designation: '',
        JobTitle: '',
        Company: '',
        EmployeeGroup: '',
        Contractor: '',
        Gender: '',
        FurtherAction: '',
        ReportType: '',
        ViewModeType: '',
        VisitPurposeMode: '',
        ParticipantTypeGroup: '',
        EmployeeTypeMainGroup: '',
        EmployeeTypeSubGroup: '',
        EmployeeSubTypeGroup: '',
        FunctionType: '',
        DepartmentSubGroup: '',
        DepartmentType: '',
        GradeSubType: '',
        DesignationTypeGroup: '',
        JobTitleSubGroup: '',
        CompanySubTypeGroup: '',
        EmployeeSubTypeMainGroup: '',
        ContractorSubTypeGroup: '',
        GenderSubType: '',
        FurtherActionType: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Case Report
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        ml: 2,
                        mt: 6,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Report <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Report"
                                            value={state.Report}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>View Mode</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ViewMode"
                                            value={state.ViewMode}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>PDF</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Visit purpose</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Visitpurpose"
                                            value={state.Visitpurpose}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Participant Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ParticipantType"
                                            value={state.ParticipantType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Other Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="OtherType"
                                            value={state.OtherType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <></>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Other Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="OtherSubGroup"
                                            value={state.OtherSubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>


                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type Group </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeTypeGroup"
                                            value={state.EmployeeTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeType"
                                            value={state.EmployeeType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Sub Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeSubType"
                                            value={state.EmployeeSubType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Function</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Function"
                                            value={state.Function}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="DepartmentGroup"
                                            value={state.DepartmentGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Department"
                                            value={state.Department}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Grade</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Grade"
                                            value={state.Grade}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Designation</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Designation"
                                            value={state.Designation}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Job Title</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="JobTitle"
                                            value={state.JobTitle}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Company</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Company"
                                            value={state.Company}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeGroup"
                                            value={state.EmployeeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Contractor</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Contractor"
                                            value={state.Contractor}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            < Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Employee Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Age From </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='AgeFrom'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Age To </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='AgeTo'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Gender</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Gender"
                                            value={state.Gender}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Further Action</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="FurtherAction"
                                            value={state.FurtherAction}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="With Detail"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="With Drug Cost"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Report Type<Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ReportType"
                                            value={state.ReportType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Accident Chart</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Accident List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>Accident Summary</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Case Cost</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={5}>Case Detail List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={6}>Case List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={7}>Case List With medicine</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={8}>Contractor Wise Case Detail</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={9}>ContractorWise Case Cost List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>ContractorWise Case Cost Summary</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={11}>ContractorWise Case List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={12}>ContractorWise Case Summary</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={13}>Disease System Chart</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={14}>DiseaseWise Case List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={15}>DiseaseWise Case Summary</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={16}>DiseaseWise Case List</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={17}>Employee Case Cost</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={18}>Employee Total Case Cost</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={19}>Employee Wise Test Package Cost</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>View Mode Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ViewModeType"
                                            value={state.ViewModeType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>PDF</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Visit Purpose Mode </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="VisitPurposeMode"
                                            value={state.VisitPurposeMode}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Participant Type Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ParticipantTypeGroup"
                                            value={state.ParticipantTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type Main Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeTypeMainGroup"
                                            value={state.EmployeeTypeMainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeTypeSubGroup"
                                            value={state.EmployeeTypeSubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Sub Type Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeSubTypeGroup"
                                            value={state.EmployeeSubTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Function Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="FunctionType"
                                            value={state.FunctionType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="DepartmentSubGroup"
                                            value={state.DepartmentSubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="DepartmentType"
                                            value={state.DepartmentType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Grade Sub Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="GradeSubType"
                                            value={state.GradeSubType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Designation Type Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="DesignationTypeGroup"
                                            value={state.DesignationTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Job Title Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="JobTitleSubGroup"
                                            value={state.JobTitleSubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Company Sub Type Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="CompanySubTypeGroup"
                                            value={state.CompanySubTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Sub Type Main Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeSubTypeMainGroup"
                                            value={state.EmployeeSubTypeMainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Contractor Sub Type Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ContractorSubTypeGroup"
                                            value={state.ContractorSubTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            < Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Employee Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Age From </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='AgeFrom'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Age To </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='AgeTo'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Gender Sub Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="GenderSubType"
                                            value={state.GenderSubType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Further Action Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="FurtherActionType"
                                            value={state.FurtherActionType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>
                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<VisibilityOutlinedIcon />}>
                            view Report
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>
                </Card>
            </Box>
        </>
    );
}
