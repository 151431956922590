import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetAssetMaintenance = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertAssetMaintenance = async (props) => {  
  const url = ApiConfig.assetMaintenance;
  const params = {
    ...props,
    document_date: props.document_date ? props.document_date.format("YYYY-MM-DD") : null,
    maintenance_date: props.maintenance_date ? props.maintenance_date.format("YYYY-MM-DD") : null,
    next_maintenance_date: props.next_maintenance_date ? props.next_maintenance_date.format("YYYY-MM-DD") : null,
  };

  const response = await api.post(url, params);
  return response.data;
};

export const SingleList = async (id) => {
  const url = `${ApiConfig.assetMaintenance}?id=${id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateAssetMaintenance = async (props) => {
  const url = `${ApiConfig.assetMaintenance}?id=${props.id}`;
  
  const { id, created_at, updated_at, ...restProps } = props;
  // Format date fields if they exist
  const params = {
    ...restProps,
    document_date: restProps.document_date ? restProps.document_date.format("YYYY-MM-DD") : null,
    maintenance_date: restProps.maintenance_date ? restProps.maintenance_date.format("YYYY-MM-DD") : null,
    next_maintenance_date: restProps.next_maintenance_date ? restProps.next_maintenance_date.format("YYYY-MM-DD") : null,
  };

  const response = await api.put(url, params);
  return response.data;
};

export const DeleteAssetMaintenance = async (id) => {
  const url = `${ApiConfig.assetMaintenance}?id=${id}`;
  const response = await api.delete(url);
  return response.data;
};
