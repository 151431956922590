import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeQualification = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeQualification = async (props) => {
  const url = `${ApiConfig.employeeQualificationMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_qualification_id) => {
  const url = `${ApiConfig.employeeQualificationMain}?id=${employee_qualification_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeQualification = async (props) => {
  const url = `${ApiConfig.employeeQualificationMain}?id=${props.employee_qualification_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeQualification = async (employee_qualification_id) => {
    const url = `${ApiConfig.employeeQualificationMain}?id=${employee_qualification_id}`;
    const response = await api.delete(url);
    return response.data;
};
