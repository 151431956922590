import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Grid,
    Card,
    Slide,
    Table,
    styled,
    Button,
    SaveIcon,
    Toolbar,
    TableRow,
    TableHead,
    TableCell,
    Typography,
    CardContent,
    TableContainer,
    useScrollTrigger,
    tableCellClasses,
    Checkbox,
    PropTypes,
    TableBody,

} = sharedImports;



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SrNo: '10', SubGroup: 'Consumable', MainGroup: '', },
    { SrNo: '37', SubGroup: 'Injection', },
    { SrNo: '38', SubGroup: 'Syrup', },
    { SrNo: '39', SubGroup: 'sachet', },
    { SrNo: '40', SubGroup: 'Surgical', },
    { SrNo: '41', SubGroup: 'cream', },
    { SrNo: '42', SubGroup: 'spray', },
    { SrNo: '43', SubGroup: 'drops', },
    { SrNo: '44', SubGroup: 'powder', },
    { SrNo: '45', SubGroup: 'liquid/surgical', },
    { SrNo: '46', SubGroup: 'powder/sachet', },
    { SrNo: '47', SubGroup: 'Tablet', },
    { SrNo: '48', SubGroup: 'capsule', }
];

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}





export default function DenseAppBar(props) {
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Sub Group For In Whole unit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d', m: 1 }} startIcon={<SaveIcon />}>
                                    Save
                                </Button>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5, mt: 7,
                        width: "100%",
                    },
                }}
            >

                <Card variant="outlined" sx={{ padding: 2, }}>
                    <Typography variant="h6" color="black" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
                        Maintenance
                    </Typography>

                    <CardContent sx={{ pt: '5px !important' }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell>
                                            <Checkbox sx={{ p: 0 }} />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Sr No</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Sub Group</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Main Group</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportListRows.map((row) => (
                                        <StyledTableRow key={row.SrNo} sx={styles.TableRow}>
                                            <StyledTableCell>
                                                <Checkbox sx={{ p: 0 }} />
                                            </StyledTableCell>
                                            <StyledTableCell> {row.SrNo} </StyledTableCell>
                                            <StyledTableCell>{row.SubGroup}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box >

        </>
    )
}   
