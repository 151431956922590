import React, { useState, useEffect } from 'react';
import LabForm from './form';
import { useParams } from 'react-router-dom';
import { SendGetRequest } from '../../../../api/config/ApiCall';

const LabUpdate = () => {
  const { id } = useParams();  
  
  const [labData, setLabData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SendGetRequest("singleLabsUrl", {id});
      setLabData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (labData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <LabForm labId={id} labData={labData} />
    </>
  );
};

export default LabUpdate;
