import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Link,
    Dialog,
    Toolbar,
    MenuItem,
    Typography,
    InputLabel,
    DeleteIcon,
    IconButton,
    Tooltip,
    DialogTitle,
    TextField,
    DialogContentText,
    SaveOutlinedIcon,
    CssBaseline,
    FormControl,
    DialogContent,
    DialogActions,
    AddCircleOutlineOutlinedIcon,
    useScrollTrigger,
    styled,
    TableBody,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            width: '16.666666%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetIncharge(props) {
    const [state, setState] = React.useState({
        All: '',
        Select: '',
        Selects: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Setup
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Link to='/support/asset-management/asset-calibration-log/create'>
                                            <Button variant='contained' sx={{ textTransform: 'none', m: 1 }} startIcon={<SaveOutlinedIcon />}> Save </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" size='small'>-All-</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="-All-"
                                            name="All"
                                            value={state.All}
                                            onChange={handleChange}
                                            size='small'
                                        >
                                            <MenuItem sx={{ ml: 3 }}><em>-All-</em></MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>Oxygen concentrator</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddCircleOutlineOutlinedIcon />}
                                            onClick={handleClickOpen}
                                            item xs={12} md={1} sx={{ textTransform: 'none' }}>
                                            Add
                                        </Button>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                component: 'form',
                                                onSubmit: (event) => {
                                                    event.preventDefault();
                                                    const formData = new FormData(event.currentTarget);
                                                    const formJson = Object.fromEntries(formData.entries());
                                                    const email = formJson.email;
                                                    console.log(email);
                                                    handleClose();
                                                },
                                            }}
                                        >
                                            <DialogTitle>Subscribe</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    To subscribe to this website, please enter your email address here. We
                                                    will send updates occasionally.
                                                </DialogContentText>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    margin="dense"
                                                    id="name"
                                                    name="email"
                                                    label="Email Address"
                                                    type="email"
                                                    fullWidth
                                                    variant="standard"
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </React.Fragment>                                </Grid>
                            </Grid>
                            <Box component='h3' sx={{ m: 0, fontWeight: 'bold' }}>List</Box>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Field Group</StyledTableCell>
                                            <StyledTableCell>Field Remark</StyledTableCell>
                                            <StyledTableCell>Type List</StyledTableCell>
                                            <StyledTableCell>Field List</StyledTableCell>
                                            <StyledTableCell>Field Order</StyledTableCell>
                                            <StyledTableCell>Delete</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow sx={styles.TableRow}>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ m: 0, p: 1 }}>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    multiline
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size='small'>-Select-</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="-Select-"
                                                        name="Select"
                                                        value={state.Select}
                                                        onChange={handleChange}
                                                        size='small'
                                                    >
                                                        <MenuItem sx={{ ml: 3 }}><em>-Select-</em></MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={3}>Oxygen concentrator</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Tooltip title="Delete">
                                                    <IconButton>
                                                        <DeleteIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow sx={styles.TableRow}>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ m: 0, p: 1 }}>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    multiline
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size='small'>-Select-</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="-Select-"
                                                        name="Selects"
                                                        value={state.Selects}
                                                        onChange={handleChange}
                                                        size='small'
                                                    >
                                                        <MenuItem sx={{ ml: 3 }}><em>-Select-</em></MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={3}>Oxygen concentrator</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                        <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField
                                                    id="filled-search"
                                                    type="search"
                                                    size='small'
                                                    fullWidth
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Tooltip title="Delete">
                                                    <IconButton>
                                                        <DeleteIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}