import { useEffect, useState } from 'react';
import sharedImports from 'component/Comman/sharedImports';
import { InsertLanguage, GetLanguage } from '../../../api/changeLanguage';
import styles from 'css/styles';
import { useSnackbar } from 'notistack';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar';
const {
    React,
    Box,
    Grid,
    Button,
    TextField
} = sharedImports;


export default function ClinicalLanguage() {
    const [formData, setFormData] = useState({
        headingKey: 'hr_language',
        headingValue: {}
    });
    const { enqueueSnackbar } = useSnackbar();
    const fields = [
        { label: 'Examination Type', name: 'clinical_examination_type', placeholder: 'Examination Type' },
        { label: 'Store Location', name: 'clinical_store_location', placeholder: 'Store Location' },
        { label: 'Round', name: 'clinical_round', placeholder: 'Round' },
        { label: 'Employee Code', name: 'clinical_employee_code', placeholder: 'Employee Code' },
        { label: 'Name Like', name: 'clinical_name_like', placeholder: 'Name Like' },
        { label: 'Document Name Like', name: 'clinical_document_name_like', placeholder: 'Document Name Like' },
        { label: 'Employee Search', name: 'clinical_employee_search', placeholder: 'Employee Search' },
        { label: 'Employee Name', name: 'clinical_employee_name', placeholder: 'Employee Name' },
        { label: 'Department', name: 'clinical_department', placeholder: 'Department' },
        { label: 'All Test and General Results', name: 'clinical_all_test_and_general_results', placeholder: 'All Test and General Results' },
        { label: 'Source Type', name: 'clinical_source_type', placeholder: 'Source Type' },
        { label: 'Round Group', name: 'clinical_round_group', placeholder: 'Round Group' },
        { label: 'Sub Location', name: 'clinical_sub_location', placeholder: 'Sub Location' },
        { label: 'Company', name: 'clinical_company', placeholder: 'Company' },
        { label: 'Name', name: 'clinical_name', placeholder: 'Name' },
        { label: 'Code', name: 'clinical_code', placeholder: 'Code' },
        { label: 'Doctor', name: 'clinical_doctor', placeholder: 'Doctor' },
        { label: 'Target Group', name: 'clinical_target_group', placeholder: 'Target Group' },
        { label: 'From Date', name: 'clinical_from_date', placeholder: 'From Date' },
        { label: 'To Date', name: 'clinical_to_date', placeholder: 'To Date' },
        { label: 'Code Like (Ex.10,155 etc.)', name: 'clinical_code_like', placeholder: 'Code Like (Ex.10,155 etc.)' },
        { label: 'Report Name', name: 'clinical_report_name', placeholder: 'Report Name' }
    ]

    const getData = async () => {
        try {
            const parmas = { headingKey: 'clinical_language' }
            const response = await GetLanguage(parmas);
            if (response.status) {
                const { data } = response;
                //console.log(typeof data);
                const formData = { headingKey: 'clinical_language', headingValue: {} };
                Object.keys(data).forEach((key) => {
                    formData.headingValue[key] = data[key];
                });
                console.log('formData == ', formData);

                setFormData(formData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            headingValue: { ...prevFormData.headingValue, [name]: value }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        insertData(formData);
    };

    const insertData = async (formData) => {
        try {
            const newData = { ...formData };
            const response = await InsertLanguage(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            console.log('error', error);

            enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
    }
    return (
        <Box sx={styles.root}>
            <CommanAppBarWithAddButton appBarTitle="Clinical Managment" />
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>

                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Original Headings</Box>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Change Headings</Box>
                        </Grid>
                    </Grid>

                    {fields.map((field, index) => (
                        <Grid container spacing={2}>
                            <React.Fragment key={index}>
                                <Grid item xs={4} md={4}>
                                    <Box sx={{ fontWeight: 'bold' }}>{field.label}</Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField
                                        required
                                        id={`filled-${field.name}`}
                                        fullWidth
                                        size='small'
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        value={formData.headingValue[field.name] || ''}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    ))}
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}