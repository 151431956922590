import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DeleteForever } from '@mui/icons-material';
import ResponsiveDialog from '../Popup/Confirm';

function DeleteButton({ id, fieldName, index, handleDeleteRow, titleName }) {
    return (
      <ResponsiveDialog
        name={fieldName}
        id={id}
        index={index}
        head={
          <Tooltip arrow placement="left" title={`Delete ${titleName}`}>
            <DeleteForever sx={{ color: 'red', cursor: 'pointer' }}/>
          </Tooltip>
        }
        action="delete"
        onPress={handleDeleteRow}
      ></ResponsiveDialog>
    );
  }

export default DeleteButton;