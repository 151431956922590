import React, { useState, useEffect } from 'react';
import EmployeeForm from '../Employee-Management/from';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../../../api/user';

const UpdateUser = () => {
  const { id } = useParams();
  
  const [userData, setUserData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SingleList(id);      
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (userData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <EmployeeForm userId={id} userData={userData} />
    </>
  );
};

export default UpdateUser;
