import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeSubType = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeSubType = async (props) => {
  const url = `${ApiConfig.employeeSubTypeMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_sub_type_id) => {
  const url = `${ApiConfig.employeeSubTypeMain}?id=${employee_sub_type_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeSubType = async (props) => {
  const url = `${ApiConfig.employeeSubTypeMain}?id=${props.employee_sub_type_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeSubType = async (employee_sub_type_id) => {
  const url = `${ApiConfig.employeeSubTypeMain}?id=${employee_sub_type_id}`;
  const response = await api.delete(url);
  return response.data;
};
