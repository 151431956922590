import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Grid,
    Card,
    Table,
    styled,
    Button,
    Dialog,
    Toolbar,
    TableRow,
    TableHead,
    TableCell,
    TextField,
    Typography,
    DialogTitle,
    CardContent,
    DialogContent,
    DialogActions,
    TableContainer,
    DialogContentText,
    HighlightOffIcon,
    tableCellClasses,
    useScrollTrigger,
    Slide,
    PropTypes,
    ArrowBackIcon,
    Checkbox,
    TableBody,
    KeyboardArrowUpIcon,
    KeyboardArrowDownIcon,

} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SelectAll: '', Name: 'PERIODIC', MainType: 'Periodic', Active: '', DirectApprove: '', Online: '', Offline: '' },
    { SelectAll: '', Name: 'PRE PLACEMENT', MainType: 'Pre Placement', Active: '', DirectApprove: '', Online: '', Offline: '' },
    { SelectAll: '', Name: 'PRE RETIREMENT', MainType: 'Pre Retirement', Active: '', DirectApprove: '', Online: '', Offline: '' },
    { SelectAll: '', Name: 'POST SICKNESS', MainType: 'Post Sickness', Active: '', DirectApprove: '', Online: '', Offline: '' },
    { SelectAll: '', Name: 'OPD', MainType: 'OPD', Active: '', DirectApprove: '', Online: '', Offline: '' },
    { SelectAll: '', Name: 'POST ACCIDENT', MainType: 'Post Accident', Active: '', DirectApprove: '', Online: '', Offline: '' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={5.5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Appoinment Setup
                                </Typography>
                            </Grid>

                            <Grid item xs={5.5}>
                                <Button variant="outlined" sx={{ textTransform: 'none', m: 1 }} startIcon={<HighlightOffIcon />}>
                                    Clear
                                </Button>
                            </Grid>

                            <Grid item xs={1}>
                                <React.Fragment>
                                    <Button
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        Back
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>


            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        mb: 5,
                        marginTop: '55px',
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={3}>
                                    </Grid>

                                    <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        <Typography variant="body1">Search:</Typography>
                                        <TextField
                                            required
                                            id="filled-search"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ pt: '0px !important' }}>
                            <CardContent sx={{ pt: '5px !important' }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>
                                                    <Checkbox sx={{ p: 0 }} />
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Select All<KeyboardArrowUpIcon sx={{ mt: 1, }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Name<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Main Type<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Active<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Direct Approve<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Online<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Offline<KeyboardArrowUpIcon sx={{ mt: 1 }} />< KeyboardArrowDownIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.SelectAll} sx={styles.TableRow}>
                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>
                                                    <StyledTableCell> {row.SelectAll} </StyledTableCell>
                                                    <StyledTableCell>{row.Name}</StyledTableCell>
                                                    <StyledTableCell>{row.MainType}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <Typography variant="body1" sx={{ ml: 2, mb: 2 }} >Showing 1 to 6 of 6 entries</Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
}