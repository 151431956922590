import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Select,
    Button,
    AppBar,
    Toolbar,
    MenuItem,
    PropTypes,
    FormControl,
    Typography,
    SaveOutlinedIcon,
    useScrollTrigger,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ChangeLocation(props) {

    const [state, setState] = React.useState({
        Location: '',
        SubLocation: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Change Location
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Button variant="contained" sx={{ textTransform: 'none', m: 1 }} startIcon={<SaveOutlinedIcon />}>Change</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1} sx={styles.pAlart}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Location <Box component='span' sx={styles.color}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Location"
                                        value={state.Location}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9}></Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Sub Location <Box component='span' sx={styles.color}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="SubLocation"
                                        value={state.SubLocation}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9}></Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}