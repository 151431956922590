import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import CustomTextField from 'component/Comman/Popup/Fields/textFIeld';

const EmailPopup = (props) => {
    const handleSend = () => {
        props.handleSendEmail();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        props.setEmailData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Send Email</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the subject and body of the email.
                </DialogContentText>
                <CustomTextField
                    label="Subject"
                    name="subject"
                    value={props.emailData.subject}
                    onChange={handleChange}
                    required
                    autoFocus
                />
                <CustomTextField
                    label="Body"
                    name="body"
                    value={props.emailData.body}
                    onChange={handleChange}
                    required
                    multiline
                    rows={4}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSend} color="primary">
                    Send Email
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailPopup;