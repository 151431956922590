import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeContractorSubBusiness = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeContractorSubBusiness = async (props) => {
  const url = `${ApiConfig.employeeContractorSubBusinessMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_contractor_sub_business_id) => {
  const url = `${ApiConfig.employeeContractorSubBusinessMain}?id=${employee_contractor_sub_business_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeContractorSubBusiness = async (props) => {
  const url = `${ApiConfig.employeeContractorSubBusinessMain}?id=${props.employee_contractor_sub_business_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeContractorSubBusiness = async (employee_contractor_sub_business_id) => {
    const url = `${ApiConfig.employeeContractorSubBusinessMain}?id=${employee_contractor_sub_business_id}`;
    const response = await api.delete(url);
    return response.data;
};