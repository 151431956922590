import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { GetUserRole } from '../../../../api/userRole';
import { CommanAppBar } from '../../../Comman/commanAppBar';
import { PermissionMenusArray } from 'component/Comman/PermissionMenus';
import sharedImports from '../../../Comman/sharedImports';
import styles from 'css/styles';
import { InsertCreateUserPermission, GetAllUserPermission } from '../../../../api/userPermission';

const {
    React,
    Box,
    Tab,
    Tabs,
    Card,
    Checkbox,
    Typography,
    Button,
} = sharedImports;

export default function UserRole() {
    const [userRoleData, setUserRoleData] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [tabStates, setTabStates] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const getUserRole = async () => {
        try {
            const parmas = {}
            const response = await GetUserRole(parmas);
            if (response.status) {
                const { data } = await response?.data;
                setUserRoleData(data);
                setTabStates(data.map(role => ({
                    user_role_id: role.user_role_id,
                    permissions: {}
                })));
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    const getUserPermission = async (user_role_id) => {        
        try {
            const response = await GetAllUserPermission();
            if (response.status) {
                const permissionsData = response.data.find(item => item.user_role_id === user_role_id);
                
                if (permissionsData) {
                    const permissions = JSON.parse(permissionsData?.permissions);
                    setTabStates(prevTabStates => {
                        const newTabStates = [...prevTabStates];
                        const tabIndex = newTabStates.findIndex(tab => tab.user_role_id === user_role_id);
                        if (tabIndex !== -1) {
                            newTabStates[tabIndex].permissions = permissions;
                        }
                        return newTabStates;
                    });
                }
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    useEffect(() => {
        getUserRole();
    }, []);

    useEffect(() => {
        if (userRoleData.length > 0) {
            getUserPermission(userRoleData[tabValue].user_role_id);
        }
    }, [tabValue, userRoleData]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCheckboxChange = (action, isParent = false, submenu = [], parentAction = null) => {
        setTabStates(prevTabStates => {
            const newTabStates = [...prevTabStates];
            const newPermissions = {
                ...newTabStates[tabValue].permissions,
                [action]: !newTabStates[tabValue].permissions[action],
            };
    
            // Helper function to update submenu items recursively
            const updateSubmenu = (items, value) => {
                items.forEach(item => {
                    newPermissions[item.action] = value;
                    if (item.submenu) {
                        updateSubmenu(item.submenu, value);
                    }
                });
            };
    
            // If the current action is a parent, update all its submenu items
            if (isParent) {
                updateSubmenu(submenu, newPermissions[action]);
            } else if (parentAction) {
                if (newPermissions[action]) {
                    // If a submenu item is checked, check its parent and all its submenus
                    newPermissions[parentAction] = true;
                    updateSubmenu(submenu, true);
                } else {
                    // If a submenu item is unchecked, check if all other submenus are unchecked
                    const parentSubmenu = PermissionMenusArray[parentAction]?.submenu || [];
                    if (parentSubmenu.every(subItem => !newPermissions[subItem.action])) {
                        newPermissions[parentAction] = false;
                    }
                    // Uncheck all submenus
                    updateSubmenu(submenu, false);
                }
            }
    
            // Uncheck parent if all submenus are unchecked
            if (!newPermissions[action] && parentAction) {
                const parentSubmenu = PermissionMenusArray[parentAction]?.submenu || [];
                if (parentSubmenu.every(subItem => !newPermissions[subItem.action])) {
                    newPermissions[parentAction] = false;
                }
            }
    
            // Check all parent menus if a submenu is checked
            if (newPermissions[action]) {
                let currentAction = parentAction;
                while (currentAction) {
                    newPermissions[currentAction] = true;
                    currentAction = (function(action) {
                        return Object.keys(PermissionMenusArray).find(key =>
                            PermissionMenusArray[key].submenu?.some(sub => sub.action === action)
                        );
                    })(currentAction);
                }
            }
    
            newTabStates[tabValue].permissions = newPermissions;
            return newTabStates;
        });
    };

    const handleSubmit = async () => {
        try {
            const currentTabState = tabStates[tabValue];

            const newData = { ...currentTabState };
            const response = await InsertCreateUserPermission(newData);
    
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                await getUserRole();
            } else {
                enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }
    
    const tabProps = (index, row) => ({
        key: row.user_role_id,
        label: row.name,
        sx: styles.permissionTab,
        ...a11yProps(index)
    });

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={styles.permissionTabPanel}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const renderSubmenu = (submenu, parentAction) => {
        return submenu.map((item, index) => (
            <div key={index} style={styles.permissionCheckboxLabel}>
                <h4 style={styles.permissionCheckboxLabelH4}>
                    <label htmlFor={item.label}>
                        <Checkbox
                            id={item.label}
                            name={item.label}
                            sx={styles.permissionCheckboxLabelInput}
                            checked={!!tabStates[tabValue]?.permissions[item.action]}
                            onChange={() => handleCheckboxChange(item.action, false, item.submenu, parentAction)}
                        />
                        {item.label}
                    </label>
                </h4>
                {item.submenu && (
                    <div style={styles.permissionSubmenu}>
                        {renderSubmenu(item.submenu, item.action)}
                    </div>
                )}
            </div>
        ));
    };
    
    return (
        <>
            <CommanAppBar appBarTitle="Permission Manager" showButtons={false} />
            <Card sx={styles.permissionTabsContainer}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs" sx={styles.permissionTabs}>
                    {userRoleData.map((row, index) => (
                        <Tab {...tabProps(index, row)} />
                    ))}
                </Tabs>

                {userRoleData.map((row, index) => (
                    <TabPanel key={index} value={tabValue} index={index}>
                        {Object.keys(PermissionMenusArray).map((key) => (
                            <div key={key} style={styles.permissionCheckboxLabel}>
                                <h4 style={{ ...styles.permissionParentCheckboxLabelH4 }}>
                                    <label htmlFor={key}>
                                        <Checkbox
                                            id={key}
                                            name={key}
                                            sx={styles.permissionCheckboxLabelInput}
                                            checked={!!tabStates[tabValue]?.permissions[PermissionMenusArray[key].action]}
                                            onChange={() => handleCheckboxChange(PermissionMenusArray[key].action, true, PermissionMenusArray[key].submenu)}
                                        />
                                        {PermissionMenusArray[key].label}
                                    </label>
                                </h4>
                                {PermissionMenusArray[key].submenu && (
                                    <div style={styles.permissionSubmenu}>
                                        {renderSubmenu(PermissionMenusArray[key].submenu, PermissionMenusArray[key].action)}
                                    </div>
                                )}
                            </div>
                        ))}
                        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '16px', marginBottom: '16px' }} >
                            Submit
                        </Button>
                    </TabPanel>
                ))}
            </Card>
        </>
    );
}