import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Toolbar,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Typography,
    CssBaseline,
    Button,
    CachedIcon,
    CancelOutlinedIcon,
    useScrollTrigger,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
    { round: 'Annual Health Checkup 2022-23' },
];
const reportListRows2 = [
    { parameter: 'Annual Health Checkup 2022-23' },
    { parameter: 'Annual Health Checkup 2022-23' },
    { parameter: 'Annual Health Checkup 2022-23' },
    { parameter: 'Annual Health Checkup 2022-23' },
    { parameter: 'Annual Health Checkup 2022-23' },
    { parameter: 'Annual Health Checkup 2022-23' },
];
const reportListRows3 = [
    {
        paramenterName: 'Cholesterol',
        annualHealthCheckup2021: 188.25,
        annualHealthCheckup2020: 189.98,
        status: 0.91 + '% Decrease',
    }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function CompareParameter(props) {

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '58px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Compare Parameter
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 4, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={6}>
                                    <Box component='h3'>Round</Box>
                                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow sx={styles.TableRow}>
                                                    <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                    <StyledTableCell>Round<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {reportListRows.map((row) => (
                                                    <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                        <StyledTableCell> </StyledTableCell>
                                                        <StyledTableCell>{row.round}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ pt: '75px !important' }}>
                                    <Box component='h3'>Round</Box>
                                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow sx={styles.TableRow}>
                                                    <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                    <StyledTableCell>Parameter<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {reportListRows2.map((row) => (
                                                    <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                        <StyledTableCell> </StyledTableCell>
                                                        <StyledTableCell>{row.parameter}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'Center', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<CachedIcon />} sx={{ mr: 0.5, mb: 2, textTransform: 'none' }}>Compare</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ ml: 0.5, mb: 2, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Paramenter Name</StyledTableCell>
                                            <StyledTableCell>Annual Health Checkup 2021-22</StyledTableCell>
                                            <StyledTableCell>Annual Health Checkup 2020-21</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows3.map((row) => (
                                            <StyledTableRow key={row.paramenterName} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ color: 'blue' }}> {row.paramenterName} </StyledTableCell>
                                                <StyledTableCell>{row.annualHealthCheckup2021}</StyledTableCell>
                                                <StyledTableCell>{row.annualHealthCheckup2020}</StyledTableCell>
                                                <StyledTableCell>{row.status}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}