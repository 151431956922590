import { useEffect, useState } from 'react';
import sharedImports from 'component/Comman/sharedImports';
import { ApiConfig } from '../../../../../api/config/ApiConfig';
import { useSnackbar } from 'notistack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
import PaginationControls from '../../../../Comman/paginationControls';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import { Listing,Delete } from "api/employee-management/employeeTransferOut";
import { GetEmployeeWorkLocation } from "api/employee-master-setup/employeeWorkLocation";

const {
    React,
    PropTypes,
    Slide,
    AppBar,
    Stack,
    Toolbar,
    Link,
    Box,
    Grid,
    Select,
    Button,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    FormControl,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
    AddCircleOutlineOutlinedIcon,
    FileCopyOutlinedIcon,
    useScrollTrigger,
    Typography,
} = sharedImports;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function EmployeeTransferOut(props) {
    const [data, setData] = useState([]);
    const [location, setLocation] = useState([]);

    const [filters, setFilters] = useState({ FromLocation: '', ToLocation: '', EmployeeCode: '', EmployeeName: ''});

    const [tempFilters, setTempFilters] = useState({
        EmployeeCode: "",
        EmployeeName: "",
        FromLocation: "",
        ToLocation: ""
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    
    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });


    const { baseUrl, listAllEmployeeWorkLocation, getAllEmployeeTransferOut } = ApiConfig;
    const fetchInfoLocation = async () => {
        try {
        const url = new URL(listAllEmployeeWorkLocation, baseUrl);
        url.searchParams.set(
            "sorting",
            JSON.stringify([{ employee_work_location_id: "name", asc: true }])
        );
        const response = await GetEmployeeWorkLocation(url);
        if (response.status) {
            const { data } = response?.data;
            setLocation(data);
        } else {
            enqueueSnackbar(response.message, { variant: "error" });
        }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    };
    
    const fetchInfo = async () => {
        try {
            const page=pagination.pageIndex+1;
            const per_page=pagination.pageSize;
            
            const url = new URL(
            getAllEmployeeTransferOut,
            baseUrl,
            );
            url.searchParams.set(
            'page',
            `${page}`,
            );
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            url.searchParams.set('FromLocation', filters.FromLocation ?? '');
            url.searchParams.set('ToLocation', filters.ToLocation ?? '');
            url.searchParams.set('EmployeeCode', filters.EmployeeCode ?? '');
            url.searchParams.set('EmployeeName', filters.EmployeeName ?? '');
            const response = await Listing(url);
            if (response.status) {
                const { data, totalCount } = await response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }   

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });
        
        fetchInfo();
    };

    const handleDeleteRow = async (id) => {
        try {
            const response = await Delete(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setData((prevData) => prevData.filter((item) => item.id !== id));
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    };

    const handleExport = () => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'employee-transfer-out.csv');
    };

    const handleSearch = () => {
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        setFilters(tempFilters); // 
    };

    const handleClear = () => {
        setFilters({ FromLocation: '', ToLocation: '', EmployeeCode: '', EmployeeName: ''});
        setTempFilters({ FromLocation: '', ToLocation: '', EmployeeCode: '', EmployeeName: ''});
        setGlobalFilter('');
        setSorting([]);
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };
   
    
    useEffect(() => {
        fetchInfo();
        fetchInfoLocation();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting,filters]);

   

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        subHeader: {
            padding: '10px',
            h3: {
                margin: '0px',
                padding: '0px'
            }
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
            mt: 2
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        }
    }

    return (
        <>
            <React.Fragment>
                <Box sx={styles.root}>
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Employee Transfer Out
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={1}>
                                        <React.Fragment>
                                            <Link to='/dashboard/hr/employee-management/create-employee-transfer-out'>
                                            <Button variant='contained' sx={{ textTransform: 'none', m: 1, mb: 2 }} startIcon={<AddCircleOutlineOutlinedIcon />}> Add </Button>
                                            </Link>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={styles.firstForm}>
                        <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                            <Grid container spacing={1}>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            name='EmployeeCode'
                                            placeholder="Code Like"
                                            value={tempFilters.EmployeeCode}
                                            onChange={(e) => setTempFilters(prev => ({ ...prev, EmployeeCode: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            name='EmployeeName'
                                            size='small'
                                            placeholder="Name Like"
                                            value={tempFilters.EmployeeName}
                                            onChange={(e) => setTempFilters(prev => ({ ...prev, EmployeeName: e.target.value }))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="FromLocation"
                                                displayEmpty
                                                value={tempFilters.FromLocation}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => setTempFilters(prev => ({ ...prev, FromLocation: e.target.value }))}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {location.map((row, index) => (
                                                <MenuItem
                                                    sx={{ ml: 3 }}
                                                    value={row.employee_work_location_id}
                                                >
                                                    {row.name}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="ToLocation"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                value={tempFilters.ToLocation}
                                                onChange={(e) => setTempFilters(prev => ({ ...prev, ToLocation: e.target.value }))}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {location.map((row, index) => (
                                                <MenuItem
                                                    sx={{ ml: 3 }}
                                                    value={row.employee_work_location_id}
                                                >
                                                    {row.name}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                

                                <Grid item xs={12}>
                                    <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom2}>
                                        <Button variant='contained' startIcon={<SearchOutlinedIcon />} onClick={handleSearch} sx={{ mr: 0.5, mb: 2 }}>Search</Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} onClick={handleClear} sx={{ ml: 0.5, mb: 2 }}>Clear</Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>                    
                </Box>
                <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                    
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={ (e) => setGlobalFilter(e.target.value) }
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={() => handleSort('EmployeeName')}>
                                                Employee Name
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('EmployeeCode')}>
                                                Employee Code
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>                                            
                                            <StyledTableCell onClick={() => handleSort('FromLocation')}>
                                                From Location
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('ToLocation')}>
                                                To Location
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('Remark')}>
                                                Remark
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={7} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                        data.map((row) => (
                                            <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                <StyledTableCell>{row.EmployeeName}</StyledTableCell>
                                                <StyledTableCell>{row.EmployeeCode}</StyledTableCell>
                                                <StyledTableCell> {row.employeeFromLocation} </StyledTableCell>
                                                <StyledTableCell>{row.employeeToLocation}</StyledTableCell>
                                                <StyledTableCell>{row.Remark}</StyledTableCell>
                                                <StyledTableCell>
                                                    <EditOutlinedIcon onClick={() => navigate(`/dashboard/hr/employee-management/update-employee-transfer-out/${row.id}`)} />
                                                    <DeleteButton id={row.id} fieldName={row.remark} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.id)} titleName="Employee Work Location" />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
            </React.Fragment>
        </>
    )
}