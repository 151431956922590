import EmployeeForm from '../Employee-Management/from';


const EmployeeAllDetail = () => {
    return (
    <>
      <EmployeeForm />
    </>  
  )
}

export default EmployeeAllDetail
