import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ label, value, onChange, name, type = 'text', required = false, multiline = false, rows = 1, size = 'medium', fullWidth = true, autoFocus = false, ...props }) => (
    <TextField
        autoFocus={autoFocus}
        margin="dense"
        label={label}
        type={type}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        required={required}
        multiline={multiline}
        rows={rows}
        size={size}
        name={name}
        {...props}
    />
);

export default CustomTextField;
