import React from 'react';
import styles from 'css/styles';
import { CommanAppBar } from '../../../../component/Comman/commanAppBar';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import { useSnackbar } from 'notistack';
import { SendGetRequest } from '../../../../api/config/ApiCall';
import PaginationControls from '../../../Comman/paginationControls';
import PageSizeSelector from '../../../Comman/PageSizeSelector';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
import sharedImports from '../../../Comman/sharedImports';
const {
    Box,
    Grid,
    Select,
    Button,
    MenuItem,
    TextField,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Typography,
    FormControl,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    FileCopyOutlinedIcon,
} = sharedImports;

export default function DocumentSearch(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [hitApi, setHitApi] = React.useState(false);
    const [formData, setFormData] = React.useState({
        examination_type: "",
        store_location: "",
        round: "",
        code: "",
        full_name: "",
    });
    
    const [globalFilter, setGlobalFilter] = React.useState('');   
    const [sorting, setSorting] = React.useState([]); 
    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isSearched, setIsSearched] = React.useState(false);
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const isSearch = React.useMemo(
        () =>
          formData.examination_type ||
          formData.store_location ||
          formData.round ||
          formData.full_name ||
          formData.documentName ||
          formData.code,
        [formData]
    );
    
    const getRows = React.useCallback(async () => {
        try {
            const data = {
              page: pagination.pageIndex + 1,
              per_page: pagination.pageSize,
              examination_type: formData.examination_type,
              store_location: formData.store_location,
              round: formData.round,
              full_name: formData.full_name,
              documentName: formData.documentName,
              code: formData.code,
              globalFilter: globalFilter,
              sorting: JSON.stringify(sorting ?? []),
            };

            const response = await SendGetRequest("listAllDocumentUrl", data);
            
            if (response.status) {
              const { data, count } = response?.data;
              setRows(data);
              setRowCount(count);
            } else {
              enqueueSnackbar(response.message, { variant: "error" });
            }
          } catch (err) {
            console.error("Error fetching employment grades:", err);
          }
    }, [hitApi, pagination.pageIndex, pagination.pageSize, globalFilter, sorting]);

    React.useEffect(() => {
        getRows();
    }, [getRows]);

    const handleExport = () => {
        const csv = Papa.unparse(rows);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'expenses.csv');
    };

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });
        
        getRows();
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Document Search" showButtons={false} />
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="examination_type"
                                                value={formData.examination_type}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Store Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="store_location"
                                                value={formData.store_location}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="round"
                                                value={formData.round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Code'
                                            name="code"
                                            value={formData.code}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Name Like</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name Like'
                                            name="full_name"
                                            value={formData.full_name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Document Name Like</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Document Name Like'
                                            name="documentName"
                                            value={formData.documentName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}
                                        onClick={() => {
                                            setIsSearched(true);
                                            setHitApi(!hitApi);
                                        }}
                                        disabled={!isSearch}>Search
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant='outlined' 
                                        sx={{ textTransform: 'none' }} 
                                        disabled={!isSearched}
                                        onClick={() => {
                                            setFormData((prev) => ({
                                            ...prev,
                                            examination_type: "",
                                            store_location: "",
                                            round: "",
                                            code: "",
                                            }));
                                            setIsSearched(false);
                                            setHitApi(!hitApi);
                                        }}
                                        startIcon={<CancelOutlinedIcon />}>
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={() => handleSort('examination_type')}>Examination Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('store_location')}>Store Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('round')}>Round<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('employee_code')}>Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Document</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={8} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            rows.map((row, index) => (
                                                <StyledTableRow key={index} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.examination_type} </StyledTableCell>
                                                    <StyledTableCell>{row.store_location}</StyledTableCell>
                                                    <StyledTableCell>{row.round}</StyledTableCell>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell>{row.full_name}</StyledTableCell>
                                                    <StyledTableCell>{row.documentName}</StyledTableCell>
                                                    <StyledTableCell sx={{ color: '#1976d2' }}>
                                                        <a target='_blank' rel='noreferrer' href={row.document}>View Download</a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}