import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeDesignation = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeDesignation = async (props) => {
  const url = `${ApiConfig.employeeDesignationMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_designation_id) => {
  const url = `${ApiConfig.employeeDesignationMain}?id=${employee_designation_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeDesignation = async (props) => {
  const url = `${ApiConfig.employeeDesignationMain}?id=${props.employee_designation_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeDesignation = async (employee_designation_id) => {
    const url = `${ApiConfig.employeeDesignationMain}?id=${employee_designation_id}`;
    const response = await api.delete(url);
    return response.data;
};

