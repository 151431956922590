import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Paper,
    Table,
    AppBar,
    Button,
    Select,
    styled,
    Dialog,
    Toolbar,
    TableRow,
    MenuItem,
    TextField,
    TableHead,
    TableBody,
    PropTypes,
    TableCell,
    Typography,
    CssBaseline,
    FormControl,
    DialogTitle,
    DialogActions,
    DialogContent,
    TableContainer,
    useScrollTrigger,
    tableCellClasses,
    CancelOutlinedIcon,
    EditNoteOutlinedIcon,
    FormatListBulletedOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { user: 'nitesh', createdDateTime: '2023-07-28 11:07', action: 'Add', remark: null, note: null, },
];
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function TicketMasterUpdate(props) {

    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    function handleChange(event, newValue) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Ticket Master
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {/* <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button> */}
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>License Number</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Connect No'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Module <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Support Ticket Status <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Button variant='contained' sx={{ color: '#ff6a00', bgcolor: '#ffe6d0', textTransform: 'none', boxShadow: 'none' }}>Pending</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Contact Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Contact Name'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Contact Number <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Contact Number'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Alternate Contact Number</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Alternate Contact Number'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>User Name</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='User Name'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box>Ticket Description <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box>Issue Steps</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Box>Note : You will get Occucare Ticket Number in next 10 to 15 mins.</Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center' sx={{ mb: 2 }}>
                                <Grid item>
                                    <React.Fragment>
                                        <Button variant="outlined" onClick={handleClickOpen} startIcon={<EditNoteOutlinedIcon />} sx={{ textTransform: 'none' }}>
                                            Add Note
                                        </Button>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                component: 'form',
                                                onSubmit: (event) => {
                                                    event.preventDefault();
                                                    const formData = new FormData(event.currentTarget);
                                                    const formJson = Object.fromEntries(formData.entries());
                                                    const addNote = formJson.addNote;
                                                    console.log(addNote);
                                                    handleClose();
                                                },
                                            }}
                                        >
                                            <DialogTitle>Add Note</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    margin="dense"
                                                    id="filled-search"
                                                    type="search"
                                                    multiline
                                                    fullWidth
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Cancel</Button>
                                                <Button type="submit" variant='contained'>Add</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </React.Fragment>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ textTransform: 'none' }}>Log Detail</Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>User</StyledTableCell>
                                            <StyledTableCell>Created Date Time</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                            <StyledTableCell>Remark</StyledTableCell>
                                            <StyledTableCell>Note</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.user} sx={styles.TableRow}>
                                                <StyledTableCell>{row.user}</StyledTableCell>
                                                <StyledTableCell>{row.createdDateTime}</StyledTableCell>
                                                <StyledTableCell>{row.action}</StyledTableCell>
                                                <StyledTableCell>{row.remark}</StyledTableCell>
                                                <StyledTableCell>{row.note}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}