import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import styles from 'css/styles';
import { SendPostRequest, SendPutRequest } from '../../../../api/config/ApiCall';
const {
    React,
    Box,
    Grid,
    Link,
    TextField,
    AppBar,
    Button,
    Toolbar,
    SaveOutlinedIcon,
    Typography,
    CssBaseline,
    FormControl,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;

export default function LabForm(props) {
    const [formData, setFormData] = useState({
        name: "",
        location: "",
        mobileNumber: "",
        description: "",
    });

    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                response = await SendPutRequest("listAllLabsUrl", formData);
            } else {
                const newData = { ...formData };
                response = await SendPostRequest("listAllLabsUrl", newData);
            }

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                navigate('/dashboard/pre-joinee-management/labs');
            } else {                
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    };

    useEffect(() => {
        if (props.labId && props.labData) {
            setIsEditing(true);
            setFormData({ ...props.labData });
        }
    }, [props.labId, props.labData]);

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099,
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        {isEditing ? 'Edit Lab' : 'Create Lab'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        startIcon={<SaveOutlinedIcon />}
                                        sx={{ m: 1, textTransform: 'none' }}
                                        onClick={handleSubmit}
                                    >
                                        {isEditing ? 'Update' : 'Save'}
                                    </Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Link to='/dashboard/labs'>
                                        <Button
                                            variant='contained'
                                            startIcon={<FormatListBulletedOutlinedIcon />}
                                            sx={{ m: 1, textTransform: 'none' }}
                                        >
                                            List
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ fontWeight: 'bold' }}>Lab Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="name"
                                        placeholder="Enter lab name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{ fontWeight: 'bold' }}>Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="location"
                                        placeholder="Enter location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{ fontWeight: 'bold' }}>Mobile Number <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="mobileNumber"
                                        placeholder="Enter mobile number"
                                        value={formData.mobileNumber}
                                        onChange={handleInputChange}
                                        type="tel"
                                        inputProps={{
                                            maxLength: 15, // Optional: Limit the max length of the input
                                            pattern: "[0-9]*", // Optional: Ensure only numbers are entered
                                        }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <Box sx={{ fontWeight: 'bold' }}>Description</Box>
                                <FormControl fullWidth>
                                    <TextField
                                        multiline
                                        rows={4}
                                        name="description"
                                        placeholder="Enter description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    );
}
