import sharedImports from '../../../Comman/sharedImports';
import { useEffect, useState } from 'react';
import { validateFileType } from './importCommonFunctions.js/importFile';
import { importDependentEmployee } from '../../../../api/user';
import { useSnackbar } from 'notistack';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Alert,
    Slide,
    AppBar,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    useScrollTrigger,
    styled,
    FileUploadOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportDependent(props) {
    const [file, setFile] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && validateFileType(selectedFile)) {
            setFile(selectedFile);
        } else {
            enqueueSnackbar("Please select an Excel file (.xlsx or .xls, csv)", { variant: "warning" });
            setFile(null);
        }
    };


    const handleFileImport = async () => {
        try {
            if (!file) {
                enqueueSnackbar("Please select a file", { variant: "warning" });
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            const response = await importDependentEmployee(formData);

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            console.error('Error uploading file:', err);
        }
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                    Import Dependent
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                            Notes: Text data to be Separated
                        </Alert>
                        <Box component="div" xs={12}>
                            <Box component='div'>
                                <Button sx={{ textTransform: 'none' }} href="/templates/Import_Dependent_Template.xls" download>
                                    Download Template
                                </Button>
                            </Box>

                            <Grid>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="fileInput"
                                    accept=".csv, .xlsx, .xls"
                                />

                                {/* Select File Button */}
                                <Button
                                    variant="contained"
                                    onClick={() => document.getElementById('fileInput').click()}
                                    startIcon={<FileUploadOutlinedIcon />}
                                    sx={{ mr: 3 }}
                                >
                                    Select File
                                </Button>

                                {/* Import Button */}
                                <Button
                                    variant='contained'
                                    sx={{ textTransform: 'none' }}
                                    startIcon={<FileUploadOutlinedIcon />}
                                    onClick={handleFileImport}
                                >
                                    Import
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}