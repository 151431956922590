import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Tab,
    Tabs,
    Box,
    Slide,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    TableRow,
    MenuItem,
    TableHead,
    TableCell,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    DemoItem,
    PropTypes,
    DatePicker,
    AdapterDayjs,
    ArrowDropDownIcon,
    useScrollTrigger,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
    TableBody,

} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { OPDNumber: 'OPD-2023070768617', EmployeeCode: '30061596', PatientName: 'Alpesh Mehta', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'HUMAN RESOURCES', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768616', EmployeeCode: '30017993', PatientName: 'Prateek Rai', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'SUPPLY CHAIN MANAGEMENT', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768615', EmployeeCode: '30103955', PatientName: 'Prince-', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'INFORMATION TECHNOLOGY', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768614', EmployeeCode: '30061318', PatientName: 'Soumya Ranjan Mishra', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'Energy Network Operation Centre', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768613', EmployeeCode: '30058789', PatientName: 'Arpan Joarder', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'Energy Network Operation Centre', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768612', EmployeeCode: '30018218', PatientName: 'Vinit Shah', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'CEOs Office', Cause: 'Sickness', EmployeeType: 'Regular' },
    { OPDNumber: 'OPD-2023070768611', EmployeeCode: '30099736', PatientName: 'Balasubramanyam', UserName: 'Dinesh Saini', Relation: 'SELF', Department: 'PROJECTS', Cause: 'Sickness', EmployeeType: 'Regular' },
];

const styles = {
    TableRow: {
        th: {
            p: 2,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 2,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        Patient: '',
        Function: '',
        DepartmentGroup: '',
        Department: '',
        VisitPurpose: '',
        Designation: '',
        EmployeeType: '',
        Grade: '',
        JobTitle: '',
        Gender: '',
        Company: '',
        Contractor: '',
        Status: '',
        PatientType: '',
        business: '',
        Subbusiness: '',
        CompanyGroup: '',
        CompanyName: '',
        FunctionName: '',
        DepartmentGroupType: '',
        DepartmentType: '',
        VisitPurposeName: '',
        DesignationName: '',
        EmployeeTypeGroup: '',
        GradeType: '',
        JobTitleType: '',
        GenderName: '',
        ContractorName: '',
        ContractorNameType: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);
    const handleChanges = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    OPD List
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Card sx={{ m: 2, ml: 2, mt: 8 }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChanges} aria-label="basic tabs example">
                        <Tab label="OPD List" sx={{ ml: 5, color: '#1976d2', textTransform: 'none' }}{...a11yProps(0)} />
                        <Tab label="OPD Custom Search" sx={{ color: '#1976d2', textTransform: 'none' }} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>OPD Number</Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='OPD Number'
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Patient</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Patient"
                                        value={state.Patient}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Function</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Function"
                                        value={state.Function}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Group</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DepartmentGroup"
                                        value={state.DepartmentGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Department"
                                        value={state.Department}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Visit Purpose</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="VisitPurpose"
                                        value={state.VisitPurpose}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Designation</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Designation"
                                        value={state.Designation}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeType"
                                        value={state.EmployeeType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Grade</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Grade"
                                        value={state.Grade}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Job Title</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="JobTitle"
                                        value={state.JobTitle}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Gender</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Gender"
                                        value={state.Gender}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Company</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Company"
                                        value={state.Company}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Contractor</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Contractor"
                                        value={state.Contractor}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Status</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Status"
                                        value={state.Status}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                <Box >Employee Code</Box>
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Employee Code'
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <SearchIcon />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Name</Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Name'
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Doctor</Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Doctor Name'
                                />
                            </Grid>
                        </Grid>

                    </Grid>


                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mt: 3 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>
                    </Stack>





                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1.5,
                                mt: 5,
                                mb: 3,
                                width: "100%",
                            },
                        }}
                    >

                        <Card variant="outlined" sx={{ ml: 2, mr: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <CardContent sx={{ pb: '0px !important' }}>
                                        <Grid container spacing={2} alignItems="center" >
                                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                                <TextField
                                                    id="outlined-number"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    defaultValue="10"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pages</Typography>
                                                <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export < ArrowDropDownIcon /></Button>
                                            </Grid>
                                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                                <TextField
                                                    required
                                                    id="filled-search"
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ pt: '0px !important' }}>
                                    <CardContent sx={{ pt: '5px !important' }}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>
                                                            <Box sx={{ p: 0 }} />
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>OPD Number<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Employee<br /> Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Patient Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>User <br />Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Relation<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>Cause<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }}>EmployeeType<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows.map((row) => (
                                                        <StyledTableRow key={row.OPDNumber} sx={styles.TableRow}>
                                                            <StyledTableCell>
                                                                <Box sx={{ color: '#1976d2' }}>Select</Box>
                                                            </StyledTableCell>
                                                            <StyledTableCell> {row.OPDNumber} </StyledTableCell>
                                                            <StyledTableCell>{row.EmployeeCode}</StyledTableCell>
                                                            <StyledTableCell>{row.PatientName}</StyledTableCell>
                                                            <StyledTableCell>{row.UserName}</StyledTableCell>
                                                            <StyledTableCell>{row.Relation}</StyledTableCell>
                                                            <StyledTableCell>{row.Department}</StyledTableCell>
                                                            <StyledTableCell>{row.Cause}</StyledTableCell>
                                                            <StyledTableCell>{row.EmployeeType}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Card>

                    </Box>

                </CustomTabPanel>


                <CustomTabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>OPD Number </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='OPD Number '
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Patient Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="PatientType"
                                        value={state.PatientType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>



                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>business</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="business"
                                        value={state.business}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Business</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sub Business</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Subbusiness"
                                        value={state.Subbusiness}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Sub Business</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Company Group</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="CompanyGroup"
                                        value={state.CompanyGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Company Group</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Company Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="CompanyName"
                                        value={state.CompanyName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Company</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Function Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="FunctionName"
                                        value={state.FunctionName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Function</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Group Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DepartmentGroupType"
                                        value={state.DepartmentGroupType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Department Group</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Department Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DepartmentType"
                                        value={state.DepartmentType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Department</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Visit Purpose Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="VisitPurposeName"
                                        value={state.VisitPurposeName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Visit Purpose</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Designation Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DesignationName"
                                        value={state.DesignationName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Designation</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Employee Type Group</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeTypeGroup"
                                        value={state.EmployeeTypeGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Employee Type</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Grade Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="GradeType"
                                        value={state.GradeType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Grade</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Job Title Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="JobTitleType"
                                        value={state.JobTitleType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Job Title</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Gender Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="GenderName"
                                        value={state.GenderName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Contractor Name</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="ContractorName"
                                        value={state.ContractorName}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '40px', color: 'grey' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>Select Contractor</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}> </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Contractor Name Type</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="ContractorNameType"
                                        value={state.ContractorNameType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-ALL-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Employee Code'
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <SearchIcon />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Name </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Name '
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Doctor </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Doctor Name '
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mt: 3 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>
                    </Stack>

                </CustomTabPanel>

            </Card>



        </>
    );
}