import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Paper,
    Table,
    CardContent,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    Button,
    styled,
    Toolbar,
    TextField,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    FormControl,
    SearchIcon,
    Slide,
    PropTypes,
    useScrollTrigger,
    HighlightOffIcon,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const style = {
    tableRow: {
        th: {
            svg: {
                float: 'right'
            }
        }
    }
}



export default function DenseAppBar(props) {

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    First Aid Inspection
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 0, ml: 1.5, mt: 6,
                        width: "98.4%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Inspection No</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Inspection No'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Employee Code'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Inspection From Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Inspection To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center' }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>
                </Card>
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 1.5,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue="10"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>entries</Typography>
                                <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export </Button>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <TableContainer component={Paper} sx={[style.tableRow, { mb: 1, ml: 2, width: '98%' }]} >
                        <Table aria-label="customized table" sx={{ mr: .1 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Inspection Number <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Employee Code <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Employee Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Inspection Date <UnfoldMoreOutlinedIcon />  <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>No data available in table</Typography>
                        </Grid>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, ml: 2, mr: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Showing 0 to 0 of 0 entries</Typography>
                        <Grid item>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1 }} disabled>Previous</Button>
                            <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                        </Grid>
                    </Grid>
                </Card>

            </Box>
        </>
    )
}