import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const getAccidentWithoutInjury = async (parmas) => {
    try {
      const { page, per_page, globalFilter, sorting, filters } = parmas;
      function buildQueryString(page, per_page, globalFilter, sorting, ) {
        const params = [`page=${page}`, `per_page=${per_page}`];
  
        if (globalFilter) {
          params.push(`globalFilter=${globalFilter}`);
        }
        if (sorting) {
          params.push(`sorting=${JSON.stringify(sorting)}`);
        }
        if (filters) {
          params.push(`filters=${JSON.stringify(filters)}`);
        }
        return params.join("&");
      }
      const query = buildQueryString(page, per_page, globalFilter, sorting, filters);
      const url = `${ApiConfig.getAccidentWithoutInjury}?${query}`;
  
      const res = await api.get(url);
      return res?.data;
    } catch (error) {
      return error;
    }
  }
