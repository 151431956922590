import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { InsertCreateUserRole, GetUserRole, DeleteUserRole, SingleList, UpdateUserRole } from '../../../../api/userRole';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSnackbar } from 'notistack';
import PaginationControls from '../../../Comman/paginationControls';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import styles from 'css/styles';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar'; 
const {
    React,
    Box,
    Grid,
    Paper,
    Table,
    Button,
    Toolbar,
    AppBar,
    Dialog,
    TableRow,
    TextField,
    TableBody,
    TableHead,
    Typography,
    DialogTitle,
    TableContainer,
    DialogContent,
    DialogActions,
    AddCircleOutlineOutlinedIcon,
} = sharedImports;

export default function UserRole() {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
    });

    const [isEditing, setIsEditing] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);

    //table state
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });  

    const handleClickOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const SingleListData = async (id) => {
        try {
          const response = await SingleList(id);     
          setFormData(response.data);
          setIsEditing(true);
          setOpen(true);
        } catch (error) {
          console.error('Error fetching job data:', error);
        }
    };
    
    const insertUpdateData = async (formData) => {        
        try {
            let response;
            if (isEditing) {
                response = await UpdateUserRole(formData);
            } else {
                const newData = { ...formData };                
                response = await InsertCreateUserRole(newData);
            }
            
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setOpen(false);
                fetchInfo();
            } else { 
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }

    const fetchInfo = async () => {
        try {
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;

            const parmas = {
                page: page,
                per_page: per_page,
            }
            const response = await GetUserRole(parmas);
            if (response.status) {
                const { data, totalCount } = await response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    const handleDeleteRow = async (id) => {
        try {
            const response = await DeleteUserRole(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchInfo();
                setData((prevData) => prevData.filter((item) => item.id !== id));
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [pagination.pageIndex, pagination.pageSize]);

    return (
        <Box sx={styles.root}>
            <CommanAppBarWithAddButton appBarTitle="User Role" handleClickOpen={handleClickOpen}/>

            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>
                                                Id
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Name
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.user_role_id} sx={styles.TableRow}>
                                                    <StyledTableCell> {index + 1} </StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <EditOutlinedIcon onClick={() => SingleListData(row.user_role_id)} />
                                                        <DeleteButton id={row.user_role_id} fieldName={row.name} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.user_role_id)} titleName="User Role" />
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>{isEditing ? 'Update' : 'Add'} User Role</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="Role Name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        fullWidth
                        sx={{ width: '500px' }}
                        variant="standard"

                    />

                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                    <Button sx={{ textTransform: 'none' }} type="save" onClick={handleSubmit}>{isEditing ? 'Update' : 'Save'}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}