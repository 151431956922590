// api.js
import axios from "axios";
import { ApiConfig } from "./ApiConfig";
const api = axios.create({
  baseURL: ApiConfig.baseUrl,
});
const fileApi = axios.create({
  baseURL: ApiConfig.baseUrl,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("app-token");
    config.headers["Content-Type"] = "application/json";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// formdata interceptor

fileApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("app-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, fileApi };
