import { ApiConfig } from "api/config/ApiConfig";
import { fileApi } from "api/config/axiosInterceptor";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeWorkLocation = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeWorkLocation = async (props) => {
  const url = `${ApiConfig.employeeWorkLocationMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_work_location_id) => {
  const url = `${ApiConfig.employeeWorkLocationMain}?id=${employee_work_location_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeWorkLocation = async (props) => {
  const url = `${ApiConfig.employeeWorkLocationMain}?id=${props.employee_work_location_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeWorkLocation = async (employee_work_location_id) => {
    const url = `${ApiConfig.employeeWorkLocationMain}?id=${employee_work_location_id}`;
    const response = await api.delete(url);
    return response.data;
};

export const importLocation = async (formData) => { 
  try {
    const url = `${ApiConfig.importLocation}`;
    const response = await fileApi.post(url, formData);
    return response.data;
  } catch (err) {
    return err;
  }
};
