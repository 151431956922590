import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Grid,
    Card,
    CardContent,
    LocalizationProvider,
    Select,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    Button,
    Toolbar,
    Slide,
    Stack,
    PropTypes,
    Checkbox,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    useScrollTrigger,
    HighlightOffIcon,
    FormControlLabel,
} = sharedImports;



const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    color: {
        color: 'red',
        border: '1px solid lightgray',
        padding: '10px',
    },

}



export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        FirstAid: '',
        Detail: '',
        IssueType: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Issue Bulk
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1} direction="row" sx={{ m: 1 }} >

                                    <Button variant="contained" sx={{ textTransform: 'none', m: 1, backgroundColor: '#0e559d' }}  >
                                        Issue
                                    </Button>

                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                                        Clear
                                    </Button>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1, ml: 2, mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid item xs={12} md={3}>
                        <Box variant="soft" sx={[styles.color, { paddingLeft: '30px', borderRadius: 2 }]}>
                            Notes: Select IsMultiple Checkbox for Issuing common medicines to multipe substores(First Aid,Ambulance,Other stock location)
                        </Box>
                    </Grid>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Issued Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Issued By</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Mayur Jayesh Trivedi'
                                    />
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Is Multiple"
                                    labelPlacement="end"
                                    sx={{ mt: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>First Aid <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="FirstAid"
                                            value={state.FirstAid}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Detail <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Detail"
                                            value={state.Detail}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{ height: '40px', color: 'grey' }}

                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Select Columns</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Issue Type <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="IssueType"
                                            value={state.IssueType}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Box>

        </>

    )
}