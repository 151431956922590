import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    DatePicker,
    DeleteOutlineIcon,
    Typography,
    AdapterDayjs,
    Select,
    DemoItem,
    EditNoteOutlinedIcon,
    CssBaseline,
    FormControl,
    LocalizationProvider,
    useScrollTrigger,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetRestorationCreate(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Asset Restoration
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button>
                                        <Button variant='contained' startIcon={<EditNoteOutlinedIcon />} sx={{ m: 1 }}> Edit </Button>
                                        <Button variant='outlined' startIcon={<DeleteOutlineIcon />} sx={{ m: 1 }}> Delete </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}> Clear </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Vendor <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>BP Instrument- First-aid room</MenuItem>
                                            <MenuItem value={2}>ECG</MenuItem>
                                            <MenuItem value={3}>Oxygen Concentrator</MenuItem>
                                            <MenuItem value={3}>Concentrator</MenuItem>
                                            <MenuItem value={3}>Infusion pump</MenuItem>
                                            <MenuItem value={3}>Thermal Gun</MenuItem>
                                            <MenuItem value={3}>Glucometer</MenuItem>
                                            <MenuItem value={3}>Two para oximeter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Challan Number</Box>
                                    <TextField
                                        size='small'
                                        placeholder='Challan Number'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Collection Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Yellow Bag (gm) {' '}
                                        <TextField
                                            value="#ffff00"
                                            size='small'
                                            InputProps={{
                                                type: "color",
                                                sx: {
                                                    width: '6ch',
                                                    '& input': {
                                                        padding: '3px'
                                                    },
                                                    '& img': {
                                                        display: 'none'
                                                    },
                                                }
                                            }}
                                        />
                                    </Box>
                                    <TextField
                                        size='small'
                                        placeholder='Yellow Bag (gm)'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Red Bag (gm) {' '}
                                        <TextField
                                            value="#ff0000"
                                            size='small'
                                            InputProps={{
                                                type: "color",
                                                sx: {
                                                    width: '6ch',
                                                    '& input': {
                                                        padding: '3px'
                                                    },
                                                    '& img': {
                                                        display: 'none'
                                                    },
                                                }
                                            }}
                                        />
                                    </Box>
                                    <TextField
                                        size='small'
                                        placeholder='Red Bag (gm)'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Blue Bag 1(gm){' '}
                                        <TextField
                                            value="#0000ff"
                                            size='small'
                                            InputProps={{
                                                type: "color",
                                                sx: {
                                                    width: '6ch',
                                                    '& input': {
                                                        padding: '3px'
                                                    },
                                                    '& img': {
                                                        display: 'none'
                                                    },
                                                }
                                            }}
                                        />
                                    </Box>
                                    <TextField
                                        size='small'
                                        placeholder='Blue Bag (gm)'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>White Bag 1(gm){' '}
                                        <TextField
                                            value="#ffffff"
                                            size='small'
                                            InputProps={{
                                                type: "color",
                                                sx: {
                                                    width: '6ch',
                                                    '& input': {
                                                        padding: '3px'
                                                    },
                                                    '& img': {
                                                        display: 'none'
                                                    },
                                                }
                                            }}
                                        />
                                    </Box>
                                    <TextField
                                        size='small'
                                        placeholder='White Bag (gm)'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>PPC (gm)</Box>
                                    <TextField
                                        size='small'
                                        placeholder='PPCgm'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Liquidwastegm</Box>
                                    <TextField
                                        size='small'
                                        placeholder='Liquid Waste gm'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Amount</Box>
                                    <TextField
                                        size='small'
                                        placeholder='Amount'
                                        InputProps={{
                                            type: "number",
                                            sx: {
                                                '& input': {
                                                    padding: '5px'
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none'
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield'
                                                },
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Remark</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}