import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeDepartment = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeDepartment = async (props) => {
  const url = `${ApiConfig.employeeDepartmentUrl}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_department_id) => {
  const url = `${ApiConfig.employeeDepartmentUrl}?id=${employee_department_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeDepartment = async (props) => {
  const url = `${ApiConfig.employeeDepartmentUrl}?id=${props.employee_department_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeDepartment = async (employee_department_id) => {
    const url = `${ApiConfig.employeeDepartmentUrl}?id=${employee_department_id}`;
    const response = await api.delete(url);
    return response.data;
};