import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeGroup = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeGroup = async (props) => {
  const url = `${ApiConfig.employeeGroupMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_group_id) => {
  const url = `${ApiConfig.employeeGroupMain}?id=${employee_group_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeGroup = async (props) => {
  const url = `${ApiConfig.employeeGroupMain}?id=${props.employee_group_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeGroup = async (employee_group_id) => {
    const url = `${ApiConfig.employeeGroupMain}?id=${employee_group_id}`;
    const response = await api.delete(url);
    return response.data;
};
