import { useEffect, useState } from 'react';
import sharedImports from 'component/Comman/sharedImports';
import { InsertLanguage, GetLanguage } from '../../../api/changeLanguage';
import styles from 'css/styles';
import { useSnackbar } from 'notistack';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar';
const {
    React,
    Box,
    Grid,
    Button,
    TextField
} = sharedImports;


export default function DiagnosticLanguage() {
    const [formData, setFormData] = useState({
        headingKey: 'hr_language',
        headingValue: {}
    });
    const { enqueueSnackbar } = useSnackbar();
    const fields = [
        { label: 'Examination Type', name: 'diagnostic_examination_type', placeholder: 'Examination Type' },
        { label: 'Round', name: 'diagnostic_round', placeholder: 'Round' },
        { label: 'Department', name: 'diagnostic_department', placeholder: 'Department' },
        { label: 'Doctor', name: 'diagnostic_doctor', placeholder: 'Doctor' },
        { label: 'Test Status', name: 'diagnostic_test_status', placeholder: 'Test Status' },
        { label: 'Test', name: 'diagnostic_test', placeholder: 'Test' },
        { label: 'From Date', name: 'diagnostic_from_date', placeholder: 'From Date' },
        { label: 'To Date', name: 'diagnostic_to_date', placeholder: 'To Date' },
        { label: 'Employee Code', name: 'diagnostic_employee_code', placeholder: 'Employee Code' },
        { label: 'Employee Name', name: 'diagnostic_employee_name', placeholder: 'Employee Name' },
        { label: 'Target Group', name: 'diagnostic_target_group', placeholder: 'Target Group' },
        { label: 'Department Group', name: 'diagnostic_department_group', placeholder: 'Department Group' },
        { label: 'Report', name: 'diagnostic_report', placeholder: 'Report' },
        { label: 'View Mode', name: 'diagnostic_view_mode', placeholder: 'View Mode' },
        { label: 'Service', name: 'diagnostic_service', placeholder: 'Service' },
        { label: 'Included Zero', name: 'diagnostic_included_zero', placeholder: 'Included Zero' }
    ]

    const getData = async () => {
        try {
            const parmas = { headingKey: 'diagnostic_language' }
            const response = await GetLanguage(parmas);
            if (response.status) {
                const { data } = response;
                //console.log(typeof data);
                const formData = { headingKey: 'diagnostic_language', headingValue: {} };
                Object.keys(data).forEach((key) => {
                    formData.headingValue[key] = data[key];
                });
                console.log('formData == ', formData);

                setFormData(formData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            headingValue: { ...prevFormData.headingValue, [name]: value }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        insertData(formData);
    };

    const insertData = async (formData) => {
        try {
            const newData = { ...formData };
            const response = await InsertLanguage(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            console.log('error', error);

            enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
    }
    return (
        <Box sx={styles.root}>
            <CommanAppBarWithAddButton appBarTitle="Diagnostic Managment" />
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>

                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Original Headings</Box>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Change Headings</Box>
                        </Grid>
                    </Grid>

                    {fields.map((field, index) => (
                        <Grid container spacing={2}>
                            <React.Fragment key={index}>
                                <Grid item xs={4} md={4}>
                                    <Box sx={{ fontWeight: 'bold' }}>{field.label}</Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField
                                        required
                                        id={`filled-${field.name}`}
                                        fullWidth
                                        size='small'
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        value={formData.headingValue[field.name] || ''}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    ))}
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}