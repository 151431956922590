import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Slide,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Dialog,
    styled,
    Button,
    Toolbar,
    DialogActions,
    DialogTitle,
    TableRow,
    TableHead,
    TableCell,
    TextField,
    Typography,
    PropTypes,
    SearchIcon,
    CardContent,
    DialogContent,
    ArrowBackIcon,
    DialogContentText,
    TableContainer,
    HighlightOffIcon,
    useScrollTrigger,
    tableCellClasses,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;



const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

const style = {
    tableRow: {
        th: {
            svg: {
                float: 'right'
            }
        }
    }
}

export default function DenseAppBar(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={11}>
                            </Grid>

                            <Grid item xs={1}>
                                <React.Fragment>
                                    <Button
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        Back
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        ml: 4,
                        mr: 4,
                        marginTop: '55px',
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >First Name</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='First Name'
                                    />
                                </Grid>

                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >Middle Name</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Middle Name'
                                    />
                                </Grid>

                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >Last Name</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Last Name'
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >Speciality</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Speciality'
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                    </CardContent>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>
                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        ml: 4,
                        mr: 4,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1">Show</Typography>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue="10"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1">Pages</Typography>
                                <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export </Button>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <Typography variant="body1">Search:</Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <TableContainer component={Paper} sx={[style.tableRow, { mb: 2, ml: 2, width: '98%' }]} >
                        <Table aria-label="customized table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>First Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Last Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Speciality <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >InActive <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1">No data available in table</Typography>
                        </Grid>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, ml: 2 }}>
                        <Typography variant="body1" >Showing 0 to 0 of 0 entries</Typography>
                        <Grid item>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1 }} disabled>Prev</Button>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 2 }} disabled>Next</Button>
                        </Grid>
                    </Grid>
                </Card>

            </Box>

        </>
    )
}