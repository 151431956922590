import React, { useState, useEffect } from 'react';
import ExpenseForm from '../Expense/from';
import { useParams } from 'react-router-dom';
import { SingleList } from '../../../../api/expense';

const UpdateExpense = () => {
  const { id } = useParams();
  
  const [expenseData, setExpenseData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SingleList(id);
      setExpenseData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (expenseData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <ExpenseForm expenseId={id} expenseData={expenseData} />
    </>
  );
};

export default UpdateExpense;
