import ExpenseForm from '../Expense/from';

const ExpenseCreate = () => {
    return (
    <>
      <ExpenseForm />
    </>  
  )
}

export default ExpenseCreate
