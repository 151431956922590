import { ApiConfig } from "./config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const InsertCreateUserPermission = async (props) => {
  const url = `${ApiConfig.userPermissionUrl}`;
  const response = await api.post(url, props);
  return response.data;
};

export const GetAllUserPermission = async () => {  
  const url = `${ApiConfig.userPermissionUrl}`;
  const response = await api.get(url);
  return response.data;
};
