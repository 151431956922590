import { SendGetRequest } from "../../../../api/config/ApiCall";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";

const styles = {
  firstForm: {
    padding: "16px 16px 34px 16px",
    backgroundColor: "#f1f1f1",
  },
  bgWhite: {
    backgroundColor: "white",
    color: "black",
  },
  wFull: {
    width: "100%",
  },
  marginBottom2: {
    marginBottom: 2,
    mt: 2,
  },
  TableRow: {
    th: {
      width: "12.5%",
      svg: {
        float: "right",
        color: "#1976d2",
      },
    },
    td: {
      border: "1px solid lightgray",
    },
  },
  h: {
    minHeight: "40px !important",
  },
  p20: {
    padding: "20px",
  },
  bRadius: {
    borderRadius: "5px",
    marginTop: "45px",
  },
  dFlex: {
    display: "flex",
  },
  justifySB: {
    justifyContent: "space-between",
  },
  alignC: {
    alignItems: "center",
  },
};

const getDropDownlist = async (id, setSetter, method) => {
  try {
    const response = await SendGetRequest("getReportFilter", { id });
    if (response.status) {
      // console.log(response.data.data);
      setSetter((prev) => ({ ...prev, [method]: response.data.data }));
    }
  } catch (error) { }
};

const getEmployeeField = async (setSetter, method) => {
  try {
    const response = await SendGetRequest("getAllInputJobDetailData", {});
    if (response.status) {
      method.forEach((element) => {
        setSetter((prev) => ({ ...prev, [element]: response.data[element] }));
      });
    }
  } catch (error) {
    console.log(error);
  }
};
const getEmployeeType = async (setSetter, method, parmas, apiUrl) => {
  try {
    const response = await SendGetRequest(apiUrl, parmas);
    if (response.status) {
      setSetter((prev) => ({ ...prev, [method]: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
};
const columns = [
  "",
  "Location",
  "",
  "",
  "",
  "",
  "Code",
  "Employee Name",
  "",
  "Email",
  "",
  "",
  "Mobile No.",
  "Training Date",
  "Count",
];
const baseStyles = { fontSize: 8, lineWidth: 0, cellPadding: 2 };
const centerAlign = { halign: "center", cellPadding: 3 };
const columnWidths = {
  location: 70,
  filterGroup: 20,
  code: 50,
  name: 70,
  email: 80,
  mobile: 60,
  trainingDate: 50,
  count: 40,
};
const initialData = {
  report: "",
  viewMode: "",
  filter: "",
  employeeTypeGroup: "",
  employeeGroup: "",
  employeeType: "",
  employeeSubType: "",
  function: "",
  departmentGroup: "",
  employeeDepartment: "",
  employeeGrade: "",
  designation: "",
  jobTitle: "",
  employeeCompany: "",
  contractor: "",
  gender: "",
  status: "",
  location: "",
  employeeJobTitle: "",
  employeeWorkLocation: "",
};
const generateExcel = (data) => {
  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Employee Data");

  // Define header row and columns
  const columns = [
    { header: "Location", key: "location", width: 20 },
    { header: "Blood Group", key: "filterGroup", width: 15 },
    { header: "Code", key: "code", width: 10 },
    { header: "Name", key: "name", width: 20 },
    { header: "Email", key: "email", width: 30 },
    { header: "Mobile", key: "mobile", width: 15 },
    { header: "Training Date", key: "trainingDate", width: 15 },
    { header: "Employee Count", key: "employeeCount", width: 15 },
  ];

  worksheet.columns = columns;

  // Apply header styles
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFF" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "BEBEBE" },
    };
    cell.alignment = { horizontal: "center", vertical: "center" };
  });

  let row = 2; // Start after header row

  data.forEach((loc) => {
    // Add location row with styling
    worksheet.addRow({
      location: loc.location,
      filterGroup: "",
      code: "",
      name: "",
      email: "",
      mobile: "",
      trainingDate: "",
      employeeCount: "",
    });
    worksheet.getRow(row).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D2D2D2" },
      };
      cell.alignment = { horizontal: "center", vertical: "center" };
    });
    row++;

    loc.filterGroups.forEach((bg) => {
      // Add blood group row with styling
      worksheet.addRow({
        location: "",
        filterGroup: bg.filterGroup,
        code: "",
        name: "",
        email: "",
        mobile: "",
        trainingDate: "",
        employeeCount: bg.employees.length,
      });
      worksheet.getRow(row).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F0F0F0" },
        };
        cell.alignment = { horizontal: "center", vertical: "center" };
      });
      row++;

      bg.employees.forEach((e) => {
        // Add employee details row with styling
        worksheet.addRow({
          location: "",
          filterGroup: "",
          code: e.code,
          name: e.name,
          email: e.email,
          mobile: e.mobile,
          trainingDate: e.trainingDate,
        });
        worksheet.getRow(row).eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "center" };
        });
        row++;
      });
    });
  });

  // Download the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "report.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  });
};

const generatePDF = (data) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "px",
    format: "a4",
    margins: { top: 0, right: 0, bottom: 0, left: 0 },
    pageMargins: [0, 0, 0, 0],
  });

  let y = 15;

  // Draw the header only on the first page
  doc.autoTable({
    head: [columns],
    startY: y,
    theme: "plain",
    headStyles: { ...baseStyles, fillColor: [190, 190, 190], ...centerAlign },
    styles: { ...baseStyles, fillColor: [210, 210, 210], ...centerAlign },
    columnStyles: Object.fromEntries(
      Object.entries(columnWidths).map(([k, v], i) => [i, { cellWidth: v }])
    ),
    margin: { left: 4, right: 0 },
  });
  y = doc.lastAutoTable.finalY + 2;

  data.forEach((loc) => {
    // Location header
    doc.autoTable({
      body: [[loc.location, ...Array(7).fill("")]],
      startY: y,
      theme: "plain",
      styles: { ...baseStyles, fillColor: [210, 210, 210], ...centerAlign },
      columnStyles: Object.fromEntries(
        Object.entries(columnWidths).map(([k, v], i) => [i, { cellWidth: v }])
      ),
      margin: { left: 4, right: 0 },
    });
    y = doc.lastAutoTable.finalY + 2;

    // Blood groups
    loc.filterGroups.forEach((bg) => {
      doc.autoTable({
        body: [["", bg.filterGroup, ...Array(5).fill(""), bg.employees.length]],
        startY: y,
        theme: "plain",
        styles: { ...baseStyles, fillColor: [240, 240, 240] },
        columnStyles: {
          1: { ...centerAlign, cellWidth: columnWidths.filterGroup },
          7: { ...centerAlign, cellWidth: columnWidths.count },
        },
        margin: { left: 4, right: 0 },
      });
      y = doc.lastAutoTable.finalY + 1;
      // Employee details
      doc.autoTable({
        body: bg.employees.map((e) => [
          "",
          "",
          e.code,
          e.name,
          e.email,
          e.mobile,
          e.trainingDate,
          "",
        ]),
        startY: y,
        theme: "plain",
        styles: baseStyles,
        columnStyles: {
          0: { cellWidth: columnWidths.location, ...centerAlign },
          1: { cellWidth: columnWidths.filterGroup, ...centerAlign },
          2: { cellWidth: columnWidths.code, ...centerAlign },
          3: { cellWidth: columnWidths.name, ...centerAlign },
          4: { cellWidth: columnWidths.email, ...centerAlign },
          5: { cellWidth: columnWidths.mobile, ...centerAlign },
          6: { cellWidth: columnWidths.trainingDate, ...centerAlign },
          7: { cellWidth: columnWidths.count, ...centerAlign },
        },
        margin: { left: 4, right: 0 },
      });
      y = doc.lastAutoTable.finalY + 2;
    });
  });

  // window.open(doc.output('bloburl'), '_blank');
  doc.save("report.pdf");
};

export {styles,getDropDownlist,getEmployeeField,getEmployeeType,initialData,generateExcel,generatePDF};