import React, {useRef} from 'react';
import { CommanAppBar } from 'component/Comman/commanAppBar';
import { UploadDocument } from 'api/import-document';
import { useSnackbar } from "notistack";
import sharedImports from '../../../Comman/sharedImports';
const {
    Box,
    Grid,
    Alert,
    Select,
    TextField,
    MenuItem,
    Checkbox,
    FormControl,
    FormControlLabel,
    Button,
    ControlPointOutlinedIcon,
} = sharedImports;

export default function ImportDocument(props) {
    const fileInputRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = React.useState({
        examination_type: '',
        store_location: '',
        round: '',
        employee_code: '',
        document: null, // Add document to formData state
    });

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        if (name === 'document') {
            setFormData(prevState => ({
                ...prevState,
                document: files // Store the selected files array
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    
    const documentFormData = new FormData();
    const handleOnSubmit = async () => {
        try {
            // Append formData fields to documentFormData
            Object.keys(formData).forEach(key => {
                if (key === 'document') {
                    // Append each file separately
                    Array.from(formData[key]).forEach(file => {
                        documentFormData.append('document', file);
                    });
                } else {
                    documentFormData.append(key, formData[key]);
                }
            });
    
            const response = await UploadDocument(documentFormData);
    
            if (response && response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setFormData({
                    examination_type: '',
                    store_location: '',
                    round: '',
                    employee_code: '',
                    document: null,
                });
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; 
                }
            } else {
                const errorMessage = response?.response?.data?.message || response.message;
                enqueueSnackbar(errorMessage, { variant: "warning" });
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Import Document" showButtons={false} />
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray', mb: 2 }}>
                            You can import employee's document from here
                            <br />
                            Note : If employee code is not selected, then it is mandatory to name the document file as employee code (33012374) or with underscore (33012374_filename).
                            <br />
                            Note : Inorder to deny multiple document files for a single employee, it is mandatory to check in Deny Multiple Files check box.
                        </Alert>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="examination_type"
                                                value={formData.examination_type}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Store Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="store_location"
                                                value={formData.store_location}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Uttar Pradesh</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="round"
                                                value={formData.round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>--All--</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            name="employee_code"
                                            value={formData.employee_code}
                                            onChange={handleChange}
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Employee Code"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        name="document"
                                        onChange={handleChange}
                                        multiple // Allow multiple file selection
                                        ref={fileInputRef} // Add the reference here
                                    />
                                    <label htmlFor="contained-button-file"></label>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel control={<Checkbox />} label="Deny Multiple Files" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleOnSubmit()}
                                        startIcon={<ControlPointOutlinedIcon />}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}