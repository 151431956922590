import { useEffect, useState } from 'react';
import { ApiConfig } from 'api/config/ApiConfig';
import { useSnackbar } from 'notistack';
import { GetEmployeeWorkLocation, InsertCreateEmployeeWorkLocation, SingleList, UpdateEmployeeWorkLocation, DeleteEmployeeWorkLocation } from 'api/employee-master-setup/employeeWorkLocation';
import PaginationControls from 'component/Comman/paginationControls';
import sharedImports from 'component/Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import styles from 'css/styles';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import {CommanAppBar} from 'component/Comman/commanAppBar';
const {
    React,
    Box,
    Grid,
    Button,
    TextField,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
} = sharedImports;

export default function EmployeeWorkLocationMaster() {
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        employee_work_location_id: '',
        code: '',
        name: '',
        inactive: '',
    });
    
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { baseUrl, listAllEmployeeWorkLocation } = ApiConfig;

    const fetchInfo = async () => {
        try{
            const page=pagination.pageIndex+1;
            const per_page=pagination.pageSize;
            
            const url = new URL(
            listAllEmployeeWorkLocation,
            baseUrl,
            );
            url.searchParams.set(
            'page',
            `${page}`,
            );
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            const response = await GetEmployeeWorkLocation(url);
            if (response.status) {
                const { data, totalCount } = response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.employee_work_location_id === columnId);            
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ employee_work_location_id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ employee_work_location_id: columnId, desc: false }];
            }
        });
        
        fetchInfo();
    };

    const handleClickOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const insertUpdateData = async (formData) => {      
        try {
            let response;
            if (isEditing) {
                response = await UpdateEmployeeWorkLocation(formData);
            } else {
                const newData = { ...formData };                
                response = await InsertCreateEmployeeWorkLocation(newData);
            }

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setOpen(false);
                fetchInfo();
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }

        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }
    
    const SingleListData = async (id) => {
        try {
          const response = await SingleList(id);     
          setFormData(response.data);
          setIsEditing(true);
          setOpen(true);
        } catch (error) {
          console.error('Error fetching job data:', error);
        }
    };

    const handleDeleteRow = async (id) => {
        try {
            const response = await DeleteEmployeeWorkLocation(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchInfo();
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('An error occurred while deleting the user:', { variant: "error" });
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting])

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Employee Work Location Master" showButtons={true} handleClickOpen={handleClickOpen} backButtonPath="/dashboard/hr/employee-management/employee-master-setup"/>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>{isEditing ? 'Update' : 'Add'} Employee Work Location</DialogTitle>
                        <DialogContent>
                        <TextField
                                required
                                margin="dense"
                                id="code"
                                label="Code"
                                name="code"
                                value={formData.code}
                                onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name="name"
                                value={formData.name || ''}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.inactive === 1}
                                        onChange={(e) => setFormData(prev => ({ ...prev, inactive: e.target.checked ? 1 : 0 }))}
                                        name="inactive"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                            <Button sx={{ textTransform: 'none' }} type="save" onClick={handleSubmit}>{isEditing ? 'Update' : 'Save'}</Button>
                        </DialogActions>
                    </Dialog>

                    <Box sx={{ mt: 7, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.EmployeeMasterSetupTableRow}>
                                            <StyledTableCell onClick={() => handleSort('employee_work_location_id')}>
                                                ID
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('name')}>
                                                Name
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.assetTag} sx={styles.EmployeeMasterSetupTableRow}>
                                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <EditOutlinedIcon onClick={() => SingleListData(row.employee_work_location_id)} />
                                                        <DeleteButton id={row.employee_work_location_id} fieldName={row.name} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.employee_work_location_id)} titleName="Employee Work Location" />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}