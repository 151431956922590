import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeTargetGroup = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeTargetGroup = async (props) => {
  const url = `${ApiConfig.employeeTargetGroupMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_target_group_id) => {  
  const url = `${ApiConfig.employeeTargetGroupMain}?id=${employee_target_group_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeTargetGroup = async (props) => {
  const url = `${ApiConfig.employeeTargetGroupMain}?id=${props.employee_target_group_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeTargetGroup = async (employee_target_group_id) => {
  const url = `${ApiConfig.employeeTargetGroupMain}?id=${employee_target_group_id}`;
  const response = await api.delete(url);
  return response.data;
};
