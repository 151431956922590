import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Dialog,
    AppBar,
    styled,
    DeleteOutlineIcon,
    Button,
    Toolbar,
    TableContainer,
    Paper,
    Table,
    Tooltip,
    IconButton,
    TableHead,
    TableBody,
    TextField,
    MenuItem,
    Select,
    DatePicker,
    Typography,
    AdapterDayjs,
    DemoItem,
    CssBaseline,
    FormControl,
    DialogTitle,
    DialogContent,
    LocalizationProvider,
    useScrollTrigger,
    Checkbox,
    TableCell,
    DialogActions,
    DialogContentText,
    tableCellClasses,
    TableRow,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { srNo: 1, pantryName: 'Mahesh Kumar Bhavsar' },
    { srNo: 2, pantryName: 'NILESH PARMAR' },
    { srNo: 3, pantryName: 'NILESH PARMAR' },
    { srNo: 4, pantryName: 'NILESH PARMAR' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function PantryInspectionBulkCreate(props) {
    const [state, setState] = React.useState({
        Status: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Pantry Inspection Bulk Create
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Save </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                startIcon={<FormatListBulletedOutlinedIcon />}
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                                List
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Inspection Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, mb: 3, p: 0 }}>Pantry List</Box>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><Checkbox sx={{ color: 'gray' }} /></StyledTableCell>
                                            <StyledTableCell>Sr No</StyledTableCell>
                                            <StyledTableCell>Pantry Name</StyledTableCell>
                                            <StyledTableCell>Water Cooler</StyledTableCell>
                                            <StyledTableCell>Tea Vending Machine</StyledTableCell>
                                            <StyledTableCell>Fridge</StyledTableCell>
                                            <StyledTableCell>Oven</StyledTableCell>
                                            <StyledTableCell>Cleaning</StyledTableCell>
                                            <StyledTableCell>Total</StyledTableCell>
                                            <StyledTableCell>Obtain</StyledTableCell>
                                            <StyledTableCell>Percentage</StyledTableCell>
                                            <StyledTableCell>Pantry Inspection Status</StyledTableCell>
                                            <StyledTableCell>Remark</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                <StyledTableCell><Checkbox sx={{ color: 'gray' }} /></StyledTableCell>
                                                <StyledTableCell>{row.srNo}</StyledTableCell>
                                                <StyledTableCell>{row.pantryName}</StyledTableCell>
                                                <StyledTableCell><TextField id="filled-search" type="search" fullWidth /></StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="Status"
                                                            value={state.Status}
                                                            onChange={handleChange}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem sx={{ ml: 3 }} value="">
                                                                --Select--
                                                            </MenuItem>
                                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Delete">
                                                        <IconButton>
                                                            <DeleteOutlineIcon sx={{ color: '#1785e3' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}