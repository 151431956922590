import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeCompany = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeCompany = async (props) => {
  const url = `${ApiConfig.employeeCompanyMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_company_id) => {
  const url = `${ApiConfig.employeeCompanyMain}?id=${employee_company_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeCompany = async (props) => {
  const url = `${ApiConfig.employeeCompanyMain}?id=${props.employee_company_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeCompany = async (employee_company_id) => {
    const url = `${ApiConfig.employeeCompanyMain}?id=${employee_company_id}`;
    const response = await api.delete(url);
    return response.data;
};