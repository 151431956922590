import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeProcessType = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeProcessType = async (props) => {
  const url = `${ApiConfig.employeeProcessTypeMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_process_type_id) => {
  const url = `${ApiConfig.employeeProcessTypeMain}?id=${employee_process_type_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeProcessType = async (props) => {
  const url = `${ApiConfig.employeeProcessTypeMain}?id=${props.employee_process_type_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeProcessType = async (employee_process_type_id) => {
    const url = `${ApiConfig.employeeProcessTypeMain}?id=${employee_process_type_id}`;
    const response = await api.delete(url);
    return response.data;
};