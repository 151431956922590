import sharedImports from '../../../Comman/sharedImports';
import { GetHRListing } from "api/HRsection";
import PaginationControls from "component/Comman/paginationControls";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";
import { getAllInputJobDetailData } from '../../../../api/user';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Typography,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    Stack,
    SearchIcon,
    HighlightOffIcon,
    NoteAddIcon
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

const initalState = {
    location: "",
    pre_employee: "",
    FromDate: null,
    ToDate: null,
};

const PreEmploymentExample = (props) => {
    const [rows, setRows] = React.useState([]);
    const [state, setState] = React.useState(initalState);
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isApiHit, setIsApiHit] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(0);
    const [isSearched, setIsSearched] = React.useState(false);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [inputData, setInputData] = React.useState({
        employeeWorkLocation: [],
    });
    const getAllInputJobDetail = async () => {
        try {
            const response = await getAllInputJobDetailData();

            if (response.status) {
                const { data } = response;
                const stateFields = [
                    'employeeWorkLocation',
                ];

                // Dynamically setting state for each field
                const stateData = {};
                stateFields.forEach(field => {
                    stateData[field] = data[field] || [];
                });

                setInputData(stateData);

            }
        }
        catch (err) {
            console.error('Error fetching employment grades:', err);
        }

    }
    const fetchHRListing = React.useCallback(async () => {
        try {
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const params = {
                page,
                per_page,
                globalFilter,
            };
            const response = await GetHRListing(params);
            if (response.status) {
                setRows(response.data.users);
                setRowCount(response.data.total);
            } else {
                console.log(response.message);
            }
        } catch (err) {
            console.log(err);
        }
    }, [pagination, globalFilter]);
    React.useEffect(() => {
        fetchHRListing();
        getAllInputJobDetail();
    }, [fetchHRListing, isApiHit, pagination, globalFilter]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleTextChange = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    const onSearch = async () => {
        try {
            setIsSearched(true);
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const params = {
                page,
                per_page,
                globalFilter,
                ...state,
            };
            const response = await GetHRListing(params);
            if (response.status) {
                setRows(response.data.users);
                setRowCount(response.data.total);
            } else {
                console.log(response.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (isSearched) {
            onSearch();
        }
    }, [globalFilter]);

    const ClearFilter = async () => {
        setState(initalState);
        setIsApiHit(!isApiHit);
        setIsSearched(false);
    };

    const handleExport = () => {
        const selectedData = rows.map(row => ({
            Id: row.id,
            Name: row.name,
            Code: row.employeeCode,
            Contractor: row.jobDetails?.department || '', 
            "Employee Type": row.jobDetails?.employeeType || '',
            Location: row.workLocation,
            Department: row.jobDetails?.department || '',
            Company: row.jobDetails?.company || '',
            Status: row.status,
            dateOfJoining: row.jobDetails?.dateOfJoining 
                ? new Date(row.jobDetails.dateOfJoining).toLocaleDateString() 
                : '',
        }));
    
        // Convert the selected data to CSV
        const csv = Papa.unparse(selectedData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "preEmployment.csv");
    };
    
    const isSearchQueryDisabled = Object.values(state).every(
        (val) => val === "" || val === null
    );


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Pre-Employment Example Status
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DatePicker
                                                        name="FromDate"
                                                        value={state.FromDate}
                                                        onChange={(date) => handleTextChange("FromDate", date)}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DatePicker
                                                        name="ToDate"
                                                        value={state.ToDate}
                                                        onChange={(date) => handleTextChange("ToDate", date)}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="location"
                                                value={state.location}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {inputData.employeeWorkLocation.map((data, index) => (
                                                    <MenuItem sx={{ ml: 3 }} key={index} value={data.employee_work_location_id}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Pre Employee</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Filter"
                                                value={""}
                                                onChange={handleChange}
                                                displayEmpty
                                                disabled={true}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>
                                                    Ten
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={20}>
                                                    Twenty
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={30}>
                                                    Thirty
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={2} direction="row" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<SearchIcon />}
                                            disabled={isSearchQueryDisabled}
                                            onClick={onSearch}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<HighlightOffIcon />}
                                            disabled={!isSearched}
                                            onClick={ClearFilter}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<NoteAddIcon />}
                                            onClick={handleExport}
                                            disabled={rowCount === 0}

                                        >
                                            Export
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />} onClick={handleExport}
                                            disabled={rowCount === 0}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Contractor<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Company<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Status<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {rowCount ? (
                                            rows?.map((row) => (
                                                <StyledTableRow
                                                    key={row.temporaryCode}
                                                    sx={styles.TableRow}
                                                >
                                                    <StyledTableCell> {row.employeeCode} </StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.jobDetails.contractor}</StyledTableCell>
                                                    <StyledTableCell>{row.jobDetails.employeeType}</StyledTableCell>
                                                    <StyledTableCell>{row.workLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.jobDetails.department}</StyledTableCell>
                                                    <StyledTableCell>{row.jobDetails.company}</StyledTableCell>
                                                    <StyledTableCell>{row.status}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow sx={styles.TableRow}>
                                                <StyledTableCell colSpan={8} align="center">
                                                    No Records Found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}

export default PreEmploymentExample;