import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { ApiConfig } from '../../../../api/config/ApiConfig';
import { GetUser, DeleteUser } from '../../../../api/user';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import moment from 'moment';
import * as Papa from 'papaparse';
import { getAllInputJobDetailData } from '../../../../api/user';
const {
    React,
    Box,
    Grid,
    Select,
    Paper,
    Table,
    Stack,
    Slide,
    styled,
    Button,
    Toolbar,
    AppBar,
    PropTypes,
    TableRow,
    MenuItem,
    TextField,
    TableBody,
    FormControl,
    TableHead,
    SearchIcon,
    Typography,
    TableContainer,
    TableCell,
    useScrollTrigger,
    tableCellClasses,
    FileCopyOutlinedIcon,
    HighlightOffIcon,
    UnfoldMoreOutlinedIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function SearchAllEmployee(props) {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        Code: '',
        first_name: '',
        last_name: '',
        contractor: '',
        EmployeeType: '',
        Status: '',
        Department: '',
        Location: '',
        TargetGroup: '',
        Business: '',
        SubBusiness: '',
        CompanyGroup: '',
        Company: '',
        LegalEntity: '',
    });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    //table state
    const [sorting, setSorting] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isApiHit, setIsApiHit] = React.useState(false);
    const { baseUrl, listAllUser } = ApiConfig;

    const fetchInfo = React.useCallback(async () => {
        console.log('===============', globalFilter);
        
        try {
            const userId = localStorage.getItem("userID");
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;

            const url = new URL(
                listAllUser,
                baseUrl,
            );
            url.searchParams.set(
                'page',
                `${page}`,
            );
            url.searchParams.set('userID', userId);
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            url.searchParams.set('filters', JSON.stringify(filters ?? []));
            const response = await GetUser(url);

            if (response.status) {
                const { data, totalCount } = await response.data;
                setData(data);
                setRowCount(totalCount);
            }
            else {
                enqueueSnackbar(response.message, { variant: 'error' })
            }

        } catch (error) {
            enqueueSnackbar('An error occurred. Please try again later.', { variant: "error" })
        }

    }, [pagination])

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);

            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });

        fetchInfo();
    };

    const handleDelete = async (id) => {
        // try {
        //     const response = await DeleteUser(id);
        //     switch (response.status) {
        //         case "success":
        //             enqueueSnackbar(response.message, { variant: "success" });
        //             setData((prevData) => prevData.filter((item) => item.id !== id));
        //             break;
        //         case "failed":
        //             enqueueSnackbar(response.message, { variant: "warning" });
        //             break;
        //         case "error":
        //             enqueueSnackbar(response.message, { variant: "error" });
        //             break;
        //         default:
        //             break;
        //     }
        // } catch (error) {
        //     console.error('An error occurred while deleting the user:', error);
        // }

        setSelectedUserId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await DeleteUser(selectedUserId);
            switch (response.status) {
                case "success":
                    enqueueSnackbar(response.message, { variant: "success" });
                    setData((prevData) => prevData.filter((item) => item.id !== selectedUserId));
                    break;
                case "failed":
                    enqueueSnackbar(response.message, { variant: "warning" });
                    break;
                case "error":
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error('An error occurred while deleting the user:', error);
        } finally {
            setOpen(false); // Close the dialog after deletion
        }
    };


    const handleExport = () => {
        const customData = data.map((item) => ({
            "Employee Code": item?.code || '',
            "FirstName": item?.first_name || '',
            "MiddleName": item?.middle_name || '',
            "LastName": item?.last_name || '',
            "DateOfBirth": item?.date_of_birth ? moment(item.date_of_birth).format('DD/MM/YYYY') : '',
            "DateOfJoining": item.jobDetails?.[0]?.date_of_joining ? moment(item.jobDetails?.[0]?.date_of_joining).format('DD/MM/YYYY') : '',
            "Gender": item?.gender || '',
            "EmpTypeGroup": item.jobDetails?.[0]?.employee_type_group || '',
            "EmpType": item.jobDetails?.[0]?.employee_type || '',
            "EmpSubType": item.jobDetails?.[0]?.employee_sub_type || '',
            "Location": '',
            "Company": item.jobDetails?.[0]?.company || '',
            "Department": item.jobDetails?.[0]?.department || '',
            "Grade": item.jobDetails?.[0]?.grade || '',
            "Designation": item.jobDetails?.[0]?.designation || '',
            "TargetGroup": item.jobDetails?.[0]?.target_group || '',
            "ProcessType": item.jobDetails?.[0]?.process_type || '',
            "EmpGroup": item.jobDetails?.[0]?.employee_group || '',
            "EmpGroupCode": item.jobDetails?.[0]?.employee_group_code || '',
            "BloodGroup": item.personalDetails?.blood_group || '',
            "Address1": item.addressDetails?.local_address_1 || '',
            "Address2": item.addressDetails?.local_address_2 || '',
            "Address3": item.addressDetails?.local_address_3 || '',
            "City": item.addressDetails?.local_city_1 || '',
            "State": item.addressDetails?.local_state_1 || '',
            "Country": item.addressDetails?.local_country_1 || '',
            "MobileNo": item.communicationDetails?.mobile_number || '',
            "Email": item.communicationDetails?.company_email || '',
            "CompanyPhone": item.communicationDetails?.company_phone_no || '',
            "Status": '',
            "DateOfLeaving": item.jobDetails?.[0]?.date_of_leaving ? moment(item.jobDetails?.[0]?.date_of_leaving).format('DD/MM/YYYY') : '',
            "ReasonOfLeaving": item.jobDetails?.[0]?.reason_for_leaving || '',
            "Contractor": item.jobDetails?.[0]?.contractor || '',
            "ContractValidUpto": '',
            "ImportReferenceId": ''
        }));

        // Convert the modified data to CSV
        const csv = Papa.unparse(customData);

        // Create and save the CSV file
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'user.csv');
    };

    useEffect(() => {
        fetchInfo();
        getAllFilerInputValue();
    }, [fetchInfo, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, isApiHit]);

    const handleSearch = () => {
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
    };

    const handleClear = () => {
        setFilters({ Code: '', first_name: '', last_name: '', contractor: '', EmployeeType: '', Status: '', Department: '', Location: '', TargetGroup: '', Business: '', SubBusiness: '', CompanyGroup: '', Company: '', LegalEntity: '', });
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        setIsApiHit(!isApiHit);
    };

        /* Get all input values of Job Detail */
        const [inputData, setInputData] = useState({
            employeeCompany: [],
            employeeContractorSubBusiness: [],
            employeeDepartment: [],
            employeeDesignatedPosition: [],
            employeeGrade: [],
            employeeGroup: [],
            employeeLegalEntity: [],
            employeeProcessType: [],
            employeeQualification: [],
            employeeSkill: [],
            employeeSubType: [],
            employeeTargetGroup: [],
            employeeType: [],
            employeeTypeGroup: [],
            employeeWorkLocation: []
        });

    const getAllFilerInputValue = async () => {
        try {
            const response = await getAllInputJobDetailData();

            if (response.status) {
                const { data } = response;
                const stateFields = [
                    'employeeCompany',
                    'employeeContractorSubBusiness',
                    'employeeDepartment',
                    'employeeDesignatedPosition',
                    'employeeGrade',
                    'employeeGroup',
                    'employeeLegalEntity',
                    'employeeProcessType',
                    'employeeQualification',
                    'employeeSkill',
                    'employeeSubType',
                    'employeeTargetGroup',
                    'employeeType',
                    'employeeTypeGroup',
                    'employeeWorkLocation'
                ];

                // Dynamically setting state for each field
                const stateData = {};
                stateFields.forEach(field => {
                    stateData[field] = data[field] || [];
                });

                setInputData(stateData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            console.error('Error fetching employment grades:', err);
        }

    }

    const renderMenuItems = (data, labelKey = 'name', valueKey = 'name') => {
        return data.map(item => (
            <MenuItem sx={{ ml: 3 }} key={item[valueKey]} value={item[valueKey]}>
                {item[labelKey]}
            </MenuItem>
        ));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
            mt: 2
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        }
    }
    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.subHeader, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Search All Employee
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="Code"
                                    value={filters.Code}
                                    onChange={(e) => setFilters(prev => ({ ...prev, Code: e.target.value }))}
                                    placeholder="Code Like"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Name</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="first_name"
                                    value={filters.first_name}
                                    onChange={(e) => setFilters(prev => ({ ...prev, first_name: e.target.value }))}
                                    placeholder="Name Like"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Surname</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="last_name"
                                    value={filters.last_name}
                                    onChange={(e) => setFilters(prev => ({ ...prev, last_name: e.target.value }))}
                                    placeholder="Surname Like"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Contractor</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    name="contractor"
                                    value={filters.contractor}
                                    onChange={(e) => setFilters(prev => ({ ...prev, contractor: e.target.value }))}
                                    placeholder="Contractor"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeType"
                                        value={filters.EmployeeType}
                                        onChange={(e) => setFilters(prev => ({ ...prev, EmployeeType: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeTypeGroup)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Status"
                                        value={filters.Status}
                                        onChange={(e) => setFilters(prev => ({ ...prev, Status: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="active">Active</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value="inActive">In Active</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Department"
                                        value={filters.Department}
                                        onChange={(e) => setFilters(prev => ({ ...prev, Department: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeDepartment)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Location</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Location"
                                        value={filters.Location}
                                        onChange={(e) => setFilters(prev => ({ ...prev, Location: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeWorkLocation)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="TargetGroup"
                                        value={filters.TargetGroup}
                                        onChange={(e) => setFilters(prev => ({ ...prev, TargetGroup: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeTargetGroup)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Business</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Business"
                                        value={filters.Business}
                                        onChange={(e) => setFilters(prev => ({ ...prev, Business: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Sub Business</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="SubBusiness"
                                        value={filters.SubBusiness}
                                        onChange={(e) => setFilters(prev => ({ ...prev, SubBusiness: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Company Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="CompanyGroup"
                                        value={filters.CompanyGroup}
                                        onChange={(e) => setFilters(prev => ({ ...prev, CompanyGroup: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Company"
                                        value={filters.Company}
                                        onChange={(e) => setFilters(prev => ({ ...prev, Company: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeCompany)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Legal Entity</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="LegalEntity"
                                        value={filters.LegalEntity}
                                        onChange={(e) => setFilters(prev => ({ ...prev, LegalEntity: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        {renderMenuItems(inputData.employeeLegalEntity)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom2}>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<SearchIcon />} onClick={handleSearch}>Search</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />} onClick={handleClear}>Clear</Button>
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>

                        <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                            <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                            <select
                                id="outlined-number"
                                sx={{ width: '9ch' }}
                                value={pagination.pageSize}
                                onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{ fontWeight: 'bold' }}>entries</Box>
                            <Button
                                onClick={handleExport}
                                variant="outlined"
                                sx={{ textTransform: 'none' }}
                                startIcon={<FileCopyOutlinedIcon />}>
                                Export
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} ></Grid>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                            <TextField
                                size='small'
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                            />
                        </Grid>

                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={() => handleSort('Code')}>
                                                Code
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('Name')}>
                                                Name
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('Surname')}>
                                                Surname
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('Contractor')}>
                                                Contractor
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('EmployeeType')}>
                                                EmployeeType
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.map((row) => (
                                            <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                <StyledTableCell> {row?.code} </StyledTableCell>
                                                <StyledTableCell>{row?.first_name}</StyledTableCell>
                                                <StyledTableCell>{row.last_name}</StyledTableCell>
                                                <StyledTableCell>{row.jobDetails[0]?.contractor}</StyledTableCell>
                                                <StyledTableCell>{row.jobDetails[0]?.employee_type}</StyledTableCell>
                                                <StyledTableCell>
                                                    <EditOutlinedIcon onClick={() => navigate(`update/${row.id}`)} />
                                                    <DeleteOutlinedIcon onClick={() => handleDelete(row.id)} sx={{ ml: 1, color: 'red', cursor: 'pointer' }} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' justifyContent='space-between' sx={{ mb: 2, mt: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    {`Showing ${pagination.pageIndex * pagination.pageSize + 1} to ${Math.min((pagination.pageIndex + 1) * pagination.pageSize, rowCount)
                                        } of ${rowCount} entries`}
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        sx={{ mr: 2, textTransform: 'none' }}
                                        disabled={pagination.pageIndex === 0}
                                        onClick={() => setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex - 1 }))}
                                    >
                                        Prev
                                    </Button>
                                    <Button
                                        variant='contained'
                                        sx={{ textTransform: 'none' }}
                                        disabled={(pagination.pageIndex + 1) * pagination.pageSize >= rowCount}
                                        onClick={() => setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex + 1 }))}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* Confirmation Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}