import React from 'react';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { DeleteUser } from '../../../../api/user';
import { useSnackbar } from "notistack";
import styles from 'css/styles';

const ActionCellRenderer = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteRow = async (id) => {
        try {
          const response = await DeleteUser(id);
          switch (response.status) {
              case "success":
                  enqueueSnackbar(response.message, { variant: "success" });
                  break;
              case "failed":
                  enqueueSnackbar(response.message, { variant: "warning" });
                  break;
              case "error":
                  enqueueSnackbar(response.message, { variant: "error" });
                  break;
              default:
                  break;
          }
        } catch (error) {
            console.error('An error occurred while deleting the user:', error);
        }
      };

    return (
        <div style={{ ...styles.box }}>
            <EditOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/hr/employee-management/search-all-employee/update/${props.data.id}`)} />
            <DeleteButton id={props.data.id} fieldName={props.data.employeeFirstName} index={props.data.id} handleDeleteRow={ () => handleDeleteRow(props.data.id)} titleName="Employee" />
        </div>
    );
};

export default ActionCellRenderer;