import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Typography,
    CssBaseline,
    FormControl,
    DatePicker,
    useScrollTrigger,
    DemoItem,
    AdapterDayjs,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    NoteAddOutlinedIcon,
    VisibilityOutlinedIcon,
    LocalizationProvider,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
    { subLocation: 'Parsa Kante', examinationDate: '28/06/2323', employeeCode: 30012912, employeeName: 'Rameshwar Porte', doctor: 'Rishi Kumar Sahu', setupGroup: 'OPD', round: 'OPD', department: 'Engineering Services', company: 'Adani Resources Pvt Ltd' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function MedicalReview(props) {
    const [state, setState] = React.useState({
        SourceType: '',
        examinationType: '',
        RoundGroup: '',
        SubLocation: '',
        Round: '',
        Department: '',
        Company: '',
        Doctor: '',
        TargetGroup: '',
        ReportName: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Medical Review
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Source Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="SourceType"
                                                value={state.SourceType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="examinationType"
                                                value={state.examinationType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round Group</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="RoundGroup"
                                                value={state.RoundGroup}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Sub Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="SubLocation"
                                                value={state.SubLocation}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Round"
                                                value={state.Round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Department"
                                                value={state.Department}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Company"
                                                value={state.Company}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>ANGIOGRAPHY</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>BLOOD GROUP</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Cardiac Profile</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>CBC</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>COLLAGEN PROFILE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Dengue Test</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>DIABETIC PROFILE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={9}>ECHO</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>HEPATIC PROFILE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={11}>HIV</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={12}>Hormonal Profile</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={13}>Iron Profile</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={14}>LIPID PROFILE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={15}>Malaria Test</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={16}>MAMMOGRAPHY</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={17}>MANTOUX</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={18}>Other</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={19}>Pancreatic Profile</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Name</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name'
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Code'
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Doctor</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Doctor"
                                                value={state.Doctor}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="TargetGroup"
                                                value={state.TargetGroup}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex.10,155 etc.)</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Code'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Report Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="ReportName"
                                                value={state.ReportName}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<NoteAddOutlinedIcon />}>Export</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<VisibilityOutlinedIcon />} disabled>View Report</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Total Record : 631
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Sub Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Examination Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Doctor<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Setup Group<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Round<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Company<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                <StyledTableCell> {row.subLocation} </StyledTableCell>
                                                <StyledTableCell>{row.examinationDate}</StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell>{row.doctor}</StyledTableCell>
                                                <StyledTableCell>{row.setupGroup}</StyledTableCell>
                                                <StyledTableCell>{row.round}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                                <StyledTableCell>{row.company}</StyledTableCell>
                                                <StyledTableCell sx={{ color: '#1976d2' }}>View</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}