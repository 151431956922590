import { ApiCallPost } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from 'qs';

export const LoginApi = async (props) => {
    const { baseUrl, login } = ApiConfig;
    const url = baseUrl + login
    let params = qs.stringify({
        "email": props.email,
        "password": props.password,
    });
    
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    return await ApiCallPost(url, params, headers);
  };