const styles = {
 firstForm: {
    padding: "16px 16px 34px 16px",
    backgroundColor: "#f1f1f1",
  },
  bgWhite: {
    backgroundColor: "white",
    color: "black",
  },
  wFull: {
    width: "100%",
  },
  marginBottom2: {
    marginBottom: 2,
    mt: 2,
  },
  TableRow: {
    th: {
      width: "12.5%",
      svg: {
        float: "right",
        color: "#1976d2",
      },
    },
    td: {
      border: "1px solid lightgray",
    },
  },
  h: {
    minHeight: "40px !important",
  },
  p20: {
    padding: "20px",
  },
  bRadius: {
    borderRadius: "5px",
    marginTop: "45px",
  },
  dFlex: {
    display: "flex",
  },
  justifySB: {
    justifyContent: "space-between",
  },
  alignC: {
    alignItems: "center",
  },
  color: {
    color: "red",
    marginBottom: "20px",
  },
  pAlart: {
    // padding: "0px 5px",
  },
};

export default styles;
