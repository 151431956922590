import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeType = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeType = async (props) => {
  const url = ApiConfig.employeeTypeMain;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_type_id) => {  
  const url = `${ApiConfig.employeeTypeMain}?id=${employee_type_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeType = async (props) => {
  const url = `${ApiConfig.employeeTypeMain}?id=${props.employee_type_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeType = async (employee_type_id) => {
    const url = `${ApiConfig.employeeTypeMain}?id=${employee_type_id}`;
    const response = await api.delete(url);
    return response.data;
};
