import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    ArrowBackIcon,
    AppBar,
    Button,
    AddCircleOutlineOutlinedIcon,
    Toolbar,
    SearchOutlinedIcon,
    Typography,
    TextField,
    FileCopyOutlinedIcon,
    CssBaseline,
    useScrollTrigger,
    styled,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { srNo: 1, code: 'Achalraj', title: 'Achalraj', isActive: 'Y' },
    { srNo: 2, code: 'Achalraj', title: 'Achalraj', isActive: 'Y' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    textTransform: {
        textTransform: 'none',
    },
    hover: {
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#89f36e'
        },
    }
}

export default function PantryMaster(props) {

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Pantry Master
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Link to='/dashboard/support/canteen/pantry-master/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={[{ mr: 1, bgcolor: 'gray' }, styles.textTransform]}> Add </Button>
                                        </Link>
                                        <Button variant='outlined' startIcon={<ArrowBackIcon />} sx={styles.textTransform}> Back </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 4, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Code"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Title</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Title"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={[{ mr: 0.5, mb: 2 }, styles.textTransform]}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={[{ ml: 0.5, mb: 2 }, styles.textTransform]}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' startIcon={<FileCopyOutlinedIcon />} sx={styles.textTransform}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Sr No<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Title<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Is Active<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ color: '#0178ff' }}>
                                                    <Link to='/support/canteen/pantry-master/update'>
                                                        Select
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell>{row.srNo}</StyledTableCell>
                                                <StyledTableCell>{row.code}</StyledTableCell>
                                                <StyledTableCell>{row.title}</StyledTableCell>
                                                <StyledTableCell>{row.isActive}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 1 to 1 of 1 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={[{ mr: 2 }, styles.textTransform]} disabled>Previous</Button>
                                    <Button variant='contained' sx={styles.textTransform} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}