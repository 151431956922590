import { ApiConfig } from "./../config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeGrade = async (parmas) => {
  try{
    const {page ,per_page,globalFilter,sorting} = parmas;
    function buildQueryString(page, per_page, globalFilter, sorting) {
      const params = [`page=${page}`, `per_page=${per_page}`];

      if (globalFilter) {
        params.push(`globalFilter=${globalFilter}`);
      }
      if (sorting) {
        params.push(`sorting=${JSON.stringify(sorting)}`);
      }
      return params.join("&");
    }
    const query = buildQueryString(page ,per_page,globalFilter,sorting);
    const url = `${ApiConfig.listAllEmployeeGrade}?${query}`;

    const res = await api.get(url);
    return res?.data;
  }catch(error){
    return error;
  }
}

export const InsertCreateEmployeeGrade = async (props) => {
  const url = `${ApiConfig.employeeGradeMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_grade_id) => {
  const url = `${ApiConfig.employeeGradeMain}?id=${employee_grade_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeGrade = async (props) => {
  const url = `${ApiConfig.employeeGradeMain}?id=${props.employee_grade_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeGrade = async (employee_grade_id) => {
    const url = `${ApiConfig.employeeGradeMain}?id=${employee_grade_id}`;
    const response = await api.delete(url);
    return response.data;
};