import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    CardContent,
    VisibilityOutlinedIcon,
    Button,
    Toolbar,
    Slide,
    PropTypes,
    TextField,
    Typography,
    SearchIcon,
    useScrollTrigger,
    HighlightOffIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Issue Return
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Stack spacing={1} direction="row" sx={{ m: 1 }} >

                                    <Button variant="outlined" sx={{ textTransform: 'none' }} disabled startIcon={<VisibilityOutlinedIcon />}>
                                        View Report
                                    </Button>

                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                                        Clear
                                    </Button>
                                </Stack>
                            </Grid>

                            <Grid>
                                <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#515c66', m: 1 }} startIcon={<SearchIcon />}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1, ml: 2, mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Issue Return No</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Issue Return No'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Return By</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='Mayur'
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Issue No <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Issue No'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Remark</Box>
                                </Typography>
                                <textarea
                                    name="postContent"
                                    rows={3}
                                    cols={80}
                                />
                            </Grid>


                        </Grid>
                    </CardContent>
                </Card>
            </Box>

        </>

    )
}