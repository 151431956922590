const styles = {
    firstForm: {
        padding: '16px 16px 34px 16px',
        backgroundColor: '#f1f1f1',
    },
    bgWhite: {
        backgroundColor: 'white',
        color: 'black'
    },
    wFull: {
        width: '100%'
    },
    marginBottom5: {
        marginBottom: 5,
        mt: 5
    },
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray !important',
        },
    },
    h: {
        minHeight: '40px !important'
    },
}

export default styles;