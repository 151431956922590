import { useEffect, useState } from 'react';
import sharedImports from 'component/Comman/sharedImports';
import { InsertLanguage, GetLanguage } from '../../../api/changeLanguage';
import styles from 'css/styles';
import { useSnackbar } from 'notistack';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar';
const {
    React,
    Box,
    Grid,
    Button,
    TextField
} = sharedImports;


export default function HrLanguage() {
    const [formData, setFormData] = useState({
        headingKey: 'hr_language',
        headingValue: {}
    });
    const { enqueueSnackbar } = useSnackbar();
    const fields = [
        { label: 'Temporary Registration', name: 'hr_employee_temporary_registration', placeholder: 'Temporary Registration' },
        { label: 'Temporary Code', name: 'hr_employee_temporary_code', placeholder: 'Temporary Code' },
        { label: 'Code', name: 'hr_employee_code', placeholder: 'Code' },
        { label: 'Reference Code', name: 'hr_employee_reference_code', placeholder: 'Reference Code' },
        { label: 'First Name', name: 'hr_employee_first_name', placeholder: 'First Name' },
        { label: 'Middle Name', name: 'hr_employee_middle_name', placeholder: 'Middle Name' },
        { label: 'Last Name', name: 'hr_employee_last_name', placeholder: 'Last Name' },
        { label: 'Date of Birth', name: 'hr_employee_date_of_birth', placeholder: 'Date of Birth' },
        { label: 'BirthDate Unknown', name: 'hr_employee_birthdate_unknown', placeholder: 'BirthDate Unknown' },
        { label: 'Gender', name: 'hr_employee_gender', placeholder: 'Gender' },
        { label: 'Employee Status', name: 'hr_employee_hr_employee_status', placeholder: 'Employee Status' },
        { label: 'Work Location', name: 'hr_employee_work_location', placeholder: 'Work Location' },
        { label: 'Date of Joining', name: 'hr_employee_date_of_joining', placeholder: 'Date of Joining' },
        { label: 'Department', name: 'hr_employee_department', placeholder: 'Department' },
        { label: 'Designation', name: 'hr_employee_designation', placeholder: 'Designation' },
        { label: 'Employee Type Group', name: 'hr_employee_hr_employee_type_group', placeholder: 'Employee Type Group' },
        { label: 'Employee Type', name: 'hr_employee_hr_employee_type', placeholder: 'Employee Type' },
        { label: 'Employee Sub Type', name: 'hr_employee_hr_employee_sub_type', placeholder: 'Employee Sub Type' },
        { label: 'Grade', name: 'hr_employee_grade', placeholder: 'Grade' },
        { label: 'Company', name: 'hr_employee_company', placeholder: 'Company' },
        { label: 'Contractor SubBusiness', name: 'hr_employee_contractor_sub_business', placeholder: 'Contractor SubBusiness' },
        { label: 'Target Group', name: 'hr_employee_target_group', placeholder: 'Target Group' },
        { label: 'Process Type', name: 'hr_employee_process_type', placeholder: 'Process Type' },
        { label: 'Skill', name: 'hr_employee_skill', placeholder: 'Skill' },
        { label: 'Employee Group', name: 'hr_employee_hr_employee_group', placeholder: 'Employee Group' },
        { label: 'Employee Group Code', name: 'hr_employee_hr_employee_group_code', placeholder: 'Employee Group Code' },
        { label: 'Legal Entity', name: 'hr_employee_legal_entity', placeholder: 'Legal Entity' },
        { label: 'Job Title', name: 'hr_employee_job_title', placeholder: 'Job Title' },
        { label: 'Medical Coverage', name: 'hr_employee_medical_coverage', placeholder: 'Medical Coverage' },
        { label: 'Raw Material Used', name: 'hr_employee_raw_material_used', placeholder: 'Raw Material Used' },
        { label: 'Hazardous Process', name: 'hr_employee_hazardous_process', placeholder: 'Hazardous Process' },
        { label: 'Dangerous Process', name: 'hr_employee_dangerous_process', placeholder: 'Dangerous Process' },
        { label: 'Contractor', name: 'hr_employee_contractor', placeholder: 'Contractor' },
        { label: 'Active Upto', name: 'hr_employee_active_upto', placeholder: 'Active Upto' },
        { label: 'EPF Number', name: 'hr_employee_epf_number', placeholder: 'EPF Number' },
        { label: 'ESI Number', name: 'hr_employee_esi_number', placeholder: 'ESI Number' },
        { label: 'Reporting Manager', name: 'hr_employee_reporting_manager', placeholder: 'Reporting Manager' },
        { label: 'Job Description', name: 'hr_employee_job_description', placeholder: 'Job Description' },
        { label: 'Working in Shift', name: 'hr_employee_working_in_shift', placeholder: 'Working in Shift' },
        { label: 'Medical Date', name: 'hr_employee_medical_date', placeholder: 'Medical Date' },
        { label: 'Designated Position', name: 'hr_employee_designated_position', placeholder: 'Designated Position' },
        { label: 'UID Number', name: 'hr_employee_uid_number', placeholder: 'UID Number' },
        { label: 'Age', name: 'hr_employee_age', placeholder: 'Age' },
        { label: 'Color Vision', name: 'hr_employee_color_vision', placeholder: 'Color Vision' },
        { label: 'Blood Group', name: 'hr_employee_blood_group', placeholder: 'Blood Group' },
        { label: 'Marital Status', name: 'hr_employee_marital_status', placeholder: 'Marital Status' },
        { label: 'Religion', name: 'hr_employee_religion', placeholder: 'Religion' },
        { label: 'Qualification', name: 'hr_employee_qualification', placeholder: 'Qualification' },
        { label: 'ID Mark1', name: 'hr_employee_id_mark1', placeholder: 'ID Mark1' },
        { label: 'ID Mark2', name: 'hr_employee_id_mark2', placeholder: 'ID Mark2' },
        { label: 'Family Doctor', name: 'hr_employee_family_doctor', placeholder: 'Family Doctor' },
        { label: 'Family Doctor Contact', name: 'hr_employee_family_doctor_contact', placeholder: 'Family Doctor Contact' },
        { label: 'Family Doctor Address 1', name: 'hr_employee_family_doctor_address1', placeholder: 'Family Doctor Address 1' },
        { label: 'Family Doctor Address 2', name: 'hr_employee_family_doctor_address2', placeholder: 'Family Doctor Address 2' },
        { label: 'Family Doctor Address 3', name: 'hr_employee_family_doctor_address3', placeholder: 'Family Doctor Address 3' },
        { label: 'Disability', name: 'hr_employee_disability', placeholder: 'Disability' },
        { label: 'Disability Certified', name: 'hr_employee_disability_certified', placeholder: 'Disability Certified' },
        { label: 'First Aid Training', name: 'hr_employee_first_aid_training', placeholder: 'First Aid Training' },
        { label: 'First Aid Training Date', name: 'hr_employee_first_aid_training_date', placeholder: 'First Aid Training Date' },
        { label: 'Fire Training', name: 'hr_employee_fire_training', placeholder: 'Fire Training' },
        { label: 'Fire Training Date', name: 'hr_employee_fire_training_date', placeholder: 'Fire Training Date' },
        { label: 'Random First Aid Date', name: 'hr_employee_random_first_aid_date', placeholder: 'Random First Aid Date' },
        { label: 'First Aid Training Due Date', name: 'hr_employee_first_aid_training_due_date', placeholder: 'First Aid Training Due Date' },
        { label: 'Company Phone No', name: 'hr_employee_company_phone_no', placeholder: 'Company Phone No' },
        { label: 'Extension No', name: 'hr_employee_extension_no', placeholder: 'Extension No' },
        { label: 'Company Email', name: 'hr_employee_company_email', placeholder: 'Company Email' },
        { label: 'Personal Email', name: 'hr_employee_personal_email', placeholder: 'Personal Email' },
        { label: 'Mobile Number', name: 'hr_employee_mobile_number', placeholder: 'Mobile Number' },
        { label: 'Address 1', name: 'hr_employee_address1', placeholder: 'Address 1' },
        { label: 'Address 2', name: 'hr_employee_address2', placeholder: 'Address 2' },
        { label: 'Address 3', name: 'hr_employee_address3', placeholder: 'Address 3' },
        { label: 'City', name: 'hr_employee_city', placeholder: 'City' },
        { label: 'State', name: 'hr_employee_state', placeholder: 'State' },
        { label: 'Country', name: 'hr_employee_country', placeholder: 'Country' },
        { label: 'Pin Code', name: 'hr_employee_pin_code', placeholder: 'Pin Code' },
        { label: 'Contact No', name: 'hr_employee_contact_no', placeholder: 'Contact No' },

    ]

    const getData = async () => {
        try {
            const parmas = { headingKey: 'hr_language' }
            const response = await GetLanguage(parmas);
            if (response.status) {
                const { data } = response;
                //console.log(typeof data);
                    const formData = { headingKey: 'hr_language', headingValue: {} };
                    Object.keys(data).forEach((key) => {
                        formData.headingValue[key] = data[key];
                    });
                    console.log('formData == ',formData);
                    
                    setFormData(formData);
              
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            headingValue: { ...prevFormData.headingValue, [name]: value }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        insertData(formData);
    };

    const insertData = async (formData) => {
        try {
            const newData = { ...formData };
            const response = await InsertLanguage(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            console.log('error', error);

            enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
    }
    return (
        <Box sx={styles.root}>
            <CommanAppBarWithAddButton appBarTitle="HR Employee Managment" />
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>

                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Original Headings</Box>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Change Headings</Box>
                        </Grid>
                    </Grid>

                    {fields.map((field, index) => (
                        <Grid container spacing={2}>
                            <React.Fragment key={index}>
                                <Grid item xs={4} md={4}>
                                    <Box sx={{ fontWeight: 'bold' }}>{field.label}</Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField
                                        required
                                        id={`filled-${field.name}`}
                                        fullWidth
                                        size='small'
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        value={formData.headingValue[field.name] || ''}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    ))}
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}