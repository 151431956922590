import { ApiConfig } from "./config/ApiConfig";
import { api } from "./config/axiosInterceptor";

export const GetExpenses = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateExpense = async (props) => {
  const url = ApiConfig.expenseUrl;
  const params = {
    ...props,
    expense_date: props.expense_date?.format("YYYY-MM-DD"),
  }
  const response = await api.post(url, params);
  return response.data;
};

export const SingleList = async (id) => {
  const url = `${ApiConfig.expenseUrl}?id=${id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateExpense = async (props) => {
  const url = `${ApiConfig.expenseUrl}?id=${props.id}`;
  const { id, created_at, updated_at, ...restProps} = props;
  const params = {
    ...restProps,
    expense_date: restProps.expense_date?.format("YYYY-MM-DD"),
  }
  const response = await api.put(url, params);
  return response.data;
};

export const DeleteExpense = async (id) => {
  const url = `${ApiConfig.expenseUrl}?id=${id}`;
  const response = await api.delete(url);
  return response.data;
};
