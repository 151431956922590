import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RestoreIcon from '@mui/icons-material/Restore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import HeadsetIcon from '@mui/icons-material/Headset';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Checkbox,
    Grid,
    Tabs,
    Tab,
    Slide,
    Link,
    Select,
    VisibilityOutlinedIcon,
    AppBar,
    Button,
    FormControlLabel,
    Toolbar,
    MenuItem,
    Typography,
    FormatListBulletedOutlinedIcon,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    styled,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    LocalizationProvider,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    Stack,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    SearchOutlinedIcon,
    DemoItem,
    DatePicker,
    AdapterDayjs,
} = sharedImports;


const drawerWidth = 300;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
    { testName: 'Cardio Vascular System', parameterName: 'Other', type: 'List', range: null },
];
const reportListRows3 = [
    { testName: 'CBC', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
    { testName: '', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
    { testName: '', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
    { testName: '', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
    { testName: '', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
    { testName: '', parameterName: 'HB', type: 'Numeric', range: '13.5000 - 17.5000 (gm%)' },
];
const reportListRows4 = [
    { frequency: 250 },
    { frequency: 250 },
    { frequency: 250 },
    { frequency: 250 },
    { frequency: 250 },
    { frequency: 250 },
    { frequency: 250 },
];
const reportListRows5 = [
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
    { parameter: 'FVC(L)' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '16.666666px !important',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ExaminationVital(props) {

    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    function handleChange(event, newValue) {
        setAge(event.target.value);
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" noWrap component="div">
                                            Examination Vital
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <React.Fragment>
                                            <Button variant="contained" onClick={handleClickOpen} startIcon={<FormatListBulletedOutlinedIcon />} sx={{ float: 'right' }}>
                                            List
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Cancel</Button>
                                                    <Button type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Stack direction="row" spacing={0} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={12}>
                                    <Button startIcon={<VisibilityOutlinedIcon />}>View</Button>
                                    <Button startIcon={<AccountBoxIcon />}>Visit</Button>
                                    <Button startIcon={<RestoreIcon />}>History</Button>
                                    <Button startIcon={<KeyboardDoubleArrowLeftIcon />}>Previous</Button>
                                    <Button startIcon={<ViewColumnIcon />}>Current</Button>
                                    <Button startIcon={< KeyboardDoubleArrowRightIcon />}>Next</Button>
                                    <Button startIcon={<PersonAddAlt1Icon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<PhotoOutlinedIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<HeadsetIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<QuestionMarkIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<AccessibilityNewIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<VisibilityOffIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<WestIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                    <Button startIcon={<EastIcon />} sx={{ p: '0px !important', minWidth: '35px' }}></Button>
                                </Grid>
                            </Stack>
                            <Box sx={{ mb: 3 }}>Type: <b>Periodic</b>| Round: <b>Annual Health Checkup 2022-23</b>| Code: <b>30005230</b>| Name: <b>Sudesh Jain</b>| Status: <b>Compleated</b>| Target Group: <b>General</b></Box>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Vital" {...a11yProps(0)} />
                                    <Tab label="General" {...a11yProps(1)} />
                                    <Tab label="Test" {...a11yProps(2)} />
                                    <Tab label="Audiometry" {...a11yProps(3)} />
                                    <Tab label="Pulmonary" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>General Examination</Box>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>
                                    <Stack direction="row" spacing={0}>
                                        <Grid item xs={12} md={12}>
                                            <Button>Cardio Vascular System</Button>
                                            <Button>Central Nervous System</Button>
                                            <Button>Ear</Button>
                                            <Button>Endocrine System</Button>
                                            <Button>Eye</Button>
                                            <Button>General</Button>
                                            <Button>Genitor Urinary System</Button>
                                            <Button>GI System</Button>
                                            <Button>Hair</Button>
                                            <Button>Locomotor System</Button>
                                            <Button>Mouth</Button>
                                            <Button>Nails</Button>
                                            <Button>Nose</Button>
                                            <Button>Respiratory System</Button>
                                            <Button>Skin</Button>
                                            <Button>Throat</Button>
                                        </Grid>
                                    </Stack>
                                </Box>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Test Name</StyledTableCell>
                                                <StyledTableCell>Parameter Name</StyledTableCell>
                                                <StyledTableCell>Result</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Remark</StyledTableCell>
                                                <StyledTableCell>Range</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.testName} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.testName}</StyledTableCell>
                                                    <StyledTableCell>{row.parameterName}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={age}
                                                                onChange={handleChange}
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>-All-</em>
                                                                </MenuItem>
                                                                <MenuItem value={1}>Councelling</MenuItem>
                                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.type}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            placeholder='Remark'
                                                            multiline
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.range}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>General Examination</Box>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>
                                    <Stack direction="row" spacing={0}>
                                        <Grid item xs={12} md={12}>
                                            <Button>Cardio Vascular System</Button>
                                            <Button>Central Nervous System</Button>
                                            <Button>Ear</Button>
                                            <Button>Endocrine System</Button>
                                            <Button>Eye</Button>
                                            <Button>General</Button>
                                            <Button>Genitor Urinary System</Button>
                                            <Button>GI System</Button>
                                            <Button>Hair</Button>
                                            <Button>Locomotor System</Button>
                                            <Button>Mouth</Button>
                                            <Button>Nails</Button>
                                            <Button>Nose</Button>
                                            <Button>Respiratory System</Button>
                                            <Button>Skin</Button>
                                            <Button>Throat</Button>
                                        </Grid>
                                    </Stack>
                                </Box>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Test Name</StyledTableCell>
                                                <StyledTableCell>Parameter Name</StyledTableCell>
                                                <StyledTableCell>Result</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Remark</StyledTableCell>
                                                <StyledTableCell>Range</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.testName} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.testName}</StyledTableCell>
                                                    <StyledTableCell>{row.parameterName}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={age}
                                                                onChange={handleChange}
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>-All-</em>
                                                                </MenuItem>
                                                                <MenuItem value={1}>Councelling</MenuItem>
                                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.type}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            placeholder='Remark'
                                                            multiline
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.range}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>Test Examination</Box>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>
                                    <Stack direction="row" spacing={0}>
                                        <Grid item xs={12} md={12}>
                                            <Button>CBC</Button>
                                            <Button>COLLAGEN PROFILE</Button>
                                            <Button>DIABETIC PROFILE</Button>
                                            <Button>ECG</Button>
                                            <Button>ECHO</Button>
                                            <Button>HEPATIC PROFILE</Button>
                                            <Button>Hormonal Profile</Button>
                                            <Button>Iron Profile</Button>
                                            <Button>LIPID PROFILE</Button>
                                            <Button>PSA</Button>
                                            <Button>RENAL PROFILE</Button>
                                            <Button>SONOGRAPHY</Button>
                                            <Button>Thyriod Profile</Button>
                                            <Button>TMT</Button>
                                            <Button>URINE TEST</Button>
                                            <Button>Vitamin and Mineral</Button>
                                            <Button>X-RAY</Button>
                                        </Grid>
                                    </Stack>
                                </Box>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Test Name</StyledTableCell>
                                                <StyledTableCell>Parameter Name</StyledTableCell>
                                                <StyledTableCell>Result</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Remark</StyledTableCell>
                                                <StyledTableCell>Range</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows3.map((row) => (
                                                <StyledTableRow key={row.testName} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.testName}</StyledTableCell>
                                                    <StyledTableCell>{row.parameterName}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            placeholder='Result'
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.type}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            placeholder='Remark'
                                                            multiline
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.range}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>Audiometry</Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="By Rule"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="By Examination"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Is Done"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="By Rule"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="By Examination"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Is Done"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <Box component='h3' sx={{ m: 0, my: 1 }}>Left Audiometry</Box>
                                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>Frequency</StyledTableCell>
                                                        <StyledTableCell>Ac Value</StyledTableCell>
                                                        <StyledTableCell>Bc Value</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows4.map((row) => (
                                                        <StyledTableRow key={row.frequency} sx={styles.TableRow}>
                                                            <StyledTableCell>{row.frequency}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <TextField
                                                                    id="filled-search"
                                                                    type="search"
                                                                    fullWidth
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <TextField
                                                                    id="filled-search"
                                                                    type="search"
                                                                    fullWidth
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box component='h3' sx={{ m: 0, my: 1 }}>Right Audiometry</Box>
                                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow sx={styles.TableRow}>
                                                        <StyledTableCell>Frequency</StyledTableCell>
                                                        <StyledTableCell>Ac Value</StyledTableCell>
                                                        <StyledTableCell>Bc Value</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reportListRows4.map((row) => (
                                                        <StyledTableRow key={row.frequency} sx={styles.TableRow}>
                                                            <StyledTableCell>{row.frequency}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <TextField
                                                                    id="filled-search"
                                                                    type="search"
                                                                    fullWidth
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <TextField
                                                                    id="filled-search"
                                                                    type="search"
                                                                    fullWidth
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <Box>Remark</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box>AcResult</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box>BcResult</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box>Remark</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box>AcResult</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box>BcResult</Box>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Show Previous Chart Left"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Show Previous Chart Right"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>Pulmonary</Box>
                                <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Is Done"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Is Manually"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                <TableContainer sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important', fontWeight: 'bold' }}>Parameter</StyledTableCell>
                                                <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important', fontWeight: 'bold' }}>Observe</StyledTableCell>
                                                <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important', fontWeight: 'bold' }}>Predicted</StyledTableCell>
                                                <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important', fontWeight: 'bold' }}>%Predicted</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows5.map((row) => (
                                                <StyledTableRow key={row.parameter}>
                                                    <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important' }}>{row.parameter}</StyledTableCell>
                                                    <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important' }}>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important' }}>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ bgcolor: 'white !important', color: 'black !important' }}>
                                                        <TextField
                                                            id="filled-search"
                                                            type="search"
                                                            fullWidth
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box component='div' sx={{ p: 1, bgcolor: '#f1f1f1', borderRadius: 1, mb: 2 }}>Broncho Test</Box>
                                <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        Broncho FEV1
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        Broncho FVC
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        Broncho PEFR
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} md={3}>
                                        Reversibility Test
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="">
                                                    <em>-Select-</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Councelling</MenuItem>
                                                <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem value={3}>First Aid Training</MenuItem>
                                                <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        Reason
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            multiline
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={3}></Grid>
                                </Grid>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}