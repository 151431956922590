import React, { useState } from 'react';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import sharedImports from '../../../Comman/sharedImports';
const {
    AppBar,
    Box,
    Tab,
    Tabs,
    Slide,
    Card,
    Paper,
    Grid,
    Table,
    Button,
    CssBaseline,
    Dialog,
    Toolbar,
    PropTypes,
    Divider,
    TableBody,
    useScrollTrigger,
    TableRow,
    TableHead,
    TableCell,
    Typography,
    CardContent,
    DialogActions,
    TableContainer,
    styled,
} = sharedImports;


const drawerWidth = 300;
const StyledTab = styled(Tab)(({ theme, selected }) => ({
    backgroundColor: 'white',
    color: selected ? '#1976d2' : 'black',
    borderTop: selected ? '3px solid #1976d2' : '1px solid #dfdcdc',
    '&:hover': {
        backgroundColor: '#f5f5f5',
    },
    '&.Mui-selected': {
        backgroundColor: '#1976d2',
        color: 'white',
    },
}));
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const rows = [
    { name: 'Height', value: 165.00, unit: "Cm" },
    { name: 'Weight', value: 84.00, unit: "Kg" },
    { name: 'BMI', value: 30.85 },
    { name: 'BP Sys (mmHg)', value: 122, unit: "mmHg" },
    { name: 'BP Dia (mmHg)', value: 80, unit: "mmHg" },
];

const row = [
    { name: 'Height', value: 166.00, unit: "Cm" },
    { name: 'Weight', value: 82.00, unit: "Kg" },
    { name: 'BMI', value: 29.71 },
    { name: 'BP Sys (mmHg)', value: 126, unit: "mmHg" },
    { name: 'BP Dia (mmHg)', value: 80, unit: "mmHg" },
];

const rowss = [
    { name: 'Blood' },
    { name: 'BLOOD GROUP', value: "O-" },
    { name: 'HB', value: 13.9, unit: "gm%" },
    { name: 'R.B.C COUNT', value: 4.53, unit: "mill/cmm" },
    { name: 'MCV', value: 92.5, unit: "FL" },
    { name: 'TOTAL COUNT', value: 7700, unit: "/cumm" },
    { name: 'NEUTROPHILS', value: 56, unit: "%" },
    { name: 'LYMPHOCYTES', value: 39, unit: "%" },
];

const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '50px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

const style = {
    tableRow: {
        th: {
            svg: {
                float: 'right'
            }
        }
    }
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DenseAppBar(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const [selectedTabs, setSelectedTabs] = useState(0);

    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleTabChanges = (event, newValue) => {
        setSelectedTabs(newValue);
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>My Health</Typography>
                                    <Grid>
                                        <Button variant="outlined" sx={{ textTransform: 'none', mr: 1 }} startIcon={<InsertChartOutlinedTwoToneIcon />}>
                                            360 View
                                        </Button>
                                        <React.Fragment>
                                            <Button sx={{ textTransform: 'none' }} variant="outlined" onClick={handleClickOpen} startIcon={<InsertChartOutlinedTwoToneIcon />}>
                                                View Chart
                                            </Button>
                                            <Dialog
                                                fullWidth={'lg'}
                                                maxWidth={'lg'}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <Card variant="outlined">
                                                    <CardContent>

                                                        <Grid item xs={12} md={3}>
                                                            <Typography variant="body1">
                                                                <Box component='h2' sx={{ m: 0 }}>Health Trend </Box>
                                                            </Typography>
                                                        </Grid>

                                                    </CardContent>
                                                    <Divider />

                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ width: '40%', m: 2, boxShadow: '12px 12px 10px 1px rgba(0, 0, 0, .2)', height: '750px', overflow: 'auto' }}>
                                                            <Tabs
                                                                orientation="vertical"
                                                                value={selectedTabs}
                                                                onChange={handleTabChanges}
                                                                aria-label="Sidebar Tabs"
                                                                TabIndicatorProps={{
                                                                    style: {
                                                                        display: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                {['Health Index', 'Weight', 'BMI', 'BP Sys (mmHg)', 'BP Dia (mmHg)', 'HB', 'F B S', 'P P B S', 'R B S', 'HBA1C',
                                                                    'SERUM CREATININE', 'CHOLESTEROL', 'TRIGLYCERIDE', 'HDL', 'LDL', 'SGPT', 'SERUM URIC ACID', 'TSH', 'PSA',
                                                                    'Vitamin B12', 'Vitamin D3'].map((label, index) => (
                                                                        <StyledTab
                                                                            key={label}
                                                                            label={label}
                                                                            selected={selectedTabs === index}
                                                                            sx={{ textTransform: 'none' }}
                                                                        />
                                                                    ))}
                                                            </Tabs>
                                                        </Box>

                                                        <Box sx={{ width: '100%', m: 2 }}>
                                                            {selectedTabs === 0 &&
                                                                <Typography>

                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12} md={3}>
                                                                            <Button variant='outlined'><ArrowForwardIcon /></Button>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                                Normal Min:0 Normal Max: 0
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={3}>
                                                                            <Button variant='outlined' sx={{ float: 'right' }}><ArrowBackIcon /></Button>
                                                                        </Grid><br />
                                                                        <Grid item xs={12} md={3}></Grid>
                                                                    </Grid>
                                                                    <Box sx={{ m: 3 }}>
                                                                        <Typography>90</Typography><br />
                                                                        <Typography>80</Typography><br />
                                                                        <Typography>70</Typography><br />
                                                                        <Typography>60</Typography><br />
                                                                        <Typography>50</Typography><br />
                                                                        <Typography>40</Typography><br />
                                                                        <Typography>30</Typography><br />
                                                                        <Typography>20</Typography><br />
                                                                        <Typography>10</Typography><br />
                                                                        <Typography>0</Typography><hr />
                                                                        <Typography>11/22</Typography>
                                                                    </Box>

                                                                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Health Index</Typography>

                                                                </Typography>

                                                            }

                                                            {selectedTabs === 1 && <Typography>Content for Tab 2</Typography>}

                                                            {selectedTabs === 2 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 3 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 4 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 5 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 6 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 7 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 8 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 9 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 10 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 11 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 12 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 13 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 14 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 15 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 16 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 17 && <Typography>Content for Tab 3</Typography>}
                                                            {selectedTabs === 18 && <Typography>Content for Tab 1</Typography>}
                                                            {selectedTabs === 19 && <Typography>Content for Tab 2</Typography>}
                                                            {selectedTabs === 20 &&
                                                                <Typography>

                                                                    <Box sx={{ m: 3 }}>

                                                                        <Typography>7</Typography><br />
                                                                        <Typography>6</Typography><br />
                                                                        <Typography>5</Typography><br />
                                                                        <Typography>4</Typography><br />
                                                                        <Typography>3</Typography><br />
                                                                        <Typography>2</Typography><br />
                                                                        <Typography>1</Typography><br />
                                                                        <Typography>0</Typography><hr />
                                                                        <Typography>11/22</Typography>
                                                                    </Box>

                                                                    <Typography sx={{ textAlign: 'center' }}>Vitamin D3</Typography>

                                                                </Typography>}
                                                        </Box>
                                                    </Box>
                                                </Card>

                                                <DialogActions>
                                                    <Button onClick={handleClose} sx={{ textTransform: 'none' }}>Close</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="EXAMINATION" sx={{ color: '#1976d2', }} {...a11yProps(0)} />
                                <Tab label="OPD" sx={{ color: '#1976d2', }} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'lightgray', mb: 2 }}>
                                    <Typography>Code: 30053101</Typography>
                                    <Typography>Name: Mayur Trivedi</Typography>
                                    <Typography>Examination Date: 14/11/2022</Typography>
                                    <Button variant='outlined' sx={{ float: 'right' }}><ArrowForwardIcon /></Button>
                                </Box>

                                <Box sx={{ mb: 2, p: 2, backgroundColor: 'lightgray' }}>
                                    <Typography>Vital</Typography>
                                </Box>

                                <TableContainer component={Paper} sx={[style.tableRow, { mb: 2, }]} >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'black' }}>
                                                <TableCell sx={{ color: 'white' }}>Parameter</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Value</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Unit</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell>{row.value}</TableCell>
                                                    <TableCell>{row.unit}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Box sx={{ mb: 2, p: 2, backgroundColor: 'lightgray' }}>
                                    <Typography>Test</Typography>
                                </Box>

                                <TableContainer component={Paper} sx={[style.tableRow, { mb: 2 }]} >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'black' }}>
                                                <TableCell sx={{ color: 'white' }}>Parameter</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Value</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Unit</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowss.map((row) => (
                                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell>{row.value}</TableCell>
                                                    <TableCell>{row.unit}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Typography>  <TableContainer component={Paper} sx={[style.tableRow, { mb: 4 }]} >
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: 'black' }}>
                                            <TableCell sx={{ color: 'white' }}>Parameter</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Value</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Unit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.map((row) => (
                                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                                <TableCell>{row.value}</TableCell>
                                                <TableCell>{row.unit}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                                <Box sx={{ mb: 2, p: 2, backgroundColor: 'lightgray' }}>
                                    <Typography>Test</Typography>
                                </Box>

                                <TableContainer component={Paper} sx={[style.tableRow, { mb: 2 }]} >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'black' }}>
                                                <TableCell sx={{ color: 'white' }}>Parameter</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Value</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Unit</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowss.map((row) => (
                                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell>{row.value}</TableCell>
                                                    <TableCell>{row.unit}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer></Typography>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    );
}
