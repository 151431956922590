import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Slide,
    Select,
    Checkbox,
    MenuItem,
    PropTypes,
    CardContent,
    Button,
    Toolbar,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    useScrollTrigger,
    FormControlLabel,
    HighlightOffIcon,
} = sharedImports;



const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}




export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        MainGroup: '',
        SubGroup: '',
        ItemCategory: '',
        UOM: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Item Detail Update
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 0,
                        ml: 1,
                        mt: 6,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 0, }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex,10,155 Etc.) </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Code Like'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Name Like </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Name Like'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Main Group </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="MainGroup"
                                            value={state.MainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sub Group </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="SubGroup"
                                            value={state.SubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Item Category </Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ItemCategory"
                                            value={state.ItemCategory}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>UOM</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="UOM"
                                            value={state.UOM}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Vendor</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Vendor'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>



                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Drug"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Batch wise"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Block For OPD"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Critical"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="First Aid"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Not In Store"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="In Active"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>

                        </Grid>

                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 5 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>

                </Card>
            </Box>

        </>

    )
}