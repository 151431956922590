import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Table,
    styled,
    Button,
    Toolbar,
    TableRow,
    TableHead,
    TableCell,
    TextField,
    Typography,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    FormControlLabel,
    Checkbox,
    ArrowDropDownIcon,
    TableBody,
    Slide,
    useScrollTrigger,
    PropTypes,

} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SubLocation: 'Ahmedabad', ItemName: 'Foley Catheter 14 FR', Quantity: 1, },
    { SubLocation: 'Ahmedabad', ItemName: 'ECG gel', Quantity: 9, },
    { SubLocation: 'Ahmedabad', ItemName: 'Zylocaine gel', Quantity: 3, },
    { SubLocation: 'Ahmedabad', ItemName: 'Sterilium 500ml', Quantity: 17, },
    { SubLocation: 'Ahmedabad', ItemName: 'Relispray', Quantity: 3, },
    { SubLocation: 'Ahmedabad', ItemName: 'Dynacrepe Bandage', Quantity: 2, },
    { SubLocation: 'Ahmedabad', ItemName: 'Elastoplast/Realplast (dynaplast)', Quantity: 3, },
    { SubLocation: 'Ahmedabad', ItemName: 'Sterile Disposable Gloves 7.5', Quantity: 75, },
    { SubLocation: 'Ahmedabad', ItemName: 'Micropore 1&quot;', Quantity: 9, },
    { SubLocation: 'Ahmedabad', ItemName: 'Band Aid', Quantity: 395, },
];

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function DenseAppBar(props) {
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Enquiry
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Name Like</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Item Name Like'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Name</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Name'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={4.5}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex.10,155 etc.)</Box>
                                </Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Code Like '
                                />

                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}></Grid>


                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="In Active"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center' }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export
                        </Button>

                    </Stack>
                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent >
                                <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
                                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue="10"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pages</Typography>
                                        <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export < ArrowDropDownIcon /></Button>
                                    </Grid>
                                    <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                        <TextField
                                            required
                                            id="filled-search"
                                        />
                                    </Grid>
                                </Grid>

                                <TableContainer item xs={12} md={3} >
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Sub Location <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Item Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Quantity <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.SubLocation} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.SubLocation} </StyledTableCell>
                                                    <StyledTableCell>{row.ItemName}</StyledTableCell>
                                                    <StyledTableCell>{row.Quantity}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>

                            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 2 }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Showing 1 to 10 of 172 entries</Typography>
                                <Grid item sx={{ p: 1 }}>
                                    <Button variant='contained' sx={{ textTransform: 'none', mr: 1 }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ mr: 1 }} disabled>1</Button>
                                    <Button variant='contained' sx={{ mr: 1 }} disabled>2</Button>
                                    <Button variant='contained' sx={{ mr: 1 }} disabled>3</Button>
                                    <Button variant='contained' sx={{ mr: 1 }} disabled>...</Button>
                                    <Button variant='outlined' sx={{ mr: 1 }} disabled>18</Button>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }}>Next</Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Card>
            </Box>



        </>
    );
}