import { ApiConfig } from "api/config/ApiConfig";
import { api } from "api/config/axiosInterceptor";

export const GetEmployeeMedicalCoverage = async (url) => {
  const response = await api.get(url);
  return response.data;
}

export const InsertCreateEmployeeMedicalCoverage = async (props) => {
  const url = `${ApiConfig.employeeMedicalCoverageMain}`;
  const response = await api.post(url, props);
  return response.data;
};

export const SingleList = async (employee_medical_coverage_id) => {
  const url = `${ApiConfig.employeeMedicalCoverageMain}?id=${employee_medical_coverage_id}`;
  const response = await api.get(url);
  return response.data;
};

export const UpdateEmployeeMedicalCoverage = async (props) => {
  const url = `${ApiConfig.employeeMedicalCoverageMain}?id=${props.employee_medical_coverage_id}`;
  const response = await api.put(url, props);
  return response.data;
};

export const DeleteEmployeeMedicalCoverage = async (employee_medical_coverage_id) => {
    const url = `${ApiConfig.employeeMedicalCoverageMain}?id=${employee_medical_coverage_id}`;
    const response = await api.delete(url);
    return response.data;
};

