import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from "../component/Login/loginPage";
import Dashboard from "../component/dashboard";
import UserPermission  from "../component/Navbar/UserRole-SubMenus/UserPermission/userPermission"
import UserRole from '../component/Navbar/UserRole-SubMenus/UserPermission/userRole';
import TestListing from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/testListing';
import TestAnalysis from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/testAnalysis';
import EmployeeAnalysis from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/employeeAnalysis';
import ServiceCostAnalysis from '../component/Navbar/Diagnostic-SubMenus/Report/serviceCostAnalysis';
import TestReport from '../component/Navbar/Diagnostic-SubMenus/Report/testReport';
import CostReport from '../component/Navbar/Diagnostic-SubMenus/Report/costReport';
import TestListReport from '../component/Navbar/Diagnostic-SubMenus/Report/testListReport';
import ImportDoctor from '../component/Navbar/Clinical-SubMenus/Doctor/importDoctor';
import DoctorMasterSetup from '../component/Navbar/Clinical-SubMenus/Doctor/doctorMasterSetup';
import DocumentSearch from '../component/Navbar/Clinical-SubMenus/MedicalReview/documentSearch';
import MedicalHistory from '../component/Navbar/Clinical-SubMenus/MedicalReview/medicalHistory';
import MedicalReview from '../component/Navbar/Clinical-SubMenus/MedicalReview/medicalReview';
import FormAccidentWithInjury from '../component/Navbar/Safety-SubMenus/formAccidentWithInjury';
import FormAccidentWithoutInjury from '../component/Navbar/Safety-SubMenus/formAccidentWithoutInjury';
import IncidentListing from '../component/Navbar/Safety-SubMenus/incidentListing';
import IncidentLog from '../component/Navbar/Safety-SubMenus/incidentLog';
import InjuriesReport from '../component/Navbar/Safety-SubMenus/injuriesReport';
// import EmployeeCustomSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeCustomSearch';
// import EmployeeSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeSearch';

import EmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/listing';
import CreateEmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/create';
import UpdateEmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/update';

import EmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/listing';
import CreateEmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/create';
import UpdateEmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/update';

import FirstAidTrainingLog from '../component/Navbar/HR-SubMenus/Employee-Management/firstAidTrainingLog';
import SearchAllEmployee from '../component/Navbar/HR-SubMenus/Employee-Management/searchAllEmployee';
import EmployeeAllDetail from '../component/Navbar/HR-SubMenus/Employee-Management/employeeAllDetail';
import UpdateUser from '../component/Navbar/HR-SubMenus/Employee-Management/updateUser';
import EmployeeDataUpdate from '../component/Navbar/HR-SubMenus/Employee-Management/employeeDataUpdate';
import UploadEmployeePhoto from '../component/Navbar/HR-SubMenus/Employee-Management/uploadEmployeePhoto';
import LocationDepartmentGroup from '../component/Navbar/HR-SubMenus/Employee-Management/locationDepartmentGroup';
import LocationDepartmentHead from '../component/Navbar/HR-SubMenus/Employee-Management/locationDepartmentHead';
import EmployeeDocumentSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeDocumentSearch';
import FirstAidDashboard from '../component/Navbar/HR-SubMenus/Employee-Management/firstAidDashboard';
import EmployeeSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeSearch';
// Emplyee master setup urls
import EmployeeMasterSetup from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employeeMasterSetup';
import EmployeeDepartmentMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-department/employeeDepartmentMaster';
import EmployeeTypeGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-type-group/employeeTypeGroupMaster';
import EmployeeTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-type/employeeTypeMaster';
import EmployeeSubTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-sub-type/employeeSubTypeMaster';
import EmployeeGradeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-grade/employeeGradeMaster';
import EmployeeCompanyMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-company/employeeCompanyMaster';
import EmployeeContractorSubBusinessMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-contractor-sub-business/employeeContractorSubBusinessMaster';
import EmployeeTargetGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-target-group/employeeTargetGroupMaster';
import EmployeeProcessTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-process-type/employeeProcessTypeMaster';
import EmployeeSkillMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-skill/employeeSkillMaster';
import EmployeeGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-group/employeeGroupMaster';
import EmployeeLegalEntityMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-legal-entity/employeeLegalEntityMaster';
import EmployeeQualificationMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-qualification/employeeQualificationMaster';
import EmployeeDesignatedPositionMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-designated-position/employeeDesignatedPositionMaster';
import EmployeeWorkLocationMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-work-location/employeeWorkLocationMaster';
import EmployeeJobTitleMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-job-title/employeeJobTitleMaster';
import EmployeeMedicalCoverage from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-medical-coverage/employeeMedicalCoverage';
import DesignationList from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/designation-list';

import AllEmployeeList from '../component/Navbar/HR-SubMenus/Report/allEmployeeList';
// import LocationWiseEmployeeList from '../component/Navbar/HR-SubMenus/Report/locationWiseEmployeeList';
import HRListing from '../component/Navbar/HR-SubMenus/Pre-Joinee-Management/hrListing';
import PreEmploymentExample from '../component/Navbar/HR-SubMenus/Pre-Joinee-Management/preEmploymentExample';
import AssignPermanentCode from '../component/Navbar/HR-SubMenus/Pre-Joinee-Management/assignPermanentCode';
import ImportOtherMaster from '../component/Navbar/HR-SubMenus/Other-Master/importOtherMaster';
import Expense from '../component/Navbar/Support-SubMenus/Expense/expense';
import ExpenseCreate from '../component/Navbar/Support-SubMenus/Expense/expenseCreate';
import UpdateExpense from '../component/Navbar/Support-SubMenus/Expense/updateExpense';
import ExpenseReport from '../component/Navbar/Support-SubMenus/Expense/expenseReport';
import AmbulanceLog from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceLog';
import AmbulanceCheck from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceCheck';
import VehicleMaintenance from '../component/Navbar/Support-SubMenus/Ambulance/vehicleMaintenance';
import VehicleMaintenanceUpdate from '../component/Navbar/Support-SubMenus/Ambulance/vehicleMaintenanceUpdate';
import VehicleReport from '../component/Navbar/Support-SubMenus/Ambulance/vehicleReport';
import AmbulanceMasterSetup from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceMasterSetup';
import AssetMaintenance from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance';
import AssetMaintenanceCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance/assetMaintenanceCreate';
import UpdateAssetMaintenance from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance/updateMaintenance';
import AssetAudit from '../component/Navbar/Support-SubMenus/AssetManagement/assetAudit';
import AssetAuditCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetAuditCreate';
import AssetAdjustment from '../component/Navbar/Support-SubMenus/AssetManagement/assetAdjustment';
import AssetAdjustmentCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetAdjustmentCreate';
import AssetMaintenanceSchedule from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenanceSchedule';
import AssetOperationalStatus from '../component/Navbar/Support-SubMenus/AssetManagement/assetOperationalStatus';
import AssetOperationalStatusCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetOperationalStatusCreate';
import AssetCheck from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheck';
import AssetMovement from '../component/Navbar/Support-SubMenus/AssetManagement/assetMovement';
import AssetMovementCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMovementCreate';
import AssetRestoration from '../component/Navbar/Support-SubMenus/AssetManagement/assetRestoration';
import AssetRestorationCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetRestorationCreate';
import AssetCheckLog from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheckLog';
import AssetCheckLogCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheckLogCreate';
import AssetIncharge from '../component/Navbar/Support-SubMenus/AssetManagement/assetIncharge';
import AssetInchargeCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetInchargeCreate';
import AssetIssueReturn from '../component/Navbar/Support-SubMenus/AssetManagement/assetIssueReturn';
import AssetCalibrationLog from '../component/Navbar/Support-SubMenus/AssetManagement/assetCalibrationLog';
import AssetCalibrationLogCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCalibrationLogCreate';
import AssetSetup from '../component/Navbar/Support-SubMenus/AssetManagement/assetSetup';
import AssetManagementReport from '../component/Navbar/Support-SubMenus/AssetManagement/assetManagementReport';
import AssetManagementMasterSetup from '../component/Navbar/Support-SubMenus/AssetManagement/assetManagementMasterSetup';
import AssetMaintenanceAlerts from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenanceAlerts';
import AssetMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaster';
import AssetMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMasterCreate';
import AssetCategoryMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetCategoryMaster';
import AssetCategoryMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCategoryMasterCreate';
import AssetLocationMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetLocationMaster';
import AssetLocationMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetLocationMasterCreate';
import BioMedicalWaste from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalWaste';
import BioMedicalWasteUpdate from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalWasteUpdate';
import BioMedicalMasterSetup from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalMasterSetup';
import BioMedicalVendorMaster from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalVendorMaster';
import BioMedicalVendorMasterCreate from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalVendorMasterCreate';
import ClaimAnalysis from '../component/Navbar/Support-SubMenus/Claim/claimAnalysis';
import HealthConnect from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnect';
import HealthConnectUpdate from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnectUpdate';
import HealthConnectReport from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnectReport';
import WellnessActivityReport from '../component/Navbar/Support-SubMenus/WellnessActivity/wellnessActivityReport';
import TicketMaster from '../component/Navbar/Support-SubMenus/Communication/ticketMaster';
import TicketMasterCreate from '../component/Navbar/Support-SubMenus/Communication/ticketMasterCreate';
import TicketMasterUpdate from '../component/Navbar/Support-SubMenus/Communication/ticketMasterUpdate';
import CanteenInspectionDetail from '../component/Navbar/Support-SubMenus/Canteen/canteenInspectionDetail';
import CanteenInspectionDetailUpdate from '../component/Navbar/Support-SubMenus/Canteen/canteenInspectionDetailUpdate';
import CanteenEmployeeAssign from '../component/Navbar/Support-SubMenus/Canteen/canteenEmployeeAssign';
import CanteenEmployeeAssignCreate from '../component/Navbar/Support-SubMenus/Canteen/canteenEmployeeAssignCreate';
import PantryInspectionSingleEntry from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntry';
import PantryInspectionSingleEntryUpdate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntryUpdate';
import PantryInspectionSingleEntryCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntryCreate';
import PantryInspectionBulk from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionBulk';
import PantryInspectionBulkCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionBulkCreate';
import CheckingParameterItem from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItem';
import EmployeeHygieneParameter from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneParameter';
import PantryItemAllocation from '../component/Navbar/Support-SubMenus/Canteen/pantryItemAllocation';
import InspectionReport from '../component/Navbar/Support-SubMenus/Canteen/inspectionReport';
import ImportPantry from '../component/Navbar/Support-SubMenus/Canteen/importPantry';
import ImportCanteen from '../component/Navbar/Support-SubMenus/Canteen/importCanteen';
import CanteenMasterSetup from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterSetup';
import CanteenMaster from '../component/Navbar/Support-SubMenus/Canteen/canteenMaster';
import CanteenMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterUpdate';
import CanteenMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterCreate';
import PantryMaster from '../component/Navbar/Support-SubMenus/Canteen/pantryMaster';
import PantryMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/pantryMasterUpdate';
import PantryMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryMasterCreate';
import EmployeeHygieneMaster from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMaster';
import EmployeeHygieneMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMasterUpdate';
import EmployeeHygieneMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMasterCreate';
import CheckingParameterMaster from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMaster';
import CheckingParameterMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMasterUpdate';
import CheckingParameterMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMasterCreate';
import CheckingParameterItemMaster from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMaster';
import CheckingParameterItemMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMasterUpdate';
import CheckingParameterItemMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMasterCreate';
import TypeMaster from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMaster';
import TypeMasterUpdate from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMasterUpdate';
import TypeMasterCreate from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMasterCreate';
import TagsMaster from '../component/Navbar/Support-SubMenus/HealthLibrary/tagsMaster';
import TagsMasterUpdate from '../component/Navbar/Support-SubMenus/HealthLibrary/tagsMasterUpdate';
import TagsMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/tagsMasterCreate";
import CategoryMaster from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMaster";
import CategoryMasterUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMasterUpdate";
import CategoryMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMasterCreate";
import SubCategoryMaster from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMaster";
import SubCategoryMasterUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMasterUpdate";
import SubCategoryMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMasterCreate";
import HealthDocumentUpload from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUpload";
import HealthDocumentUploadUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUploadUpdate";
import HealthDocumentUploadCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUploadCreate";
import CompareParameter from "../component/Navbar/MIS-SubMenus/MIS/compareParameter";
import ExaminationAllLocation from "../component/Navbar/MIS-SubMenus/MIS/examinationAllLocation";
import ChangeLocation from '../component/Navbar/AdminTool-SubMenus/Security/changeLocation';
import ChangePassword from '../component/Navbar/AdminTool-SubMenus/Security/changePassword';
import MyDashboard from '../component/Navbar/AdminTool-SubMenus/Security/myDashboard';
import UserPreference from '../component/Navbar/AdminTool-SubMenus/Security/userPreference';
import ViewReport from '../component/Navbar/AdminTool-SubMenus/Security/viewReport';
import ImportAsset from '../component/Navbar/AdminTool-SubMenus/Utilities/importAsset';
import ImportContractEmployee from '../component/Navbar/AdminTool-SubMenus/Utilities/importContractEmployee';
import ImportEmployee from '../component/Navbar/AdminTool-SubMenus/Utilities/importEmployee';
import ImportPreJoinee from '../component/Navbar/AdminTool-SubMenus/Utilities/importPreJoinee';
import ImportDependent from '../component/Navbar/AdminTool-SubMenus/Utilities/importDependent';
import ImportStockItem from '../component/Navbar/AdminTool-SubMenus/Utilities/importStockItem';
import ImportExaminationAll from '../component/Navbar/AdminTool-SubMenus/Utilities/importExaminationAll';
import ImportLocation from '../component/Navbar/AdminTool-SubMenus/Utilities/importLocation';
import ImportUsers from '../component/Navbar/AdminTool-SubMenus/Utilities/importUsers';
import ImportDocument from '../component/Navbar/AdminTool-SubMenus/Utilities/importDocument';
import UpdateEmployeeData from '../component/Navbar/AdminTool-SubMenus/Utilities/updateEmployeeData';
import UpdateStockItemData from '../component/Navbar/AdminTool-SubMenus/Utilities/updateStockItemData';
import MISDashboard from "../component/Navbar/MIS-SubMenus/MIS/misDashboard";
import MISExaminationDashboard from "../component/Navbar/MIS-SubMenus/MIS/misExaminationDashboard";
import MISOPDDashboard from "../component/Navbar/MIS-SubMenus/MIS/misOPDDashboard";
import MISReport from "../component/Navbar/MIS-SubMenus/MIS/misReport";
import DiagnosisAnalysis from "../component/Navbar/MIS-SubMenus/Diagnosis-Tracker/diagnosisAnalysis";
import DiagnosisSearch from "../component/Navbar/MIS-SubMenus/Diagnosis-Tracker/diagnosisSearch";
import ExaminationAll from "../component/Navbar/Examination-SubMenus/Examination/examinationAll";
import LetterIssue from "../component/Navbar/Examination-SubMenus/Examination/letterIssue";
import UnfitListing from "../component/Navbar/Examination-SubMenus/Examination/unfitListing";
import FitnessCertificate from "../component/Navbar/Examination-SubMenus/Examination/fitnessCertificate";
import PeriodicDashboard from "../component/Navbar/Examination-SubMenus/Examination/periodicDashboard";
import ExaminationCounselling from "../component/Navbar/Examination-SubMenus/Examination/examinationCounselling";
import PersonalDetailReport from "../component/Navbar/Examination-SubMenus/Examination-Report/personalDetailReport";
import ClinicalExaminationReport from "../component/Navbar/Examination-SubMenus/Examination-Report/clinicalExaminationReport";
import ComplianceReport from "../component/Navbar/Examination-SubMenus/Examination-Report/complianceReport";
import HealthCardReport from "../component/Navbar/Examination-SubMenus/Examination-Report/healthCardReport";
import PulmonaryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/pulmonaryReport";
import HearingReport from "../component/Navbar/Examination-SubMenus/Examination-Report/hearingReport";
import VisionReport from "../component/Navbar/Examination-SubMenus/Examination-Report/visionReport";
import DiagnosisReport from "../component/Navbar/Examination-SubMenus/Examination-Report/diagnosisReport";
import ExaminationStatusReport from "../component/Navbar/Examination-SubMenus/Examination-Report/examinationStatusReport";
import OtherExaminationReport from "../component/Navbar/Examination-SubMenus/Examination-Report/otherExaminationReport";
import ExportTestReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportTestReport";
import ExportClinicalReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportClinicalReport";
import ExportVitalReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportVitalReport";
import ExportAudiometryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportAudiometryReport";
import ExportPersonalDetailReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportPersonalDetailReport";
import ExportReports from "../component/Navbar/Examination-SubMenus/Examination-Report/exportReports";
import ExaminationSummaryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/examinationSummaryReport";
import ExaminationPlanning from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/examinationPlanning";
import FreezePeriodicAllList from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/freezePeriodicAllList";
import FreezePeriodicList from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/freezePeriodicList";
import TestDue from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/testDue";
import CriteriaGroup from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/criteriaGroup";
import SearchCriteriaGroup from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/searchCriteriaGroup";
import EmployeeFreezeStatus from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/employeeFreezeStatus";
import ExaminationVital from "../component/Navbar/Examination-SubMenus/Examination/examinationVital";
import OPD from "../component/Navbar/OPD-SubMenus/opd";
import Cashless from "../component/Navbar/MyDetails-SubMenus/Employee-Center/cashless";
import MyHealth from "../component/Navbar/MyDetails-SubMenus/Employee-Center/myHealth";
import Affiliated from "../component/Navbar/MyDetails-SubMenus/Employee-Center/affiliatedService";
import HealthContent from "../component/Navbar/MyDetails-SubMenus/Employee-Center/healthContent";
import MisExamination from "../component/Navbar/MyDetails-SubMenus/Employee-Center/misExamination";
import PrivilegeHealthService from "../component/Navbar/MyDetails-SubMenus/Employee-Center/privilegeHealthService";
import StockStatus from "../component/Navbar/Stock-SubMenus/Stock/stockStatus";
import StockBatch from "../component/Navbar/Stock-SubMenus/Stock/stockBatch";
import StockEnquiry from "../component/Navbar/Stock-SubMenus/Stock/stockEnquiry";
import StockInward from "../component/Navbar/Stock-SubMenus/Stock/stockInward";
import StockInwardRequest from "../component/Navbar/Stock-SubMenus/Stock/stockInwardRequest";
import StockInwardReturn from "../component/Navbar/Stock-SubMenus/Stock/stockInwardReturn";
import StockIssue from "../component/Navbar/Stock-SubMenus/Stock/stockIssue";
import StockIssueRequest from "../component/Navbar/Stock-SubMenus/Stock/stockIssueRequest";
import StockIssueReturn from "../component/Navbar/Stock-SubMenus/Stock/stockIssueReturn";
import StockIssueBulk from "../component/Navbar/Stock-SubMenus/Stock/stockIssueBulk";
import StockIssueCount from "../component/Navbar/Stock-SubMenus/Stock/stockIssueCount";
import OtherStockBatch from "../component/Navbar/Stock-SubMenus/Stock/otherStockBatch";
import OtherStockIssue from "../component/Navbar/Stock-SubMenus/Stock/otherStockIssue";
import StockAdjustment from "../component/Navbar/Stock-SubMenus/Stock/stockAdjustment";
import StockTransfer from "../component/Navbar/Stock-SubMenus/Stock/stockTransfer";
import StockTransferRequest from "../component/Navbar/Stock-SubMenus/Stock/stockTransferRequest";
import StockTransaction from "../component/Navbar/Stock-SubMenus/Stock/stockTransaction";
import Report from "../component/Navbar/Stock-SubMenus/Stock/report";
import ImportStockItemRestrictive from "../component/Navbar/Stock-SubMenus/Stock/importStockItemRestrictive";
import StockReset from "../component/Navbar/Stock-SubMenus/Stock/stockReset";
import StockDiscard from "../component/Navbar/Stock-SubMenus/Stock/stockDiscard";
import FirstAidInspection from "../component/Navbar/Stock-SubMenus/FirstAid/firstAidInspection";
import ImportFirstAid from "../component/Navbar/Stock-SubMenus/FirstAid/importFirstAid";
import NursingListing from "../component/Navbar/Stock-SubMenus/Pharmacy/nursingListing";
import PharmacyQueueListing from "../component/Navbar/Stock-SubMenus/Pharmacy/pharmacyQueueListing";
import PharmacyIssueReturn from "../component/Navbar/Stock-SubMenus/Pharmacy/pharmacyIssueReturn";
import SubGroupForInWholeUnit from "../component/Navbar/Stock-SubMenus/Master/subGroupForInWholeUnit";
import StockItemPlanning from "../component/Navbar/Stock-SubMenus/Master/stockItemplanning";
import StockItemParameterUpdate from "../component/Navbar/Stock-SubMenus/Master/stockItemParameterUpdate";
import StockItemDetailUpdate from "../component/Navbar/Stock-SubMenus/Master/stockItemDetailUpdate";
import PatientRegister from "../component/Navbar/AppointmentModules-SubMenus/patientRegister";
import VisitingDoctorAppointment from "../component/Navbar/AppointmentModules-SubMenus/visitingDoctorAppointment";
import PatientRegisterAnalysis from "../component/Navbar/AppointmentModules-SubMenus/patientRegisterAnalysis";
import AppointmentMasterSetup from "../component/Navbar/AppointmentModules-SubMenus/appointmentMasterSetup";
import VisitingDoctorMaster from "../component/Navbar/AppointmentModules-SubMenus/visitingDoctorMaster";
import TimeSlotSetup from "../component/Navbar/AppointmentModules-SubMenus/timeSlotSetup";
import AppointmentSetup from "../component/Navbar/AppointmentModules-SubMenus/appointmentSetup";
import PatientRegisterAnalysisAdd from "../component/Navbar/AppointmentModules-SubMenus/patientRagisterAnalysisAdd";
import OpdList from "../component/Navbar/OPD-SubMenus/opdList";
import OPDDashboard from "../component/Navbar/OPD-SubMenus/opdDashboard";
import ConsolidatedCost from "../component/Navbar/OPD-SubMenus/consolidatedCost";
import CaseReport from "../component/Navbar/OPD-SubMenus/caseReport";
import CaseSummaryReport from "../component/Navbar/OPD-SubMenus/caseSummaryReport";
import RaferralList from "../component/Navbar/OPD-SubMenus/raferralList";
import HrLanguage from 'component/Navbar/Change-language/hrLanguage';
import DiagnosticLanguage from 'component/Navbar/Change-language/diagnosticLanguage';
import ClinicalLanguage from 'component/Navbar/Change-language/clinicalLanguage';
import SafetyLanguage from 'component/Navbar/Change-language/safetyLanguage';
import LabsListing from 'component/Navbar/pre-joinee-managements/lab/labsListing';
import LabCreate from 'component/Navbar/pre-joinee-managements/lab/LabCreate';
import LabUpdate from 'component/Navbar/pre-joinee-managements/lab/LabUpdate';

export default function RoutesDefine() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />}>
                {/* Start Diagnostic => Sub-Menus */}
                <Route path="diagnostic/test-listing" element={<TestListing />} />
                <Route path="diagnostic/test-analysis" element={<TestAnalysis />} />
                <Route path="diagnostic/employee-analysis" element={<EmployeeAnalysis />} />
                {/* Report => Sub-Menus */}
                <Route path="diagnostic/report/service-cost-analysis" element={<ServiceCostAnalysis />} />
                <Route path="diagnostic/report/test-report" element={<TestReport />} />
                <Route path="diagnostic/report/cost-report" element={<CostReport />} />
                <Route path="diagnostic/report/test-list-report" element={<TestListReport />} />
                {/* end Diagnostic => Sub-Menus */}

                {/* start Clinical => Sub-Menus */}
                {/* Doctor => Sub-Menus */}
                <Route path="clinical/doctor/import-doctor" element={<ImportDoctor />} />
                <Route path="clinical/doctor/doctor-master-setup" element={<DoctorMasterSetup />} />
                {/* Medical Review => Sub-Menus */}
                <Route path="clinical/medical-review/document-search" element={<DocumentSearch />} />
                <Route path="clinical/medical-review/medical-history" element={<MedicalHistory />} />
                <Route path="clinical/medical-review" element={<MedicalReview />} />
                {/* start Safety => Sub-Menus */}
                <Route path="safety/form-accident-with-injury" element={<FormAccidentWithInjury />} />
                <Route path="safety/form-accident-without-injury" element={<FormAccidentWithoutInjury />} />
                <Route path="safety/incident-listing" element={<IncidentListing />} />
                <Route path="safety/incident-log" element={<IncidentLog />} />
                <Route path="safety/injuries-report" element={<InjuriesReport />} />
                {/* end Clinical => Sub-Menus */}

                {/* start HR => Sub-Menus */}
                {/* Employee Management => Sub-Menus */}
                {/* <Route path="hr/employee-management/employee-custom-search" element={<EmployeeCustomSearch />} />
                <Route path="hr/employee-management/employee-search" element={<EmployeeSearch />} /> */}
                <Route path="hr/employee-management/employee-transfer-in" element={<EmployeeTransferIn />} />
                <Route path="hr/employee-management/create-employee-transfer-in" element={<CreateEmployeeTransferIn />} />
                <Route path="hr/employee-management/update-employee-transfer-in/:id" element={<UpdateEmployeeTransferIn />} />

                <Route path="hr/employee-management/employee-transfer-out" element={<EmployeeTransferOut />} />
                <Route path="hr/employee-management/create-employee-transfer-out" element={<CreateEmployeeTransferOut />} />
                <Route path="hr/employee-management/update-employee-transfer-out/:id" element={<UpdateEmployeeTransferOut />} />

                <Route path="hr/employee-management/first-aid-training-log" element={<FirstAidTrainingLog />} />
                <Route path="hr/employee-management/search-all-employee" element={<SearchAllEmployee />} />
                <Route path="hr/employee-management/employee-all-detail" element={<EmployeeAllDetail />} />
                <Route path="hr/employee-management/search-all-employee/update/:id" element={<UpdateUser />} />
                <Route path="hr/employee-management/employee-data-update" element={<EmployeeDataUpdate />} />
                <Route path="hr/employee-management/upload-employee-photo" element={<UploadEmployeePhoto />} />
                <Route path="hr/employee-management/location-department-group" element={<LocationDepartmentGroup />} />
                <Route path="hr/employee-management/location-department-head" element={<LocationDepartmentHead />} />
                <Route path="hr/employee-management/employee-document-search" element={<EmployeeDocumentSearch />} />
                <Route path="hr/employee-management/first-aid-dashboard" element={<FirstAidDashboard />} />
                <Route path="hr/employee-management/employee-search" element={<EmployeeSearch />} />

                {/* Employee master setup */}
                <Route path="hr/employee-management/employee-master-setup" element={<EmployeeMasterSetup />} />
                <Route path="hr/employee-management/employee-master-setup/employee-department-master" element={<EmployeeDepartmentMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-type-group-master" element={<EmployeeTypeGroupMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-type-master" element={<EmployeeTypeMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-sub-type-master" element={<EmployeeSubTypeMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-grade-master" element={<EmployeeGradeMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-company-master" element={<EmployeeCompanyMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-contractor-sub-business-master" element={<EmployeeContractorSubBusinessMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-target-group-master" element={<EmployeeTargetGroupMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-process-type-master" element={<EmployeeProcessTypeMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-skill-master" element={<EmployeeSkillMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-group-master" element={<EmployeeGroupMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-legal-entity-master" element={<EmployeeLegalEntityMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-qualification-master" element={<EmployeeQualificationMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-designated-position" element={<EmployeeDesignatedPositionMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-work-location" element={<EmployeeWorkLocationMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-job-title" element={<EmployeeJobTitleMaster />} />
                <Route path="hr/employee-management/employee-master-setup/employee-medical-coverage" element={<EmployeeMedicalCoverage />} />
                <Route path="hr/employee-management/employee-master-setup/designation-list" element={<DesignationList />} />

                {/* Report => Sub-Menus */}
                <Route path="hr/report/all-employee-list" element={<AllEmployeeList />} />
                {/* <Route path="hr/report/location-wise-employee-list" element={<LocationWiseEmployeeList />} /> */}
                {/* Other Master => Sub-Menus */}
                <Route path="hr/other-master/import-other-master" element={<ImportOtherMaster />} />
                {/* end HR => Sub-Menus */}

                {/* <Route path="hr/report/location-wise-employee-list" element={<LocationWiseEmployeeList />} /> */}
                {/* Pre-Joinee Management => Sub-Menus */}
                <Route path="hr/pre-joinee-management/hr-listing" element={<HRListing />} />
                <Route path="hr/pre-joinee-management/pre-employment-example" element={<PreEmploymentExample />} />
                <Route path="hr/pre-joinee-management/assign-permanent-code" element={<AssignPermanentCode />} />
                <Route path="pre-joinee-management/labs" element={<LabsListing />} />
                <Route path="pre-joinee-management/labs/create" element={<LabCreate />} />
                <Route path="pre-joinee-management/labs/update/:id" element={<LabUpdate />} />
                {/* End Pre-Joinee Management => Sub-Menus */}

                {/* start Support => Sub-Menus */}
                {/* Expense => Sub-Menus */}
                <Route path="support/expense" element={<Expense />} />
                <Route path="support/expense/create" element={<ExpenseCreate />} />
                <Route path="support/expense/update/:id" element={<UpdateExpense />} />
                <Route path="support/expense/expense-report" element={<ExpenseReport />} />
                {/* Ambulance => Sub-Menus */}
                <Route path="support/ambulance/ambulance-log" element={<AmbulanceLog />} />
                <Route path="support/ambulance/ambulance-check" element={<AmbulanceCheck />} />
                <Route path="support/ambulance/vehicle-maintenance" element={<VehicleMaintenance />} />
                <Route path="support/ambulance/vehicle-maintenance/update" element={<VehicleMaintenanceUpdate />} />
                <Route path="support/ambulance/vehicle-report" element={<VehicleReport />} />
                <Route path="support/ambulance/ambulance-master-setup" element={<AmbulanceMasterSetup />} />
                {/* Asset Management => Sub-Menus */}
                <Route path="support/asset-management/asset-maintenance" element={<AssetMaintenance />} />
                <Route path="support/asset-management/asset-maintenance/create" element={<AssetMaintenanceCreate />} />
                <Route path="support/asset-management/asset-maintenance/update/:id" element={<UpdateAssetMaintenance />} />
                <Route path="support/asset-management/asset-audit" element={<AssetAudit />} />
                <Route path="support/asset-management/asset-audit/create" element={<AssetAuditCreate />} />
                <Route path="support/asset-management/asset-adjustment" element={<AssetAdjustment />} />
                <Route path="support/asset-management/asset-adjustment/create" element={<AssetAdjustmentCreate />} />
                <Route path="support/asset-management/asset-maintenance-schedule" element={<AssetMaintenanceSchedule />} />
                <Route path="support/asset-management/asset-operational-status" element={<AssetOperationalStatus />} />
                <Route path="support/asset-management/asset-operational-status/create" element={<AssetOperationalStatusCreate />} />
                <Route path="support/asset-management/asset-check" element={<AssetCheck />} />
                <Route path="support/asset-management/asset-movement" element={<AssetMovement />} />
                <Route path="support/asset-management/asset-movement/create" element={<AssetMovementCreate />} />
                <Route path="support/asset-management/asset-restoration" element={<AssetRestoration />} />
                <Route path="support/asset-management/asset-restoration/create" element={<AssetRestorationCreate />} />
                <Route path="support/asset-management/asset-check-log" element={<AssetCheckLog />} />
                <Route path="support/asset-management/asset-check-log/create" element={<AssetCheckLogCreate />} />
                <Route path="support/asset-management/asset-incharge" element={<AssetIncharge />} />
                <Route path="support/asset-management/asset-incharge/create" element={<AssetInchargeCreate />} />
                <Route path="support/asset-management/asset-issue-return" element={<AssetIssueReturn />} />
                <Route path="support/asset-management/asset-calibration-log" element={<AssetCalibrationLog />} />
                <Route path="support/asset-management/asset-calibration-log/create" element={<AssetCalibrationLogCreate />} />
                <Route path="support/asset-management/asset-setup" element={<AssetSetup />} />
                <Route path="support/asset-management/asset-management-report" element={<AssetManagementReport />} />
                <Route path="support/asset-management/asset-management-master-setup" element={<AssetManagementMasterSetup />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-maintenance-alerts" element={<AssetMaintenanceAlerts />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-master" element={<AssetMaster />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-master/create" element={<AssetMasterCreate />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-category-master" element={<AssetCategoryMaster />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-category-master/create" element={<AssetCategoryMasterCreate />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-location-master" element={<AssetLocationMaster />} />
                <Route path="/dashboard/support/asset-management/asset-management-master-setup/asset-location-master/create" element={<AssetLocationMasterCreate />} />
                {/* Bio Medical Waste => Sub-Menus */}
                <Route path="support/bio-medical-waste" element={<BioMedicalWaste />} />
                <Route path="support/bio-medical-waste/update" element={<BioMedicalWasteUpdate />} />
                <Route path="support/bio-medical-waste/bio-medical-master-setup" element={<BioMedicalMasterSetup />} />
                <Route path="support/bio-medical-waste/bio-medical-master-setup/bio-medical-vendor-master" element={<BioMedicalVendorMaster />} />
                <Route path="support/bio-medical-waste/bio-medical-master-setup/bio-medical-vendor-master/create" element={<BioMedicalVendorMasterCreate />} />
                {/* Claim => Sub-Menus */}
                <Route path="support/claim/claim-analysis" element={<ClaimAnalysis />} />
                {/* Health Connect => Sub-Menus */}
                <Route path="support/health-connect" element={<HealthConnect />} />
                <Route path="support/health-connect/update" element={<HealthConnectUpdate />} />
                <Route path="support/health-connect/health-connect-report" element={<HealthConnectReport />} />
                {/* Wellness Activity => Sub-Menus */}
                <Route path="support/wellness-activity/wellness-activity-report" element={<WellnessActivityReport />} />
                {/* Communication => Sub-Menus */}
                <Route path="support/communication/ticket-master" element={<TicketMaster />} />
                <Route path="support/communication/ticket-master/create" element={<TicketMasterCreate />} />
                <Route path="support/communication/ticket-master/update" element={<TicketMasterUpdate />} />
                {/* Canteen => Sub-Menus */}
                <Route path="support/canteen/canteen-inspection-detail" element={<CanteenInspectionDetail />} />
                <Route path="support/canteen/canteen-inspection-detail/update" element={<CanteenInspectionDetailUpdate />} />
                <Route path="support/canteen/canteen-employee-assign" element={<CanteenEmployeeAssign />} />
                <Route path="support/canteen/canteen-employee-assign/create" element={<CanteenEmployeeAssignCreate />} />
                <Route path="support/canteen/pantry-inspection-single-entry" element={<PantryInspectionSingleEntry />} />
                <Route path="support/canteen/pantry-inspection-single-entry/update" element={<PantryInspectionSingleEntryUpdate />} />
                <Route path="support/canteen/pantry-inspection-single-entry/create" element={<PantryInspectionSingleEntryCreate />} />
                <Route path="support/canteen/pantry-inspection-bulk" element={<PantryInspectionBulk />} />
                <Route path="support/canteen/pantry-inspection-bulk/create" element={<PantryInspectionBulkCreate />} />
                <Route path="support/canteen/checking-parameter-item" element={<CheckingParameterItem />} />
                <Route path="support/canteen/employee-hygiene-parameter" element={<EmployeeHygieneParameter />} />
                <Route path="support/canteen/pantry-item-allocation" element={<PantryItemAllocation />} />
                <Route path="support/canteen/inspection-report" element={<InspectionReport />} />
                <Route path="support/canteen/import-pantry" element={<ImportPantry />} />
                <Route path="support/canteen/import-canteen" element={<ImportCanteen />} />
                <Route path="support/canteen/canteen-master-setup" element={<CanteenMasterSetup />} />
                <Route path="support/canteen/canteen-master" element={<CanteenMaster />} />
                <Route path="support/canteen/canteen-master/update" element={<CanteenMasterUpdate />} />
                <Route path="support/canteen/canteen-master/create" element={<CanteenMasterCreate />} />
                <Route path="support/canteen/pantry-master" element={<PantryMaster />} />
                <Route path="support/canteen/pantry-master/update" element={<PantryMasterUpdate />} />
                <Route path="support/canteen/pantry-master/create" element={<PantryMasterCreate />} />
                <Route path="support/canteen/employee-hygiene-master" element={<EmployeeHygieneMaster />} />
                <Route path="support/canteen/employee-hygiene-master/update" element={<EmployeeHygieneMasterUpdate />} />
                <Route path="support/canteen/employee-hygiene-master/create" element={<EmployeeHygieneMasterCreate />} />
                <Route path="support/canteen/checking-parameter-master" element={<CheckingParameterMaster />} />
                <Route path="support/canteen/checking-parameter-master/update" element={<CheckingParameterMasterUpdate />} />
                <Route path="support/canteen/checking-parameter-master/create" element={<CheckingParameterMasterCreate />} />
                <Route path="support/canteen/checking-parameter-item-master" element={<CheckingParameterItemMaster />} />
                <Route path="support/canteen/checking-parameter-item-master/update" element={<CheckingParameterItemMasterUpdate />} />
                <Route path="support/canteen/checking-parameter-item-master/create" element={<CheckingParameterItemMasterCreate />} />
                {/* Health Library => Sub-Menus */}
                <Route path="support/health-library/type-master" element={<TypeMaster />} />
                <Route path="support/health-library/type-master/update" element={<TypeMasterUpdate />} />
                <Route path="support/health-library/type-master/create" element={<TypeMasterCreate />} />
                <Route path="support/health-library/tags-master" element={<TagsMaster />} />
                <Route path="support/health-library/tags-master/update" element={<TagsMasterUpdate />} />
                <Route path="support/health-library/tags-master/create" element={<TagsMasterCreate />} />
                <Route path="support/health-library/category-Master" element={<CategoryMaster />} />
                <Route path="support/health-library/category-Master/update" element={<CategoryMasterUpdate />} />
                <Route path="support/health-library/category-Master/create" element={<CategoryMasterCreate />} />
                <Route path="support/health-library/sub-category-master" element={<SubCategoryMaster />} />
                <Route path="support/health-library/sub-category-master/update" element={<SubCategoryMasterUpdate />} />
                <Route path="support/health-library/sub-category-master/create" element={<SubCategoryMasterCreate />} />
                <Route path="support/health-library/sub-category-master/create" element={<SubCategoryMasterCreate />} />
                <Route path="support/health-library/health-document-upload" element={<HealthDocumentUpload />} />
                <Route path="support/health-library/health-document-upload/update" element={<HealthDocumentUploadUpdate />} />
                <Route path="support/health-library/health-document-upload/create" element={<HealthDocumentUploadCreate />} />
                {/* end Support => Sub-Menus */}

                {/* start MIS => Sub-Menus */}
                {/* MIS => Sub-Menus */}
                <Route path="mis/compare-parameter" element={<CompareParameter />} />
                <Route path="mis/examination-all-location" element={<ExaminationAllLocation />} />
                <Route path="mis/mis-dashboard" element={<MISDashboard />} />
                <Route path="mis/mis-examination-dashboard" element={<MISExaminationDashboard />} />
                <Route path="mis/mis-opd-dashboard" element={<MISOPDDashboard />} />
                <Route path="mis/mis-report" element={<MISReport />} />
                {/* Diagnosis Tracker => Sub-Menus */}
                <Route path="mis/diagnosis-tracker/diagnosis-analysis" element={<DiagnosisAnalysis />} />
                <Route path="mis/diagnosis-tracker/diagnosis-search" element={<DiagnosisSearch />} />
                {/* end MIS => Sub-Menus */}

                {/* start Admin Tool => Sub-Menus */}
                {/* Security => Sub-Menus */}
                <Route path="admin-tool/security/user-permission" element={<UserPermission />} />
                <Route path="admin-tool/security/user-role" element={<UserRole />} />
                <Route path="admin-tool/security/change-location" element={<ChangeLocation />} />
                <Route path="admin-tool/security/change-password" element={<ChangePassword />} />
                <Route path="admin-tool/security/my-dashboard" element={<MyDashboard />} />
                <Route path="admin-tool/security/user-preference" element={<UserPreference />} />
                <Route path="admin-tool/security/view-report" element={<ViewReport />} />
                {/* Utilities => Sub-Menus */}
                <Route path="admin-tool/utilities/import-asset" element={<ImportAsset />} />
                <Route path="admin-tool/utilities/import-contract-employee" element={<ImportContractEmployee />} />
                <Route path="admin-tool/utilities/import-employee" element={<ImportEmployee />} />
                <Route path="admin-tool/utilities/import-pre-joinee" element={<ImportPreJoinee />} />
                <Route path="admin-tool/utilities/import-dependent" element={<ImportDependent />} />
                <Route path="admin-tool/utilities/import-stock-item" element={<ImportStockItem />} />
                <Route path="admin-tool/utilities/import-examination-all" element={<ImportExaminationAll />} />
                <Route path="admin-tool/utilities/import-location" element={<ImportLocation />} />
                <Route path="admin-tool/utilities/import-users" element={<ImportUsers />} />
                <Route path="admin-tool/utilities/import-document" element={<ImportDocument />} />
                <Route path="admin-tool/utilities/update-employee-data" element={<UpdateEmployeeData />} />
                <Route path="admin-tool/utilities/update-stock-item-data" element={<UpdateStockItemData />} />
                {/* end Admin Tool => Sub-Menus */}

                {/* start Examination => Sub-Menus */}
                {/* Examination => Sub-Menus */}
                <Route path="examination/examination-all" element={<ExaminationAll />} />
                <Route path="examination/examination-all/examination-vital" element={<ExaminationVital />} />
                <Route path="examination/letter-issue" element={<LetterIssue />} />
                <Route path="examination/unfit-listing" element={<UnfitListing />} />
                <Route path="examination/fitness-certificate" element={<FitnessCertificate />} />
                <Route path="examination/periodic-dashboard" element={<PeriodicDashboard />} />
                <Route path="examination/examination-counselling" element={<ExaminationCounselling />} />
                {/* Examination-Report => Sub-Menus */}
                <Route path="examination/examination-report/personal-detail-report" element={<PersonalDetailReport />} />
                <Route path="examination/examination-report/clinical-examination-report" element={<ClinicalExaminationReport />} />
                <Route path="examination/examination-report/compliance-report" element={<ComplianceReport />} />
                <Route path="examination/examination-report/health-card-report" element={<HealthCardReport />} />
                <Route path="examination/examination-report/pulmonary-report" element={<PulmonaryReport />} />
                <Route path="examination/examination-report/hearing-report" element={<HearingReport />} />
                <Route path="examination/examination-report/vision-report" element={<VisionReport />} />
                <Route path="examination/examination-report/diagnosis-report" element={<DiagnosisReport />} />
                <Route path="examination/examination-report/examination-status-report" element={<ExaminationStatusReport />} />
                <Route path="examination/examination-report/other-examination-report" element={<OtherExaminationReport />} />
                <Route path="examination/examination-report/export-test-report" element={<ExportTestReport />} />
                <Route path="examination/examination-report/export-clinical-report" element={<ExportClinicalReport />} />
                <Route path="examination/examination-report/export-vital-report" element={<ExportVitalReport />} />
                <Route path="examination/examination-report/export-audiometry-report" element={<ExportAudiometryReport />} />
                <Route path="examination/examination-report/export-personal-detail-report" element={<ExportPersonalDetailReport />} />
                <Route path="examination/examination-report/export-reports" element={<ExportReports />} />
                <Route path="examination/examination-report/examination-summary-report" element={<ExaminationSummaryReport />} />
                {/* Freeze-Periodic-List => Sub-Menus */}
                <Route path="examination/freeze-periodic-list/examination-planning" element={<ExaminationPlanning />} />
                <Route path="examination/freeze-periodic-list/freeze-periodic-all-list" element={<FreezePeriodicAllList />} />
                <Route path="examination/freeze-periodic-list/freeze-periodic-list" element={<FreezePeriodicList />} />
                <Route path="examination/freeze-periodic-list/test-due" element={<TestDue />} />
                <Route path="examination/freeze-periodic-list/criteria-group" element={<CriteriaGroup />} />
                <Route path="examination/freeze-periodic-list/search-criteria-group" element={<SearchCriteriaGroup />} />
                <Route path="examination/freeze-periodic-list/employee-freeze-status" element={<EmployeeFreezeStatus />} />
                {/* end Examination => Sub-Menus */}

                {/* start OPD => Sub-Menus */}
                {/* OPD => Sub-Menus */}
                <Route path="opd" element={<OPD />} />
                <Route path="opd/opd-list" element={<OpdList />} />
                <Route path="opd/opd-dashboard" element={<OPDDashboard />} />
                <Route path="opd/consolidated-cost" element={<ConsolidatedCost />} />
                <Route path="opd/case-report" element={<CaseReport />} />
                <Route path="opd/case-summary-report" element={<CaseSummaryReport />} />
                <Route path="opd/referral-list" element={<RaferralList />} />
                {/* end OPD => Sub-Menus */}

                {/* start Appointment Module => Sub-Menus */}
                {/* Appoinment => Sub-Menus */}
                <Route path="appointment-module/patient-register" element={<PatientRegister />} />
                <Route path="appointment-module/visiting-doctor-appointment" element={<VisitingDoctorAppointment />} />
                <Route path="appointment-module/patient-register-analysis" element={<PatientRegisterAnalysis />} />
                <Route path="appointment-module/appointment-master-setup" element={<AppointmentMasterSetup />} />
                <Route path="appointment-module/appointment-master-setup/patient-register-analysis-add" element={<PatientRegisterAnalysisAdd />} />
                <Route path="appointment-module/appointment-master-setup/visiting-doctor-master" element={<VisitingDoctorMaster />} />
                <Route path="appointment-module/appointment-master-setup/time-slot-setup" element={<TimeSlotSetup />} />
                <Route path="appointment-module/appointment-master-setup/appointment-setup" element={<AppointmentSetup />} />
                {/* end Appointment Module => Sub-Menus */}

                {/* start My Details => Sub-Menus */}
                {/* Employee Center => Sub-Menus */}
                <Route path="my-details/employee-center/cashless" element={<Cashless />} />
                <Route path="my-details/employee-center/my-health" element={<MyHealth />} />
                <Route path="my-details/employee-center/affiliated-service" element={<Affiliated />} />
                <Route path="my-details/employee-center/health-content" element={<HealthContent />} />
                <Route path="my-details/employee-center/mis-Examination" element={<MisExamination />} />
                <Route path="my-details/employee-center/privilege-Health-Service" element={<PrivilegeHealthService />} />
                {/* end My Details => Sub-Menus */}
                <Route path="stock/stock-status" element={<StockStatus />} />
                <Route path="stock/stock-batch" element={<StockBatch />} />
                <Route path="stock/stock-enquiry" element={<StockEnquiry />} />
                <Route path="stock/stock-inward" element={<StockInward />} />
                <Route path="stock/stock-inward-request" element={<StockInwardRequest />} />
                <Route path="stock/stock-inward-return" element={<StockInwardReturn />} />
                <Route path="stock/stock-issue" element={<StockIssue />} />
                <Route path="stock/stock-issue-request" element={<StockIssueRequest />} />
                <Route path="stock/stock-issue-return" element={<StockIssueReturn />} />
                <Route path="stock/stock-issue-bulk" element={<StockIssueBulk />} />
                <Route path="stock/stock-issue-count" element={< StockIssueCount />} />
                <Route path="stock/other-stock-batch" element={<OtherStockBatch />} />
                <Route path="stock/other-stock-issue" element={<OtherStockIssue />} />
                <Route path="stock/stock-adjustment" element={<StockAdjustment />} />
                <Route path="stock/stock-transfer" element={<StockTransfer />} />
                <Route path="stock/stock-transfer-request" element={<StockTransferRequest />} />
                <Route path="stock/stock-transaction" element={<StockTransaction />} />
                <Route path="stock/report" element={<Report />} />
                <Route path="stock/import-stock-item-restrictive" element={<ImportStockItemRestrictive />} />
                <Route path="stock/stock-reset" element={<StockReset />} />
                <Route path="stock/stock-discard" element={<StockDiscard />} />
                <Route path="stock/first-aid/first-aid-inspection" element={<FirstAidInspection />} />
                <Route path="stock/first-aid/import-first-aid" element={<ImportFirstAid />} />
                <Route path="stock/pharmacy/nursing-listing" element={<NursingListing />} />
                <Route path="stock/pharmacy/pharmacy-queue-listing" element={<PharmacyQueueListing />} />
                <Route path="stock/pharmacy/pharmacy-issue-return" element={<PharmacyIssueReturn />} />
                <Route path="stock/master/sub-group-for-in-whole-unit" element={<SubGroupForInWholeUnit />} />
                <Route path="stock/master/stock-item-planning" element={<StockItemPlanning />} />
                <Route path="stock/master/stock-item-parameter-update" element={<StockItemParameterUpdate />} />
                <Route path="stock/master/stock-item-detail-update" element={<StockItemDetailUpdate />} />

                <Route path="change-language/diagnostic" element={<DiagnosticLanguage />} />
                <Route path="change-language/clinical" element={<ClinicalLanguage />} />
                <Route path="change-language/safety" element={<SafetyLanguage />} />
                { /* Incident Log Injuries Report menu pending */ }
                <Route path="change-language/hr" element={<HrLanguage />} />
                
            </Route>
        </Routes>
    );
}