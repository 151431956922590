import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { ApiConfig } from '../../../../api/config/ApiConfig';
import { GetExpenses, DeleteExpense } from '../../../../api/expense';
import { useSnackbar } from 'notistack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar'; 
import styles from 'css/styles';
import PaginationControls from '../../../Comman/paginationControls';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import PageSizeSelector from 'component/Comman/PageSizeSelector';

const {
    React,
    Box,
    Grid,
    Select,
    Button,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    FormControl,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    TableCell,
    tableCellClasses,
    UnfoldMoreOutlinedIcon,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
} = sharedImports;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Expense() {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({ fromDate: null, toDate: null, expenseGroup: '' });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { baseUrl, listAllExpense } = ApiConfig;

    const fetchInfo = async () => {
        try {
            const page=pagination.pageIndex+1;
            const per_page=pagination.pageSize;
            
            const url = new URL(
            listAllExpense,
            baseUrl,
            );
            url.searchParams.set(
            'page',
            `${page}`,
            );
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            url.searchParams.set('fromDate', filters.fromDate ?? '');
            url.searchParams.set('toDate', filters.toDate ?? '');
            url.searchParams.set('expenseGroup', filters.expenseGroup ?? '');
            const response = await GetExpenses(url);
            if (response.status) {
                const { data, totalCount } = await response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }   

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });
        
        fetchInfo();
    };

    const handleDeleteRow = async (id) => {
        try {
            const response = await DeleteExpense(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setData((prevData) => prevData.filter((item) => item.id !== id));
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    };

    const handleExport = () => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'expenses.csv');
    };

    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    const handleSearch = () => {
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };

    const handleClear = () => {
        setFilters({ fromDate: null, toDate: null, expenseGroup: '' });
        setPagination(prev => ({ ...prev, pageIndex: 0 }));
        fetchInfo();
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBarWithAddButton appBarTitle="Expense" handleUrl="/dashboard/support/expense/create"/>

                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker
                                                    value={filters.fromDate}
                                                    onChange={(newValue) => setFilters(prev => ({ ...prev, fromDate: newValue }))}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker
                                                    value={filters.toDate}
                                                    onChange={(newValue) => setFilters(prev => ({ ...prev, toDate: newValue }))}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Expense Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ExpenseGroup"
                                            value={filters.expenseGroup}
                                            onChange={(e) => setFilters(prev => ({ ...prev, expenseGroup: e.target.value }))}
                                            displayEmpty
                                        >
                                            <MenuItem sx={{ml: 3}} value="">-All-</MenuItem>
                                            <MenuItem sx={{ml: 3}} value="1">Male nurse salary</MenuItem>
                                            <MenuItem sx={{ml: 3}} value="2">MEDICINE</MenuItem>
                                            <MenuItem sx={{ml: 3}} value="3">Other expense</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} onClick={handleSearch} sx={{ mr: 0.5, mb: 2 }}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} onClick={handleClear} sx={{ ml: 0.5, mb: 2 }}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                    
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={ (e) => setGlobalFilter(e.target.value) }
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell onClick={() => handleSort('expense_date')}>
                                                Expense Date
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('expense_group')}>
                                                Expense Group
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('expense_sub_group')}>
                                                Expense Sub Group
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('remark')}>
                                                Remark
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('amount')}>
                                                Amount
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.map((row) => (
                                            <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                <StyledTableCell> {row.expense_date} </StyledTableCell>
                                                <StyledTableCell>{row.expense_group}</StyledTableCell>
                                                <StyledTableCell>{row.expense_sub_group}</StyledTableCell>
                                                <StyledTableCell>{row.remark}</StyledTableCell>
                                                <StyledTableCell>{row.amount}</StyledTableCell>
                                                <StyledTableCell>
                                                    <EditOutlinedIcon onClick={() => navigate(`update/${row.id}`)} />
                                                    <DeleteButton id={row.id} fieldName={row.remark} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.id)} titleName="Employee Work Location" />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}