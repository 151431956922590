import { useEffect, useState } from 'react';
import { ApiConfig } from '../../../../../../api/config/ApiConfig';
import { useSnackbar } from 'notistack';
import { GetEmployeeSubType, InsertCreateEmployeeSubType, SingleList, UpdateEmployeeSubType, DeleteEmployeeSubType } from '../../../../../../api/employee-master-setup/employeeSubType';
import { fetchEmployeeTypeGroup, fetchEmployeeType } from '../../../../../../helper/employee/employeeHelpers';
import PaginationControls from '../../../../../Comman/paginationControls';
import sharedImports from '../../../../../Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from '../../../../../Comman/DeleteButton/DeleteButton';
import {CommanAppBar} from '../../../../../Comman/commanAppBar';
import styles from 'css/styles';
import PageSizeSelector from '../../../../../Comman/PageSizeSelector';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
const {
    React,
    Box,
    Grid,
    Button,
    TextField,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Autocomplete,
} = sharedImports;

export default function EmployeeSubTypeMaster() {
    const [data, setData] = useState([]);
    const [employeeTypeGroup, setEmployeeTypeGroup] = useState([]);
    const [employeeType, setEmployeeType] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        employee_sub_type_id: '',
        code: '',
        name: '',
        employee_type_group: '',
        employee_type: '',
        inactive: '',
    });
    
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { baseUrl, listAllEmployeeSubType } = ApiConfig;

    const fetchInfo = async () => {
        try{
            const page=pagination.pageIndex+1;
            const per_page=pagination.pageSize;
            
            const url = new URL(
            listAllEmployeeSubType,
            baseUrl,
            );
            url.searchParams.set(
            'page',
            `${page}`,
            );
            url.searchParams.set('per_page', `${per_page}`);
            url.searchParams.set('globalFilter', globalFilter ?? '');
            url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            const response = await GetEmployeeSubType(url);
            
            if (response.status) {
                const { allEmployeeSubType, count } = await response?.data;
                setData(allEmployeeSubType);
                setRowCount(count);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch(err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }
    
    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.employee_sub_type_id === columnId);            
            
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ employee_sub_type_id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ employee_sub_type_id: columnId, desc: false }];
            }
        });
        
        fetchInfo();
    };

    const handleClickOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData('');
        fetchEmployeeTypeGroup(setEmployeeTypeGroup);
        fetchEmployeeType(setEmployeeType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                response = await UpdateEmployeeSubType(formData);
            } else {
                const newData = { ...formData };                
                response = await InsertCreateEmployeeSubType(newData);
            }

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                setOpen(false);
                fetchInfo();
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            console.log("errro", error);
            
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }
    
    const SingleListData = async (id) => {
        try {
          const response = await SingleList(id);     
          setFormData(response.data);
          setIsEditing(true);
          setOpen(true);
          fetchEmployeeTypeGroup(setEmployeeTypeGroup);
          fetchEmployeeType(setEmployeeType);
        } catch (error) {
          console.error('Error fetching job data:', error);
        }
    };

    const handleDeleteRow = async (id) => {
        try {
            const response = await DeleteEmployeeSubType(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchInfo();
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting])

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Employee Sub Type Master" showButtons={true} handleClickOpen={handleClickOpen} backButtonPath="/dashboard/hr/employee-management/employee-master-setup"/>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>{isEditing ? 'Update' : 'Add'} Employee Sub Type</DialogTitle>
                        <DialogContent>
                            <TextField
                                required
                                margin="dense"
                                id="code"
                                label="Code"
                                name="code"
                                value={formData.code}
                                onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name="name"
                                value={formData.name || ''}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <FormControl fullWidth margin="dense" sx={{ width: '500px' }} variant="standard">
                                {/* <InputLabel id="employee_type_group_label">Employee Type Group</InputLabel> */}
                                <Autocomplete
                                    required
                                    id="employee_type_group"
                                    name="employee_type_group"
                                    options={employeeTypeGroup}
                                    sx={{mt: 1}}
                                    getOptionLabel={(option) => option.name}
                                    value={employeeTypeGroup.find(group => group.employee_type_group_id === Number(formData.employee_type_group)) || null}
                                    onChange={(event, newValue) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            employee_type_group: newValue ? newValue.employee_type_group_id : ''
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Type Group"
                                            variant="standard"
                                            required
                                            placeholder="-Select-"
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense" sx={{ width: '500px' }} variant="standard">
                                {/* <InputLabel id="employee_type_label">Employee Type</InputLabel> */}
                                <Autocomplete
                                    required
                                    id="employee_type"
                                    name="employee_type"
                                    sx={{mt: 1}}
                                    options={employeeType}
                                    getOptionLabel={(option) => option.name}
                                    value={employeeType.find(type => type.employee_type_id === Number(formData.employee_type)) || null}
                                    onChange={(event, newValue) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            employee_type: newValue ? newValue.employee_type_id : ''
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Type"
                                            variant="standard"
                                            required
                                            placeholder="-Select-"
                                        />
                                    )}
                                />
                            </FormControl>                   
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.inactive === 1}
                                        onChange={(e) => setFormData(prev => ({ ...prev, inactive: e.target.checked ? 1 : 0 }))}
                                        name="inactive"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                            <Button sx={{ textTransform: 'none' }} type="save" onClick={handleSubmit}>{isEditing ? 'Update' : 'Save'}</Button>
                        </DialogActions>
                    </Dialog>

                    <Box sx={{ mt: 7, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.EmployeeMasterSetupTableRow}>
                                            <StyledTableCell onClick={() => handleSort('employee_sub_type_id')}>
                                                ID
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('code')}>
                                                Code
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('name')}>
                                                Name
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Employee Type
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Employee Type Group
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.employee_sub_type_id} sx={styles.EmployeeMasterSetupTableRow}>
                                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.employee_type_name}</StyledTableCell>
                                                    <StyledTableCell>{row.employee_type_group_name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <EditOutlinedIcon onClick={() => SingleListData(row.employee_sub_type_id)} sx={{ cursor: 'pointer' }} />
                                                        <DeleteButton id={row.employee_sub_type_id} fieldName={row.name} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.employee_sub_type_id)} titleName="Employee Sub Type" />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}