import LabForm from "./form"

const LabCreate = () => {
    return (
    <>
      <LabForm />
    </>  
  )
}

export default LabCreate
