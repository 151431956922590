import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Grid,
    Card,
    Slide,
    Table,
    styled,
    Toolbar,
    PropTypes,
    TableRow,
    TableHead,
    TableCell,
    Typography,
    CardContent,
    useScrollTrigger,
    TableContainer,
    tableCellClasses,
    TableBody,

} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { Page: 'Stock Register Report', },
    { Page: 'Stock Transaction Report', },
    { Page: 'Other Stock Consumption Report', },
    { Page: 'Stock Issue Report', },
    { Page: 'Stock Report', },
];

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}




export default function DenseAppBar(props) {
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Report
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 5,
                        mb: 5,
                        width: "100%",
                    },
                }}
            >
                <Card variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>

                            </CardContent>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ pt: '0px !important', ml: 4, mr: 4, mt: 3, mb: 4 }}>
                            <CardContent sx={{ pt: '5px !important' }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>

                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Page</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.Page} sx={styles.TableRow}>

                                                    <StyledTableCell sx={{ color: '#1976d2' }}>{row.Page}</StyledTableCell>

                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>

        </>
    )
} 