import { importLocation } from 'api/employee-master-setup/employeeWorkLocation';
import { useSnackbar } from 'notistack';
import { isExcelFile } from 'helper/index';
import sharedImports from 'component/Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    Typography,
    TextField,
    CssBaseline,
    useScrollTrigger,
    CheckOutlinedIcon,
    FileUploadOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportLocation(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [file, setFile] = React.useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        // Check if the file is an Excel file
        if (selectedFile && isExcelFile(selectedFile)) {
            setFile(selectedFile);
        } else {
            enqueueSnackbar("Please select an Excel file (.xlsx or .xls)", { variant: "warning" });
            setFile(null);
        }
    };

    const handleFileUpload = async () => {
        try {
            if (!file) {
                enqueueSnackbar("Please select a file", { variant: "warning" });
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const response = await importLocation(formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
            } else {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Import Location
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component='div'>
                            <Button sx={{ textTransform: 'none' }} href="/templates/Import_location_template.xls" download>Download Template <Box component='span' sx={{ color: 'red' }}>*</Box></Button>
                        </Box>
                        {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    placeholder='Copy and paste data here'
                                    multiline
                                    fullWidth
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                            <Grid item>
                                <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<CheckOutlinedIcon />}>Validate</Button>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
                            <Button
                                variant="contained"
                                onClick={() => document.getElementById('fileInput').click()}
                                startIcon={<FileUploadOutlinedIcon />}
                                sx={{ mr: 3 }}
                            >
                                Select File
                            </Button>
                            <Button variant='contained' onClick={handleFileUpload} sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />}> Import </Button>
                        </Grid>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}