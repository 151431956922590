import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Link,
    clsx,
    alpha,
    styled,
    useTheme,
    Typography,
    SupportIcon,
    useTreeItem,
    TreeItem2Icon,
    TreeItem2Root,
    ArrowRightIcon,
    SimpleTreeView,
    TreeItem2Content,
    ArrowDropDownIcon,
    TreeItem2Provider,
    SaveAsOutlinedIcon,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    ArrowRightAltOutlinedIcon,
} = sharedImports;

const expense = [
    { id: 3, label: 'Expense', to: 'support/expense' },
    { id: 4, label: 'Examination All Location', to: 'support/expense/expense-report' },
]
const ambulance = [
    { id: 6, label: 'Ambulance Log', to: 'support/ambulance/ambulance-log' },
    { id: 7, label: 'Ambulance Check', to: 'support/ambulance/ambulance-check' },
    { id: 8, label: 'Vehicle Maintenance', to: 'support/ambulance/vehicle-maintenance' },
    { id: 9, label: 'Vehicle Report', to: 'support/ambulance/vehicle-report' },
    { id: 10, label: 'Ambulance Master Setup', to: 'support/ambulance/ambulance-master-setup' },
]
const assetManagement = [
    { id: 12, label: 'Asset Maintenance', to: 'support/asset-management/asset-maintenance' },
    { id: 13, label: 'Asset Audit', to: 'support/asset-management/asset-audit' },
    { id: 14, label: 'Asset Adjustment', to: 'support/asset-management/asset-adjustment' },
    { id: 15, label: 'Asset Maintenance Schedule', to: 'support/asset-management/asset-maintenance-schedule' },
    { id: 16, label: 'Asset Operational Status', to: 'support/asset-management/asset-operational-status' },
    { id: 17, label: 'Asset Check', to: 'support/asset-management/asset-check' },
    { id: 18, label: 'Asset Movement', to: 'support/asset-management/asset-movement' },
    { id: 19, label: 'Asset Restoration', to: 'support/asset-management/asset-restoration' },
    { id: 20, label: 'Asset Check Log', to: 'support/asset-management/asset-check-log' },
    { id: 21, label: 'Asset Incharge', to: 'support/asset-management/asset-incharge' },
    { id: 22, label: 'Asset Issue Return', to: 'support/asset-management/asset-issue-return' },
    { id: 23, label: 'Asset Calibration Log', to: 'support/asset-management/asset-calibration-log' },
    { id: 24, label: 'Asset Setup', to: 'support/asset-management/asset-setup' },
    { id: 25, label: 'Asset Management Report', to: 'support/asset-management/asset-management-report' },
    { id: 26, label: 'Asset Management Master Setup', to: 'support/asset-management/asset-management-master-setup' },
]
const bioMedicalWaste = [
    { id: 28, label: 'Bio Medical Waste', to: 'support/bio-medical-waste' },
    { id: 29, label: 'Bio Medical Master Setup', to: 'support/bio-medical-waste/bio-medical-master-setup' },
]
const claim = [
    { id: 31, label: 'Claim Analysis', to: 'support/claim/claim-analysis' },
    { id: 32, label: 'Claim Master Setup', to: '/dashboard' },
]
const healthConnect = [
    { id: 34, label: 'Health Connect', to: 'support/health-connect' },
    { id: 35, label: 'Health Connect Report', to: 'support/health-connect/health-connect-report' },
]
const canteen = [
    { id: 41, label: 'Canteen Inspection Detail', to: 'support/canteen/canteen-inspection-detail' },
    { id: 42, label: 'Canteen Employee Assign', to: 'support/canteen/canteen-employee-assign' },
    { id: 43, label: 'Pantry Inspection Single Entry', to: 'support/canteen/pantry-inspection-single-entry' },
    { id: 44, label: 'Pantry Inspection Bulk', to: 'support/canteen/pantry-inspection-bulk' },
    { id: 45, label: 'Checking Parameter Item', to: 'support/canteen/checking-parameter-item' },
    { id: 46, label: 'Employee Hygiene Parameter', to: 'support/canteen/employee-hygiene-parameter' },
    { id: 47, label: 'Pantry Item Allocation', to: 'support/canteen/pantry-item-allocation' },
    { id: 48, label: 'Inspection Report', to: 'support/canteen/inspection-report' },
    { id: 49, label: 'Import Pantry', to: 'support/canteen/import-pantry' },
    { id: 50, label: 'Import Canteen', to: 'support/canteen/import-canteen' },
    { id: 51, label: 'Canteen Master Setup', to: 'support/canteen/canteen-master-setup' },
] 
const healthLibrary = [
    { id: 53, label: 'Type Master', to: 'support/health-library/type-master' },
    { id: 54, label: 'Tags Master', to: 'support/health-library/tags-master' },
    { id: 55, label: 'Category Master', to: 'support/health-library/category-master' },
    { id: 56, label: 'Sub Category Master', to: 'support/health-library/sub-category-master' },
    { id: 57, label: 'Health Document Upload', to: 'support/health-library/health-document-upload' },
] 

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: '#000000',
    },
}));
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });
    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0,
                            pr: 0,
                        }}
                    >
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

export default function Support() {
    return (
        <SimpleTreeView
            aria-label="Support"
            defaultExpandedItems={['3']}
            defaultSelectedItems="5"
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            <CustomTreeItem itemId="1" label="Support" labelIcon={SupportIcon}>
                <CustomTreeItem itemId="2" label="Expense" labelIcon={SaveAsOutlinedIcon}>
                    {expense.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="5" label="Ambulance" labelIcon={SaveAsOutlinedIcon}>
                    {ambulance.map((item) => (
                        <Link to={item.to}
                        key={item.id}
                        >
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                                key={item.id}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="11" label="Asset Management" labelIcon={SaveAsOutlinedIcon}>
                    {assetManagement.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                key={item.id}
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="27" label="Bio Medical Waste" labelIcon={SaveAsOutlinedIcon}>
                    {bioMedicalWaste.map((item) => (
                        <Link to={item.to} key={item.id} >
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                key={item.id}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="30" label="Claim" labelIcon={SaveAsOutlinedIcon}>
                    {claim.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="33" label="Health Connect" labelIcon={SaveAsOutlinedIcon}>
                    {healthConnect.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="36" label="Wellness Activity" labelIcon={SaveAsOutlinedIcon}>
                    <Link to='support/wellness-activity/wellness-activity-report'>
                        <CustomTreeItem
                            itemId="37"
                            label="Wellness Activity Report"
                            labelIcon={ArrowRightAltOutlinedIcon}
                            color="#000000"
                            bgColor="#e8f0fe"
                            colorForDarkMode="#B8E7FB"
                            bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                        />
                    </Link>
                </CustomTreeItem>
                <CustomTreeItem itemId="38" label="Communication" labelIcon={SaveAsOutlinedIcon}>
                    <Link to='support/communication/ticket-master'>
                        <CustomTreeItem
                            itemId="39"
                            label="Ticket Master"
                            labelIcon={ArrowRightAltOutlinedIcon}
                            color="#000000"
                            bgColor="#e8f0fe"
                            colorForDarkMode="#B8E7FB"
                            bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                        />
                    </Link>
                </CustomTreeItem>
                <CustomTreeItem itemId="40" label="Canteen" labelIcon={SaveAsOutlinedIcon}>
                    {canteen.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="52" label="Health Library" labelIcon={SaveAsOutlinedIcon}>
                    {healthLibrary.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                key={item.id}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
            </CustomTreeItem>
        </SimpleTreeView>
    );
}