import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    DemoItem,
    TableRow,
    MenuItem,
    TableHead,
    DatePicker,
    TableCell,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    FileCopyOutlinedIcon,
    useScrollTrigger,
    LocalizationProvider,
    Slide,
    AdapterDayjs,
    PropTypes,
    TableBody,

} = sharedImports;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SubLocation: 'Ahmedabad', MedicineExpense: 177.4, FirstAid: 0, AmbulanceMedicine: 0, OtherStockIssue: 0, BioMedicalExpense: 0, VehicleExpense: 0, AssetMaintenenceExpense: 0, OtherExpense: 0, Total: 177.40, },
    { SubLocation: 'Total', MedicineExpense: 177.4, FirstAid: 0, AmbulanceMedicine: 0, OtherStockIssue: 0, BioMedicalExpense: 0, VehicleExpense: 0, AssetMaintenenceExpense: 0, OtherExpense: 0, Total: 177.40, },
];

const reportListRow = [
    { Type: 'Employee', ParticipantType: 'Regular', Count: 10, Cost: 166.44, },
    { Type: 'Oth', ParticipantType: 'CONTRACT WORKER (SELF)', Count: 1, Cost: 10.96, },
    { Type: '', ParticipantType: 'Total', Count: 11, Cost: 177.40, },
];

const reportListRowss = [
    { EMPType: 'Employee', ParticipantType: 10, Count: 10, Cost: 166.44, },
    { EMPType: 'Oth', ParticipantType: 1, Count: 1, Cost: 10.96, },
    { EMPType: 'Total', ParticipantType: 11, Count: 11, Cost: 177.40, },
];

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 2,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        SubLocation: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    consolidated Cost
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        ml: 4,
                        mr: 4,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sub Location</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="SubLocation"
                                            value={state.SubLocation}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Ahmedabad</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>
                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export To Excel
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Print
                        </Button>
                    </Stack>

                </Card>
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        mb: 5,
                        ml: 4,
                        mr: 4,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <Box sx={{ w: 1, bgcolor: 'lightgray', p: 2, fontWeight: 'bold' }}>Expense Summary</Box>
                                <TableContainer item xs={12} md={3} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Sub Location</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Medicine Expense</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>First Aid</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Ambulance Medicine</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Other Stock Issue</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Bio-Medical Expense</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Vehicle Expense</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Asset maintenance Expense</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Other Expense</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Total</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.SubLocation} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.SubLocation} </StyledTableCell>
                                                    <StyledTableCell>{row.MedicineExpense}</StyledTableCell>
                                                    <StyledTableCell>{row.FirstAid}</StyledTableCell>
                                                    <StyledTableCell>{row.AmbulanceMedicine}</StyledTableCell>
                                                    <StyledTableCell>{row.OtherStockIssue}</StyledTableCell>
                                                    <StyledTableCell>{row.BioMedicalExpense}</StyledTableCell>
                                                    <StyledTableCell>{row.VehicleExpense}</StyledTableCell>
                                                    <StyledTableCell>{row.AssetMaintenenceExpense}</StyledTableCell>
                                                    <StyledTableCell>{row.OtherExpense}</StyledTableCell>
                                                    <StyledTableCell>{row.Total}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <Box sx={{ w: 1, bgcolor: 'lightgray', p: 2, fontWeight: 'bold' }}>OPD Count and Cost Based On Employee Type</Box>
                                <TableContainer item xs={12} md={3} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Type</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Participant Type</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Count</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Cost</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRow.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.Type} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.Type} </StyledTableCell>
                                                    <StyledTableCell>{row.ParticipantType}</StyledTableCell>
                                                    <StyledTableCell>{row.Count}</StyledTableCell>
                                                    <StyledTableCell>{row.Cost}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <Box sx={{ w: 1, bgcolor: 'lightgray', p: 2, fontWeight: 'bold' }}>OPD Count and Cost Based Summary</Box>
                                <TableContainer item xs={12} md={3} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>EMP Type</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Count</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Cost</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRowss.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.EMPType} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.EMPType} </StyledTableCell>
                                                    <StyledTableCell>{row.Count}</StyledTableCell>
                                                    <StyledTableCell>{row.Cost}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>







                </Card>
            </Box>



        </>
    );
}