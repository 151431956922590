import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    TableRow,
    MenuItem,
    TableHead,
    TableCell,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    FileCopyOutlinedIcon,
    FormControlLabel,
    Checkbox,
    UnfoldMoreOutlinedIcon,
    TableBody,
    useScrollTrigger,
    Slide,
    PropTypes,

} = sharedImports;






const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { SubLocation: 'Ahmedabad', ItemCode: 80, ItemName: 'Adhesive tape', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 3 },
    { SubLocation: 'Ahmedabad', ItemCode: 117, ItemName: 'Airway No.4(Adult)', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 3 },
    { SubLocation: 'Ahmedabad', ItemCode: 151, ItemName: 'Alex lozenges', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 26 },
    { SubLocation: 'Ahmedabad', ItemCode: 38, ItemName: 'Allegra 180', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 80 },
    { SubLocation: 'Ahmedabad', ItemCode: 198, ItemName: 'Allegra M', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 98 },
    { SubLocation: 'Ahmedabad', ItemCode: 30, ItemName: 'Alprax 0.5mg', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 15 },
    { SubLocation: 'Ahmedabad', ItemCode: 152, ItemName: 'Amlodipine 5mg', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 32 },
    { SubLocation: 'Ahmedabad', ItemCode: 46, ItemName: 'Atorva 80', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 13 },
    { SubLocation: 'Ahmedabad', ItemCode: 19, ItemName: 'Augmentin Duo 625', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 180 },
    { SubLocation: 'Ahmedabad', ItemCode: 94, ItemName: 'Avil', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 53 },
    { SubLocation: 'Ahmedabad', ItemCode: 24, ItemName: 'Lozenges', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 328 },
    { SubLocation: 'Ahmedabad', ItemCode: 146, ItemName: 'B.P.Insurment', Uom: 'NOS', MaximumInventory: 0, ReOrderQuantity: 0, SafetyStock: 0, Quantity: 3 },
];

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function DenseAppBar(props) {
    const [state, setState] = React.useState({
        MainGroup: '',
        SubGroup: '',
        Filter: '',
        ItemCategory: '',
        Tag: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Status
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Name Like</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Item Name Like'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Item Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Main Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="MainGroup"
                                            value={state.MainGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sub Group</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="SubGroup"
                                            value={state.SubGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Filter</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Filter"
                                            value={state.Filter}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Live Stock</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Item Category</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="ItemCategory"
                                            value={state.ItemCategory}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Vendor Code</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Item Code'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Vendor Name</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Vendor Name'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex.10,155 etc.)</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Code Like (Ex.10,155 etc.)'
                                    />
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Tag</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Tag"
                                            value={state.Tag}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Select Type</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Critical"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="First Aid"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Inactive"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Zero Visible"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center' }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>
                            Export
                        </Button>

                        <Button variant='outlined' sx={{ textTransform: 'none' }} >
                            Generate Request
                        </Button>

                    </Stack>


                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        mb: 5,
                        width: "100%",
                    },
                }}
            >
                <Card variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important' }}>
                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            <Box sx={{ fontWeight: 'bold' }}>Total Records:160</Box>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                        <TextField
                                            required
                                            id="filled-search"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ pt: '0px !important' }}>
                            <CardContent sx={{ pt: '5px !important' }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>
                                                    <Checkbox sx={{ p: 0 }} />
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Sub Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Item Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Item Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>UOM<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Maximum Inventory<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>ReOrder Quantity<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Safety Stock<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Quantity<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.SubLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>
                                                        <Checkbox sx={{ p: 0 }} />
                                                    </StyledTableCell>
                                                    <StyledTableCell> {row.SubLocation} </StyledTableCell>
                                                    <StyledTableCell>{row.ItemCode}</StyledTableCell>
                                                    <StyledTableCell>{row.ItemName}</StyledTableCell>
                                                    <StyledTableCell>{row.Uom}</StyledTableCell>
                                                    <StyledTableCell>{row.MaximumInventory}</StyledTableCell>
                                                    <StyledTableCell>{row.ReOrderQuantity}</StyledTableCell>
                                                    <StyledTableCell>{row.SafetyStock}</StyledTableCell>
                                                    <StyledTableCell sx={{ backgroundColor: 'green', textAlign: 'center', color: 'white' }}>{row.Quantity}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
}
